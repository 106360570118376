import React from 'react'
import './webheader.css'
import newlogo from '../../assest/Image/newlogo.png'
import { loginSignupselect } from '../../Functions/LoginSingupfun'
function Webheader({ selectedbtn, setSelectedbtn ,setShow , setLoginstatus,setSignupstatus}) {
  
  return (

    <div className='webheader'>
      <div className='webheaderlogo'>
        <img src={newlogo} />
      </div>
      <div className='webheaderloginsignupbtn'>
        <div className='webheaderlogin' onClick={() =>{
           setSignupstatus(false)
           setLoginstatus(true)
        }
      }
         
          >
          LOGIN
        </div>
        <div className='webheadersignup'
          onClick={() =>
            {
              setSignupstatus(true)
              setLoginstatus(false) 
            }
         
         
          }
            >
       GET STARTED
    
        </div>
      </div>
    </div>
  )
}

export default Webheader