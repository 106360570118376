import React from 'react'
import './adduserproject.css'
function Adduserproject() {
  return (
   <div className='addusermaindiv'>
    <div className='adduserheadingdiv'>
        <div className='adduserprojectname'>Add user/ProjectName</div>
        <div className='searchuser'>
    <input placeholder='Search User...' className='searchuserinput'/>
        </div>
    </div>

    <div className='adduserheading'>
      <div className='addusercheckbox'>   </div>
      <div className='adduseremail'> User Email</div>
      <div className='addusername'>User Name</div>
      <div className='addusercompany'>Company</div>
      <div className='adduserstatus'>Status</div>
    </div>


    {/* user data table list is that  selected*/}
    <div className='userstableData'>
    <div className='adduserdata'>
        <div className='addusercheckboxdata' >
        <input  className='addusercheckboxdatainput'
        type="checkbox" checked onclick="return false;" onkeydown="return false;"/> 
        </div>
        <div className='adduseremaildata'>
            ajay9@gmail.com
        </div>
        <div className='addusernamedata' >Ajay(dholia)</div>
        <div className='addusercompanydata'>Graphketing</div>
        <div className='adduserstatusdatablue'>IN PROJECT</div>
        </div>

        <div className='adduserdata'>
        <div className='addusercheckboxdata' >
        <input type="checkbox"   disabled checked onclick="return false;" onkeydown="return false;"/> 
        </div>
        <div className='adduseremaildata'>
            a19@gmail.com
        </div>
        <div className='addusernamedata' >Ajay(dholia)</div>
        <div className='addusercompanydata'>Graphketing</div>
        <div className='adduserstatusdata'>ONBOARDED</div>
        </div>
        {/* end here user data table selected list */}
        {/* unselected data */}
        <div className='unselecteddata'>
        <div className='unselectedcheckboxdata' >
        <input type="checkbox" /> 
        </div>
        <div className='unselectedemaildata'>
            ajaydhhsdjhfkdolia19@gmail.com
        </div>
        <div className='unselectednamedata' >Ajay(dholia)</div>
        <div className='unselectedcompanydata'>Graphketing</div>
        <div className='unselectedstatusdata'>PENDING</div>
        </div>
        <div className='unselecteddata'>
        <div className='unselectedcheckboxdata' >
        <input type="checkbox"/> 
        </div>
        <div className='unselectedemaildata'>
            ajaydjjjdholia19@gmail.com
        </div>
        <div className='unselectednamedata' >Ajay(dholia)</div>
        <div className='unselectedcompanydata'>Graphketing</div>
        <div className='unselectedstatusdata'>PENDING</div>
        </div>
        <div className='unselecteddata'>
        <div className='unselectedcheckboxdata' >
        <input type="checkbox"/> 
        </div>
        <div className='unselectedemaildata'>
            holia19@gmail.com
        </div>
        <div className='unselectednamedata' >Ajay(dholia)</div>
        <div className='unselectedcompanydata'>Graphketing</div>
        <div className='unselectedstatusdata'>PENDING</div>
        </div>
        <div className='unselecteddata'>
        <div className='unselectedcheckboxdata' >
        <input type="checkbox"/> 
        </div>
        <div className='unselectedemaildata'>
            ajaydia19@gmail.com
        </div>
        <div className='unselectednamedata' >Ajay(dholia)</div>
        <div className='unselectedcompanydata'>Graphketing</div>
        <div className='unselectedstatusdata'>PENDING</div>
        </div>
        <div className='unselecteddata'>
        <div className='unselectedcheckboxdata' >
        <input type="checkbox"/> 
        </div>
        <div className='unselectedemaildata'>
            ajaydholia19@gmail.com
        </div>
        <div className='unselectednamedata' >Ajay(dholia)</div>
        <div className='unselectedcompanydata'>Graphketing</div>
        <div className='unselectedstatusdata'>PENDING</div>
        </div>
        <div className='unselecteddata'>
        <div className='unselectedcheckboxdata' >
        <input type="checkbox"/> 
        </div>
        <div className='unselectedemaildata'>
            ajaydholia19@gmail.com
        </div>
        <div className='unselectednamedata' >Ajay(dholia)</div>
        <div className='unselectedcompanydata'>Graphketing</div>
        <div className='unselectedstatusdata'>PENDING</div>
        </div>
        <div className='unselecteddata'>
        <div className='unselectedcheckboxdata' >
        <input type="checkbox"/> 
        </div>
        <div className='unselectedemaildata'>
            ajaydholia19@gmail.com
        </div>
        <div className='unselectednamedata' >Ajay(dholia)</div>
        <div className='unselectedcompanydata'>Graphketing</div>
        <div className='unselectedstatusdata'>PENDING</div>
        </div>
          <div className='unselecteddata'>
        <div className='unselectedcheckboxdata' >
        <input type="checkbox"/> 
        </div>
        <div className='unselectedemaildata'>
            ajaydholia19@gmail.com
        </div>
        <div className='unselectednamedata' >Ajay(dholia)</div>
        <div className='unselectedcompanydata'>Graphketing</div>
        <div className='unselectedstatusdata'>PENDING</div>
        </div>  <div className='unselecteddata'>
        <div className='unselectedcheckboxdata' >
        <input type="checkbox"/> 
        </div>
        <div className='unselectedemaildata'>
            ajaydholia19@gmail.com
        </div>
        <div className='unselectednamedata' >Ajay(dholia)</div>
        <div className='unselectedcompanydata'>Graphketing</div>
        <div className='unselectedstatusdata'>PENDING</div>
        </div>

        </div>
        
        {/* unselected data */}

<div className='savechanges'>Save Changes</div>


   </div>
  )
}

export default Adduserproject