import React from 'react'
import './slidescreen2.css'
import Group from '../../assest/Image/Group.png'
import { FiArrowRight } from "react-icons/fi";
function SlideScreen2({setSlide1,setSlide2,setSlide3}) {
    const handleClick = () =>{
        setSlide1(false);
        setSlide2(false);
        setSlide3(true);
    }
  return (
    <div className='SlideScreen2div'>

    <div className='spares2'>
        <div className='spare2'></div>
        <div className='activespare'>

        </div>
        <div className='spare2'></div>
    </div>
    <div className='slider2paragraphmain' >What you can do</div>
   
        <div className='slider2paragraph'>
        This is a Query Management System which helps you to collaborate and chat with all your team members in a group who are working on a specific project with you. 
    </div>
    <div>
        <div className='slider2paragraph2'>
Everyone can put up queries in the chat which needs to be solved.</div>
    </div>
    <button  className='sliderbtn2' onClick={handleClick} ><span>Next</span><FiArrowRight style={{color:"white",marginBottom:"2%",marginLeft:"5%"}} /></button>

    <div className='slider2img'><img src={Group}/></div>
    </div>
  
  )
}

export default SlideScreen2