import React from "react";
import { MdOutlineReplyAll } from "react-icons/md";
import "../sendmessage.css";
import createprojectimage from "../../../../assest/Image/createprojectimage.png";
import imagelogo from "../../../../assest/Image/imagelogo.png";
function SelfImageReply({ setChatreply, setreplymsg, message, type }) {
  const selfimagereply = "AjayTesting The Image with the string";
  return (
    <div className="pdfchatselfreplymaindiv">
      <div className="selfchatreplydiv">
        <MdOutlineReplyAll
          onClick={() => {
            setChatreply(true);
            setreplymsg({
              name: message.sendFrom.name,
              profession: message.sendFrom.profession,
              color: null,
              message: message.messageData.description,
              message_id: message._id,
            });
          }}
        />
      </div>
      <div className="sendreplyofamessagemaindiv">
        <div className="selfreplytoimage">
          <img
            src={message?.replyOf?.messageData?.doc}
            className="selfreplytorealimage"
          />
          <div className="sendreplytoimageusernameandlogo">
            <div style={{ color: "gray", fontSize: "13px" }}>
              {message?.replyOf?.sendFrom.name} (
              {message?.replyOf?.sendFrom.profession})
            </div>

            {type === "imageWithMessage" ? (
              <div style={{ color: "#868686", fontSize: "11px" }}>
                {message?.replyOf?.messageData?.description}
                ...
              </div>
            ) : (
              <img src={imagelogo} />
            )}
          </div>
        </div>
        <div style={{ padding: "3%", fontSize: "13px" }}>
          {message?.messageData?.description}
        </div>
      </div>
    </div>
  );
}

export default SelfImageReply;
