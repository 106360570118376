import React,{useEffect, useState} from 'react'
import './verifyemail.css'
import { useParams,useNavigate } from 'react-router-dom'
import axios from 'axios'
import pendingemail from "../../assest/Image/pendingemail.png";
import successbackground from "../../assest/Image/successbackground.png";
function Verifyemail() {
const [showerror,setShowerror] = useState(false)
    const navigate= useNavigate();

    useEffect(()=>{
       verifyemailfun();
    },[])

    const {id} = useParams();
    const verifyemailfun = async() =>{
        const data = await axios.post( `${process.env.React_App_prod_url}/user/emailverify`,
        {
            id: id
        })
      
{
    data.status === 200 ? 
    setShowerror(false):
    setShowerror(true)
}    }
  return (
    <div className='verifyemailmaindiv'>
{
    showerror === true ?
    <div className='pendingdiv'>
        <div className="pendingheader">
            </div>
        <img src={pendingemail}/>
        <div className='pendingstatus'>
            Pending!
            </div>
            <div className='pendingspan'>
                Your Email is still Pending
                </div>
                <div className='pendingverify' onClick = {() =>navigate("/")}>Verify</div>
    </div>:
       <div className='successdiv'>
       <div className="successheader">
           </div>
       <img src={successbackground}/>
       <div className='successstatus'>
            Successfull!
           </div>
           <div className='successspan'>
           Wohoo! You have successfully verified your Email!
               </div>
               <div className='successbtn' onClick = {() =>navigate("/")}
               >Continue</div>
   </div>
   
}
       
    </div>
  )
}

export default Verifyemail