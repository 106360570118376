import "./webhome.css";
import React, { useState } from "react";
import homepageimage from "../../assest/Image/homepageimage.png";
import Homepage from "../../assest/Image/Homepage.png";
import Webheader from "./Webheader";
import WebLogin from "./WebLogin";
import WebSignup from "./WebSignup";
import { useNavigate } from "react-router";
function Webhomepage() {
  const [selectedbtn, setSelectedbtn] = useState("")
  const navigate = useNavigate();
  const [Show, setShow] = useState(false)
  const [loginstatus, setLoginstatus] = useState(false)
  const [Signupstatus, setSignupstatus] = useState(false)

  return (
    <div className="webhomemaindiv">
      {/* <Webheader selectedbtn={selectedbtn} setSelectedbtn={setSelectedbtn} setShow={setShow} /> */}
      <Webheader selectedbtn={selectedbtn} setSelectedbtn={setSelectedbtn} setLoginstatus={setLoginstatus}  setSignupstatus={setSignupstatus}  />

      <div className="webhomeleftdiv">
        <div className="webhomepagespan">
          We bring all your tasks, teammates, and tools together
        </div>
        {/* <div className="smallspan">
          Keep everything in the same place-even if your team isn’t.
        </div> */}
        <div className="webhomeinputandbtn">
          {/* <input placeholder='Email' className='webhomeinputgmail' /> */}
          <div
            className="webhomeletsstartbtn"
            onClick={() => {
              setSignupstatus(true)
              setLoginstatus(false) 
            }}
          >
            <span>GET STARTED</span>
          </div>
        </div>
      </div>
      <div
        className="webhomerightdiv"
      >

        <div>
          <img src={Homepage}/>
        </div>
        {

          <>
            <WebSignup  Signupstatus={Signupstatus} setSignupstatus={setSignupstatus} selectedbtn={selectedbtn} setSelectedbtn={setSelectedbtn} />

              <WebLogin  loginstatus ={loginstatus} setLoginstatus={setLoginstatus} Signupstatus={Signupstatus} setSignupstatus={setSignupstatus}   selectedbtn={selectedbtn} setSelectedbtn={setSelectedbtn} />
           
              </>
            

        }



      </div>
    </div>
  );
}

export default Webhomepage;
