import { CLOSE_LOADER, START_LOADER } from "../constants";

export default function LoaderReducer(state = { status: false }, action) {
  switch (action.type) {
    case START_LOADER:
      return {
        status: true,
      };

    case CLOSE_LOADER:
      return {
        status: false,
      };
    default:
      return state;
  }
}
