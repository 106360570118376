import React from "react";
import pdf from "../../../../assest/Image/pdf.png";
import download from "../../../../assest/Image/download.png";
import { MdOutlineReplyAll } from "react-icons/md";
import "../recivedmessages.css";
function PdfMessageRecieved({ setChatreply, message, setreplymsg }) {
  // const pdfname = "AjayDholiaNamePdf  ";
  const pdfname = message?.messageData?.doc?.split("/")[4]?.substring(13);
console.log({message},"pdf reply here ")

  const handleDownload = () => {
    // Constructing the download URL
    const downloadUrl = message?.messageData?.doc
    //  `${process.env.React_App_prod_url}/download/pdf/${pdfname}`;

    // Creating an anchor element to trigger download
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = pdfname; // Setting the filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="pdfFileandreplylogodiv">
      <div className="ispdftruemaindiv">
        <div className="ispdftrue">
          <img src={pdf} className="pdfimage" />
          <div className="recievedpdfspan">
            {message?.messageData?.doc?.split("/")[4]?.substring(13)}
          </div>
          {/* <a href={`${process.env.React_App_prod_url}/download/pdf/${pdfname}`} download={pdfname}> */}

          <img src={download} className="downladimage" onClick={handleDownload}  />
          {/* </a> */}
        </div>
      </div>
      <div
        className="chatreplydiv"
        //   onClick={() =>
        //     handleReply(
        //       data?.sendFrom?.name,
        //       data?.messageData?.doc
        //         ?.split("/")[4]
        //         ?.substring(13),
        //       data?.sendFrom?.profession,
        //       color,
        //       data._id
        //     )
        //   }
        onClick={() => setChatreply(true)}
      >
        <MdOutlineReplyAll
          onClick={() => {
            setreplymsg({
              name: message.sendFrom.name,
              profession: message.sendFrom.profession,
              color: null,
              message: message.messageData.description,
              message_id: message._id,
            });
          }}
        />
      </div>
    </div>
  );
}

export default PdfMessageRecieved;
