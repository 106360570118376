import React from "react";
import email from "../../assest/Image/email.png";
import password from "../../assest/Image/password.png";
import BuildB from "../../assest/Image/BuildB.png";
import "./logonfile.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { show_toaster_actionCreater } from "../../Redux/toasers/CreateActions";
function AnimatedLogin({ toggle }) {
  const navigate = useNavigate();
  const { values, onSubmit, touched, errors, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: Yup.object({
        email: Yup.string().email().required("Please Enter Your Email"),

        password: Yup.string()
          .min(2)
          .max(30)
          .required("Please Enter Your Password"),
      }),
      onSubmit: async (values, action) => {
      
        const response = await handleloginclick(values);
        if (response)
          setTimeout(() => {
            action.resetForm();
            // toggle(false)
          }, 1000);
      },
    });

  useEffect(() => {
    let token = window.localStorage.getItem("token");
    if (token) {
     
      navigate("/home");
    }
  }, []);

  const handleloginclick = async () => {
    try {
      const response = await axios.post(
        `${process.env.React_App_prod_url}/user/login`,
        {
          email: values.email,
          password: values.password,
        }
      );

      if (response.data) {
       
        window.localStorage.setItem("token", response.data.user.jwtToken);
        navigate("/home");
      }
      show_toaster_actionCreater({
        message: "login successfully",
        status: "success",
      });
      return true;
    } catch (err) {
      if (err?.response?.data?.err) {
        show_toaster_actionCreater({
          message: err?.response?.data?.err,
          status: "error",
        });
      }
     
      return false;
    }
  };
  return (
    <div className="animatedLogindiv">
      <div className="animatedsmalldiv">
        <img src={BuildB} className="animatedimage" />
        <h3 className="animatedh2design">Log In to Username </h3>
        <form className="animatedloginform" autocomplete="off">
          {/* <div className='animatedloginform'> */}

          <div className="input-group mb-2 animatednamediv shadow-none ">
            <span
              className="input-group-text animatedemailspan"
              id="basic-addon1"
              htmlFor="email"
            >
              <img
                src={email}
                className="nameimage"
                style={{ height: "2vh" }}
              />
            </span>
            <input
              type="email"
              // autoComplete="off"
              className="form-control animatednameinput shadow-none"
              onChange={handleChange}
              value={values.email}
              placeholder="Email"
              aria-label="Username"
              name="email"
              aria-describedby="basic-addon1"
            />
          </div>
          {touched.email&&errors.email ? <p className="Errors">{errors.email}</p> : null}
          <div className="input-group mb-2 animatednamediv shadow-none ">
            <span
              className="input-group-text animatedemailspan"
              id="basic-addon1"
              htmlFor="password"
            >
              <img
                src={password}
                className="nameimage"
                style={{ height: "2vh" }}
              />
            </span>
            <input
              type="password"
              // autoComplete="off"
              className="form-control animatednameinput shadow-none"
              onChange={handleChange}
              value={values.password}
              placeholder="password"
              aria-label="Username"
              name="password"
              aria-describedby="basic-addon1"
            />
          </div>
          {touched.password &&errors.password ? <p className="Errors">{errors.password}</p> : null}
          <div
            className="animatedforgetpassword"
            onClick={() => navigate("/forgetpassword")}
          >
            <span>Forget Your Password?</span>
          </div>
          <button
            type="button"
            className="animatedloginbtn"
            onClick={handleSubmit}
          >
            <span style={{ color: "white" }}>Log In</span>
          </button>
        </form>
      </div>
    </div>
  );
}

export default AnimatedLogin;
