import React, { useEffect, useState } from "react";
import projectnamevectorblue from "../../assest/Image/projectnamevectorblue.png";
import usersbold from "../../assest/Image/usersbold.png";
import projectnameicon from "../../assest/Image/projectnameicon.png";
import users from "../../assest/Image/users.png";
import sequencelogo from "../../assest/Image/sequencelogo.png";
function StorageProject({ setIsupload, isupload }) {
  const [showsequenceandreport, setShowsequenceandreport] = useState(false);
  const [whichselected,setWhichselected] = useState(-1)
  const project = [{
    name:"Archetecture Project ",
    id:0
  },
  {
    name: "Site Construction Work",
    id:1
  },
  {
    name:"onSite Project",
    id:2
  },
  {
    name:"Midway Project",
    id:3
  },
  {
    name:"High Way Construction Project",
    id:4
  },
  {
    name:"Railway Construction Project",
    id:5
  }
]
  return (
    <div className="wrapper">
{
  project !== null ? project.map((value,index)=>{
    return(
      <div className="accordian">
      <div className={whichselected === value.id ? "itemblue" : "item"}>
        <div className="projecthead">
          <div
            className="projectNamediv1"
            onClick={() => setWhichselected(value.id)}
          >
            <img
              src={
                whichselected === value.id 
                  ? projectnameicon
                  : projectnamevectorblue
              }
              className="projecticonlogo"
            />
            <div
              className={
                whichselected === value.id 
                  ? "whiteprojectnamespan"
                  : "blueprojectnamespan"
              }

            >
         {value.name}
            </div>
          </div>

        </div>
        {
          whichselected === value.id ? <></> :
            <div className="showuser2">
              <img src={whichselected === value.id  ? users : usersbold} />
              <div
                className={
                  whichselected === value.id 
                    ? "totaluserinprojectwhite"
                    : "totaluserinproject"
                }
              >
                Users
              </div>
            </div>
        }
        {whichselected === value.id  ?

          <>
            <div className="title"
            >
              <div className="imageContainer ">
                <img src={sequencelogo} />
              </div>
              <div className="sequenceName">Storage</div>
            </div>
            <div className="storagebar">
              <div className="storageprogressbar" style={{ width: "50%"}}></div>
            </div>
            <div className="uploadfilestorage" > <div className="usedstorage">1GB of 1TB used</div> <div className="uploaddatainstorage" onClick={() => setIsupload(!isupload)}>Upload</div></div>
          </>
          : null}
      </div>
    </div>
    )
  }) :""
}
    
    </div>
  )
}

export default StorageProject