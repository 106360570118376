import { SELECT_CHAT, SEND_MESSAGE } from "../constants";

export const select_chat_action = ({ data }) => {
  return {
    type: SELECT_CHAT,
    payload: data || [],
  };
};

export const send_message_action = ({ message }) => {
  return {
    type: SEND_MESSAGE,
    payload: message || {},
  };
};
