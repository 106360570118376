import axios from "axios";
import { closeLoaderAction, startLoaderAction } from "../loader/Actions";
import {
  select_query_of_sequence_action,
  set_query_status_action,
} from "./Actions";

// slected Query
export const select_query_of_sequence_actionCreater = async ({
  dispatch,
  data,
  query,
}) => {
  try {
    if (!data && !query) return;
    if (!data) data = await get_single_query(query);

    dispatch(select_query_of_sequence_action({ data }));
  } catch (err) {
    dispatch(closeLoaderAction());
    console.log({ err });
  }
};

export const get_query_data_actionCreater = async ({ dispatch, query_id }) => {
  try {
    let data = await get_query({
      query_id,
    });
    dispatch(select_query_of_sequence_action({ data }));
  } catch (err) {
    dispatch(closeLoaderAction());
    console.log({ err });
  }
};

const get_query = async ({ query_id }) => {
  try {
    const token = window.localStorage.getItem("token");
    let new_query_data = await axios.post(
      `${process.env.React_App_prod_url}/project/delete_user_from_query`,
      {
        query_id,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return new_query_data.data.query;
  } catch (err) {
    console.log({ err });
  }
};

const get_single_query = async (query) => {
  try {
    const token = window.localStorage.getItem("token");
    let new_query_data = await axios.get(
      `${process.env.React_App_prod_url}/project/get_single_query_of_sequence/${query}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return new_query_data.data;
  } catch (err) {
    console.log({ err });
  }
};

export const set_query_status_actionCreater = async ({ dispatch, status }) => {
  try {
    dispatch(set_query_status_action({ status }));
  } catch (err) {
    dispatch(closeLoaderAction());
    console.log({ err });
  }
};

export const select_query_of_actionCreater = async ({ dispatch, query }) => {
  try {
    let data = await get_single_query(query);

    dispatch(select_query_of_sequence_action({ data: data.query }));
  } catch (err) {
    dispatch(closeLoaderAction());
    console.log({ err });
  }
};
