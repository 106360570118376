import { CLOSE_LOADER, START_LOADER } from "../constants";

export const startLoaderAction = () => {
  return {
    type: START_LOADER,
  };
};

export const closeLoaderAction = () => {
  return {
    type: CLOSE_LOADER,
  };
};
