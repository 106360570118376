import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import Forgetpassword from "./Components/Forgetpassword/Forgetpassword";
import Verification from "./Components/Verification/Verification";
import Newpassword from "./Components/Newpassword/Newpassword";
import Sidebar from "./Components/Sidebar/Sidebar";
import MainAnimatedLoginandSignup from "./Components/AnimatedLoginSignup/MainAnimatedLoginandSignup";
import Webhomepage from "./Components/webhomepage/Webhomepage";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Verifyemail from "./Components/verifyemail/Verifyemail";
import { SocketProvider } from "socket.io-react";
import io from "socket.io-client";
import { send_message_actionCreater } from "./Redux/chatData/CreateActions";
import { get_single_user_list_actioncreator } from "./Redux/profile/CreateActions";
import { get_user_data } from "./Functions/headerFunction";
import axios from "axios";
import {
  get_all_projects_actionCreater,
  get_sequence_of_project_actionCreater,
} from "./Redux/projects/CreateActions";
import { show_toaster_actionCreater } from "./Redux/toasers/CreateActions";
import useSound from "use-sound";
import sound from "./assest/sound/Notification.mp3";
import { useRef } from "react";
// import {io} from "socket.io-client";
function App() {
  const loaderStatus = useSelector((state) => {
    return state.loader.status;
  });
  const [play] = useSound(sound);
  const audioPlayer = useRef(null);
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  const oldUserData_id = useSelector((state) => {
    return state && state.profileData
      ? state?.profileData?._id
      : state?.profileData;
  });

  useEffect(() => {
    get_all_projects_actionCreater({
      oldUserData_id,
      dispatch,
      router: "project",
    });
  }, []);

  const socket = io("https://server.buildintra.app", {
    transports: ["websocket"],
  });
  // const socket = io("https://server.buildintra.com/", {
  //   transports: ["websocket"],
  // });

  // console.log(process.env.React_socket_base_url);

  const notificationPermision = async () => {
    let permission = await Notification.requestPermission();
    // console.log(permission, "permission");
    // const greeting = new Notification("Permision Granted");
  };
  useEffect(() => {
    socket.connect();
    socket.on("connect", () => {
      console.log(socket.connected, "socket connected successfully");
    });
    notificationPermision();
  }, []);
  function playAudio() {
    console.log("Audio Play");
    audioPlayer.current.play();
  }

  useEffect(() => {
    if (userData)
      socket.on("new message", async (message) => {
        // is message for me
        // console.log({
        //   userData,
        //   message: message,
        // });
        let route = window.location.href.split("/");
        let userInChat = message.query.queryUsers?.find((ele) => {
          return ele.email == userData.email;
        });
        let createrName = message.query.createrName == userData.user_id;
        // console.log(
        //   {
        //     userInChat,
        //     createrName,
        //     inProject: userInChat || createrName,
        //   },
        //   "createrName new message 1"
        
        // );

        if (!(userInChat || createrName)) return;
        // console.log({ userInChat, createrName }, "createrName new message 2");
        // i am sender no need to receive message
        if (message.sendFrom._id == userData.user_id) return;
        // console.log({ userInChat, createrName }, "createrName new message 3");
        // get current router
        let id = route[5];
        let query_id = route[6];
        if (message.query._id == route[6] && message.project._id == id) {
          // console.log(
          //   {
          //     userInChat,
          //     createrName,
          //     query: message.query._id,
          //     route: route[6],
          //     project: message.project,
          //     id,
          //   },
          //   "createrName new message 4"
          // );

          // console.log("new message received successfully", { message });
          send_message_actionCreater({
            dispatch,
            message,
          });
        } else {
          if (message.project._id == route[5]) {
            get_sequence_of_project_actionCreater({
              dispatch,
              project_id: route[5],
            });
            get_all_projects_actionCreater({
              oldUserData_id,
              dispatch,
              router: route[4],
            });
          }
          if (
            route[4] === "project" ||
            route[4] === "live_project" ||
            route[4] === "closeproject"
          ) {
            get_all_projects_actionCreater({
              oldUserData_id,
              dispatch,
              router: route[4],
            });
            // show_toaster_actionCreater({
            //   message: "Message in Another query",
            //   status: "success",
            // });
          }
          if (userData?._id == message?.sendFrom?._id) {
          } else {
            let permission = await Notification.requestPermission();
            console.log(permission, "permission");
            if (permission === "granted") playAudio();
          }

          // console.log({ message });
          // from name in project_name
          // body : message contant
          let notificationMessage = null;
          if (message.messageData.description) {
            notificationMessage = message.messageData.description;
          } else if (
            message?.messageData?.doc?.split(".")[
              message?.messageData?.doc?.split(".")?.length - 1
            ] === "pdf"
          ) {
            notificationMessage = "PDF";
          } else {
            notificationMessage = "IMAGE";
          }

          const greeting = new Notification(
            `From ${message.sendFrom.name} in ${message.project.projectName}`,
            {
              body: notificationMessage,
              // icon: "./img/js.png",
              vibrate: true,
              data: "data of message",
            }
          );

          setTimeout(() => {
            greeting.close();
          }, 10 * 1000);
        }
      });
  }, [userData]);

  useEffect(() => {
    let token = window.localStorage.getItem("token");
    if (token) {
      get_user_data({ token, setUserData });
    }
  }, []);

  return (
    <>
      <Router>
        <Backdrop
          sx={{
            zIndex: 200000,
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loaderStatus}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <audio ref={audioPlayer} src={sound} />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Routes>
          <Route path="/" element={<Webhomepage />} />
          {/* <Route path="/login" element={<MainAnimatedLoginandSignup />} /> */}
          <Route path="/forgetpassword" element={<Forgetpassword />} />
          <Route path="/verifyotp" element={<Verification />} />
          <Route path="/newpassword" element={<Newpassword />} />
          <Route path="/home/*" element={<Sidebar />} />
          <Route path="/verifyemail/:id" element={<Verifyemail />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
