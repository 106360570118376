import React from "react";
import ChatSimpleMessageSend from "./ChatMessageSend/SimpleMessageSend/ChatSimpleMessageSend";
import "./messagesendmain.css";
import PdfMessageSend from "./ChatMessageSend/PdfMessageSend/PdfMessageSend";
import ChatSelfReply from "./ChatMessageSend/SimpleMessageSend/ChatSelfReply";
import SelfImageMessage from "./ChatMessageSend/ImageMessageSend/SelfImageMessage";
import SendImageWithSpan from "./ChatMessageSend/ImageMessageSend/SendImageWithSpan";
import PdfWithSpan from "./ChatMessageSend/PdfMessageSend/PdfWithSpan";
import SelfImageReply from "./ChatMessageSend/ImageMessageSend/SelfImageReply";
import moment from "moment";
import PdfReplysend from "./ChatMessageSend/PdfMessageSend/PdfReplySend";
function MessageSend({
  setChatreply,
  replymsgRef,
  forwardtoChatReplymsg,
  message,
  setreplymsg,
  isSameUser
}) {
  console.log({ message });
  return (
    <div
      className="messagesend"
      ref={ref => replymsgRef.current[message?._id] = ref}
    >
      <div className="usermsgdiv">
        {
          isSameUser && <div className="loginusernametime">
          {" "}
          {message.sendFrom.name} ({message.sendFrom.profession}){" "}
          <span>
            {moment(message?.createdAt).format("DD/MM/YYYY hh:mm:ss")}
          </span>{" "}
        </div>
        }
       

        {message?.replyOf == null ? (
          message?.messageData?.doc === null ? (
            <>
              {/* simple text Message send */}
              <ChatSimpleMessageSend
                message={message}
                setChatreply={setChatreply}
                setreplymsg={setreplymsg}
                isSameUser={isSameUser}
              />
            </>
          ) : message?.messageData?.description &&
            message?.messageData?.description != "" ? (
            <>
              {/* file with message */}
              {/* {console.log(
                message.messageData.doc,
                message.messageData.doc.split(".")[
                  message.messageData.doc.split(".").length - 1
                ],
                "message with doc"
              )} */}
              {message?.messageData?.doc?.split(".")[
                message?.messageData?.doc?.split(".")?.length - 1
              ] === "pdf" ? (
                <>
                  {/* pdf file with message */}
                  <PdfWithSpan
                    setreplymsg={setreplymsg}
                    message={message}
                    setChatreply={setChatreply}
                    isSameUser={isSameUser}
                  />
                </>
              ) : (
                <>
                  {/* image file with message */}
                  <SendImageWithSpan
                    message={message}
                    setChatreply={setChatreply}
                    isSameUser={isSameUser}
                    setreplymsg={setreplymsg}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {/*  only file */}
              {message?.messageData?.doc?.split(".")[
                message?.messageData?.doc?.split(".")?.length - 1
              ] === "pdf" ? (
                <>
                  {/* only pdf file */}
                  <PdfMessageSend
                    message={message}
                    setChatreply={setChatreply}
                    setreplymsg={setreplymsg}
                  />
                </>
              ) : (
                <>
                  {/* only image file */}
                  <SelfImageMessage
                    message={message}
                    setChatreply={setChatreply}
                    setreplymsg={setreplymsg}
                  />
                </>
              )}
            </>
          )
        ) : message?.replyOf?.messageData?.doc === null ? (
          <>
            {/* simple text Message send */}
            <ChatSelfReply
              setChatreply={setChatreply}
              forwardtoChatReplymsg={forwardtoChatReplymsg}
              message={message}
              setreplymsg={setreplymsg}
            />
          </>
        ) : message?.replyOf?.messageData?.description &&
          message?.replyOf?.messageData?.description != "" ? (
          <>
            {/* file with message */}
            {/* {console.log(
              message,
              message.replyOf.messageData.doc,
              message.replyOf.messageData.doc.split(".")[
                message.replyOf.messageData.doc.split(".").length - 1
              ],
              "message with doc"
            )} */}
            {message?.replyOf?.messageData?.doc?.split(".")[
              message?.replyOf?.messageData?.doc?.split(".").length - 1
            ] === "pdf" ? (
              <>
                {/* pdf file with message */}
                <PdfReplysend
                  type="pdfWithMessage"
                  message={message}
                  setreplymsg={setreplymsg}
                  setChatreply={setChatreply}
                />
              </>
            ) : (
              <>
                {/* image file with message */}
                <SelfImageReply
                  type="imageWithMessage"
                  message={message}
                  setreplymsg={setreplymsg}
                  setChatreply={setChatreply}
                />
              </>
            )}
          </>
        ) : (
          <>
            {/*  only file */}
            {message.replyOf.messageData?.doc?.split(".")[
              message?.replyOf?.messageData?.doc?.split(".").length - 1
            ] === "pdf" ? (
              <>
                {/* only pdf file */}
                <PdfReplysend
                  type="pdf"
                  setreplymsg={setreplymsg}
                  message={message}
                  setChatreply={setChatreply}
                />
              </>
            ) : (
              <>
                {/* only image file */}
                <SelfImageReply
                  type="image"
                  setreplymsg={setreplymsg}
                  message={message}
                  setChatreply={setChatreply}
                />
              </>
            )}
          </>
        )}

        {/* simple image mesage send  */}
        {/* <SelfImageMessage setChatreply={setChatreply} /> */}

        {/* simple text Message send */}
        {/* <ChatSimpleMessageSend setChatreply={setChatreply} /> */}

        {/* reply to a simple text message */}
        {/* <ChatSelfReply
          setChatreply={setChatreply}
          forwardtoChatReplymsg={forwardtoChatReplymsg}
        /> */}

        {/* chat image message with span */}
        {/* <SendImageWithSpan setChatreply={setChatreply} /> */}

        {/* chat message of pdf with span */}
        {/* <PdfWithSpan setChatreply={setChatreply} /> */}

        {/* simple pdf message send  */}
        {/* <PdfMessageSend setChatreply={setChatreply} /> */}

        {/* chat image self reply */}
        {/* <SelfImageReply /> */}

        {/* pdf reply */}
        {/* <PdfReplysend /> */}
      </div>
{
  isSameUser === true ?  <div
        className="usernamelogo"
        //   style={{ background: `${color}` }}
        style={{ background: "purple" }}
      >
        {message?.sendFrom?.name?.charAt(0)}
      </div>
      :
      <div
      className="usernamelogo"
    >
    </div>
}
      
    </div>
  );
}

export default MessageSend;
