import React, { useEffect, useState } from "react";
import "./chatinfo.css";
import chatinfopic from "../../assest/Image/chatinfopic.png";
import sequencelogo from "../../assest/Image/sequencelogo.png";
import usersbold from "../../assest/Image/usersbold.png";
import exportchat from "../../assest/Image/exportchat.png";
import addblueicon from "../../assest/Image/addblueicon.png";
import Adduserinquery from "../Query/Adduserinquery";
// import chatinfopic from '../../assest/Image/chatinfopic.png'
// import usersbold from "../../assest/Image/usersbold.png"
import { BiChevronRight, BiArrowBack, BiX } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { handle_delete_user_from_query } from "../../Functions/projectsFunction";
import Deleteuserinchat from "./Deleteuserinchat";
function Chatinfo({ setShowchat, setIsCloseChat }) {
  const dispatch = useDispatch();
  const [deleteuser, setDeleteuser] = useState(false);
  const [userDetail, setUserDetail] = useState();
  const selectedProject = useSelector((state) => {
    return state.selectedProject;
  });
  const selectedQuery = useSelector((state) => {
    return state.query;
  });

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    if (selectedQuery) setUserList(selectedQuery?.queryUsers);
  }, [selectedQuery]);

  // add user in query modal open
  const [showadduserinquery, setShowadduserinquery] = useState(false);
  // End add user in query modal open
  const handleClick = () => {
    setShowchat(true);
  };

  const handleDelete = ({ user }) => {
    setUserList(userList.filter((ele) => ele.email != user));
  };

  // const usermail =

  return (
    <div className="chatinfomaindiv">
      <div className="chatInfoheader">
        <BiArrowBack
          className="chatinfoback"
          style={{
            cursor: "pointer",
          }}
          onClick={handleClick}
        />
        <img src={sequencelogo} />
        <span>{selectedQuery?.queryTitle}</span>
      </div>

      <div className="chatcreatedinfo">
        <div className="querychatinfo">
          <img src={chatinfopic} />
          <span>Chat Info</span>
        </div>
        <div className="querycreatedby">
          This Query was created by {selectedQuery?.createrName?.name} (
          {selectedQuery?.createrName?.profession}) on{" "}
          {moment(selectedProject?.projectData?.createdAt).format("DD/MM/YYYY")}
        </div>
      </div>
      <hr className="chatinfohr" />
      <div className="alluserinquerylist">
        <div className="chatqueryusers">
          <img src={usersbold} />
          <span> {userList?.length + 1 || 0} users in this Query</span>
        </div>

        {/* // creater added */}

        <div className="listofuser">
          <div className="listofadminandsimpleuser">
            <div className="logofirstletter">A</div>
            <div className="userlistname">
              {selectedQuery?.createrName?.name || ""}{" "}
              <span> (super-admin)</span>
            </div>
          </div>
          <div className="chatinfouserbtn">Creater</div>
        </div>
        {/* // creater added */}

        {userList?.map((ele, index) => {
          let user = selectedProject?.projectData?.projectUsers.find(
            (e) => e.email == ele?.email
          );
          return (
            <div className="listofuser">
              <div className="listofadminandsimpleuser">
                <div className="logofirstletter">A</div>
                <div className="userlistname">
                  {ele?.user?.name} <span> ({user?.role})</span>
                </div>
              </div>
              <div
                style={{
                  cursor: "pointer",
                }}
                className="chatinfouserbtn"
                onClick={() => {
                  setDeleteuser(true);
                  setUserDetail(ele.email);
                  // handle_delete_user_from_query({
                  //   dispatch,
                  //   project: selectedQuery?.project,
                  //   query: selectedQuery._id,
                  //   user: ele.email,
                  // });
                  // handleDelete({ user: ele.email });
                }}
              >
                Delete User
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="adduserinquery"
        style={{
          cursor: "pointer",
        }}
        onClick={() => setShowadduserinquery(true)}
      >
        <img src={addblueicon} /> <div>Add User</div>{" "}
        <span>(add new user in this Query)</span>
      </div>
      <hr className="chatinfohr" />
      <div className="exportchat">
        <div
          className="exportchatdiv"
          style={{
            cursor: "pointer",
          }}
        >
          {" "}
          <img src={exportchat} />
          <div>
            Export Chat <span>(Only PDF format)</span>
          </div>
        </div>
        {/* <BiChevronRight className='exportchatrightarrow'/> */}
      </div>
      <hr className="chatinfohr" />
      <div className="closechat" onClick={() => setIsCloseChat(true)}>
        <div
          className="closechatdiv"
          style={{
            cursor: "pointer",
          }}
          onClick={() => setShowchat(true)}
        >
          <BiX className="crossclosechat" />
          <div>
            Close Chat{" "}
            <span>
              {" "}
              (This chat can be closed only when both admin and fabricator will
              do this)
            </span>
          </div>
        </div>
        {/* <BiChevronRight className='exportchatrightarrow'/> */}
      </div>
      <hr className="chatinfohr" />
      <div className="chatstatusdiv">
        <div className="chatstatusdivspan">
          <img src={chatinfopic} /> <div>Chat Status</div>
        </div>

        <div className="chatcloseadmin">
          <div className="logofirstletter">A</div>
          <div className="Adminname">
            Ajay <span>(AOR)</span>
          </div>
          <div className="rolehighlight"> ADMIN</div>
          <div className="dotdiv"></div>
          <div className="chatstatusbyadmin">closed</div>
        </div>

        <div className="chatclosefab">
          <div className="logofirstletter">A</div>
          <div className="fabname">
            Ajay <span>(fabricator)</span>
          </div>
          <div className="dotdiv"></div>
          <div className="chatstatusbyfab">Pending</div>
        </div>
      </div>
      {showadduserinquery && selectedQuery ? (
        <Adduserinquery
          showadduserinquery={showadduserinquery}
          setShowadduserinquery={setShowadduserinquery}
          selectedProject={selectedProject}
          selectedQuery={selectedQuery}
        />
      ) : null}

      {deleteuser === true && userDetail ? (
        <Deleteuserinchat
          deleteuser={deleteuser}
          setDeleteuser={setDeleteuser}
          selectedQuery={selectedQuery}
          handleDelete={handleDelete}
          userDetail={userDetail}
          //  project= {selectedQuery?.project}
          //         query={ selectedQuery._id}
          //         user= {ele.email}
          //     handleDelete={handleDelete}
        />
      ) : null}
    </div>
  );
}

export default Chatinfo;
