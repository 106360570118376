import React, { useEffect, useState } from "react";
import "./useraccess.css";
import { IoIosArrowDown } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import UserAccesspopover from "./UserAccesspopover";
import { useDispatch, useSelector } from "react-redux";
import { get_user_access_list_actionCreater } from "../../Redux/profile/CreateActions";
import {
  handle_delete_user_updateUserAccess,
  handle_open_popover,
} from "../../Functions/profileFunctions";
import DeleteuserAccess from "./DeleteuserAccess";
function UserAccess() {
  const dispatch = useDispatch();
  const [deleteuser, setDeleteuser] = useState(false);
  const [handle_selectedData, set_handle_selectedData] = useState();
  const [project_detail, set_project_detail] = useState();
  const [selected_user, set_selected_user] = useState();

  const userAccessList = useSelector((state) => {
    return state.profile;
  });

  useEffect(() => {
    get_user_access_list_actionCreater({ dispatch });
  }, []);

  const [OpenAccess, setOpneAccess] = useState(false);
  const isAccessopen = Boolean(OpenAccess);
  const openaccessid = OpenAccess ? "simple-popover" : undefined;

  const openpopup = (event) => {
    setOpneAccess(event.target);
  };

  return (
    <div className="useracccessmaindiv">
      <div className="useraccessheading"> User Access</div>

      <div className="useraccesstablehaeding">
        {" "}
        <div className="useraccessemaildiv">
          <span>User Email</span>
        </div>{" "}
        <div className="useraccessnamediv">
          <span>User Name</span>
        </div>{" "}
        <div className="useraccesscompanynamediv">
          <span>Company Name</span>
        </div>{" "}
        <div className="useraccessAccessdiv">
          <span>Access</span>
        </div>
      </div>
      <div className="useraccessscrolldiv">
        {userAccessList.length
          ? userAccessList.map((value, idx) => {
              return (
                <div className="useraccessdata" key={idx}>
                  {" "}
                  <div className="useraccessemaildiv">
                    {value.user_detail.email}
                  </div>{" "}
                  <div className="useraccessnamediv">
                    {value.user_detail.name} ({value.user_detail.profession})
                  </div>{" "}
                  <div className="useraccesscompanynamediv">
                    {value.user_detail.companyName}
                  </div>
                  <div className="accessallowd">
                    {value.role_list?.admin ? (
                      <div className="accessallowdmaindivadmin">
                        <span>Admin</span>
                      </div>
                    ) : (
                      <div
                        className="accessallowdmaindivadmin"
                        style={{
                          background: "gray",
                        }}
                      >
                        <span>Admin</span>
                      </div>
                    )}
                    {value.role_list?.dco ? (
                      <div className="accessallowdmaindivdoc">
                        <span>DOC</span>
                      </div>
                    ) : (
                      <div
                        className="accessallowdmaindivdoc"
                        style={{
                          background: "gray",
                        }}
                      >
                        <span>DOC</span>
                      </div>
                    )}
                    {value.role_list?.user ? (
                      <div className="accessallowdmaindivuser">
                        <span>user</span>
                      </div>
                    ) : (
                      <div
                        className="accessallowdmaindivuser"
                        style={{
                          background: "gray",
                        }}
                      >
                        <span>user</span>
                      </div>
                    )}
                    <IoIosArrowDown
                      className="tablearrowdown"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        openpopup(e);
                        handle_open_popover({
                          value,
                          userAccessList,
                          set_handle_selectedData,
                          set_project_detail,
                          set_selected_user,
                        });
                      }}
                    />
                    <RiDeleteBin5Line
                      onClick={
                        () => {
                          setDeleteuser(true);
                          set_selected_user(value.user_detail.email);
                        }
                        //    {
                        //   handle_delete_user_updateUserAccess({
                        //     dispatch,
                        //     selected_user: value.user_detail.email,
                        //   });
                        // }
                      }
                      style={{ cursor: "pointer", color: "red" }}
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>

      {deleteuser === true ? (
        <DeleteuserAccess
          deleteuser={deleteuser}
          setDeleteuser={setDeleteuser}
          selected_user = {selected_user}
        />
      ) : null}
      {userAccessList && project_detail && selected_user ? (
        <UserAccesspopover
          isAccessopen={isAccessopen}
          openaccessid={openaccessid}
          OpenAccess={OpenAccess}
          setOpneAccess={setOpneAccess}
          project_detail={project_detail}
          handle_selectedData={handle_selectedData}
          set_handle_selectedData={set_handle_selectedData}
          selected_user={selected_user}
        />
      ) : null}
    </div>
  );
}

export default UserAccess;
