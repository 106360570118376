import React from "react";
import "../recivedmessages.css";
import { MdOutlineReplyAll } from "react-icons/md";
import createprojectimage from "../../../../assest/Image/createprojectimage.png";
function ImageWithSpan({ setChatreply, message, setreplymsg }) {
  return (
    <div className="imagewithspanmaindiv">
      <div className="replyofamessagemaindiv" style={{ background: "red" }}>
        <div className="imagewithspan">
          <img src={message?.messageData?.doc} />
        </div>
        <div style={{ padding: "3%", fontSize: "13px" }}>
          {message?.messageData?.description}
        </div>
      </div>
      <div className="chatreplydiv" onClick={() => setChatreply(true)}>
        <MdOutlineReplyAll
          onClick={() => {
            setreplymsg({
              name: message.sendFrom.name,
              profession: message.sendFrom.profession,
              color: null,
              message: message.messageData.description,
              message_id: message._id,
            });
          }}
        />
      </div>
    </div>
  );
}

export default ImageWithSpan;
