import React, { useState } from "react";
import "./Slidescreen.css";
import slider1 from "../../assest/Image/slider1.png";
import SlideScreen2 from "./SlideScreen2";
import Slidescreen3 from "./Slidescreen3";

import { FiArrowRight } from "react-icons/fi";
function SlideScreen1() {
  const [slide1, setSlide1] = useState(true);
  const [slide2, setSlide2] = useState(false);
  const [slide3, setSlide3] = useState(false);

  const handleClick = () => {
    setSlide2(true);
    setSlide1(false);
  };
  if (slide1 === true) {
    return (
      <div className="SlideScreen1div">
        <div className="spares">
          <div className="active"></div>
          <div className="spare"></div>
          <div className="spare"></div>
        </div>
        <div>
          <div className="sliderhi">Hi! User</div>
          <div className="sliderp">Welcome to the QMS software we would</div>
          <div className="sliderp">
            like to make you a little familiar about how{" "}
          </div>
          <div className="sliderp">to use this in next 2 step</div>
        </div>
        <button className="sliderbtn1" onClick={handleClick}>
          <span>Next</span>{" "}
          <FiArrowRight style={{ color: "white", marginBottom: "2%" }} />
        </button>

        <div className="slider1img">
          <img src={slider1} />
        </div>
      </div>
    );
  } else if (slide2 === true) {
    return (
      <SlideScreen2
        setSlide1={setSlide1}
        setSlide2={setSlide2}
        setSlide3={setSlide3}
      />
    );
  } else {
    return <Slidescreen3 />;
  }
}

export default SlideScreen1;
