import React, { useState, useRef, useEffect } from "react";
import "./notifiation.css";
import { useDispatch, useSelector } from "react-redux";
import {
  get_user_notifications,
  handle_read_notifications,
} from "../../Functions/headerFunction";
import moment from "moment/moment";
import { useNavigate } from "react-router";

function DetectOutsideClick(ref, setShownotification) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShownotification(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function Notification({ shownotification, setShownotification }) {
  const [allnotification, setAllnotification] = useState(false);
  const notifyref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const notifications = useSelector((state) => {
    return state.notification;
  });
  DetectOutsideClick(notifyref, setShownotification);
  useEffect(() => {
    get_user_notifications({ dispatch });
  }, []);
  
  return allnotification !== true ? (
    <div className="notoficationmaindiv" ref={notifyref}>
      <div className="notificationspan">Notification</div>
      {notifications?.notificationList
        ? notifications?.notificationList?.slice(0, 5).map((ele) => {
            return (
              <>
                <hr className="notifyhr" />
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handle_read_notifications({ dispatch, id: ele._id });
                    setShownotification(false);
                    if (ele?.notificationType === "project")
                      navigate(`/home/project/${ele.project}`);
                  }}
                  className={`${
                    ele.notificationRead
                      ? "notificationdiv"
                      : "notificationdivblue"
                  }`}
                >
                  <div>{ele?.notificationMessageData}</div>
                  <span>
                    {ele.createdAt
                      ? moment(ele.createdAt).format("DD/MM/YYYY hh:mm:ss A")
                      : null}
                  </span>
                </div>
              </>
            );
          })
        : null}
      {/* <hr className="notifyhr" />
      <div className="notificationdiv">
        <div>You have been added to Optech Solutions.</div>
        <span>Ajay Gupta • March 22, 2023 at 9:15 AM</span>
      </div>
      <hr className="notifyhr" />
      <div className="notificationdivblue">
        <div>You have been added to Optech Solutions.</div>

        <span>Ajay Gupta • March 22, 2023 at 9:15 AM</span>
      </div>
      <hr className="notifyhr" />
      <div className="notificationdiv">
        <div>You have been added to Optech Solutions.</div>
        <span>Ajay Gupta • March 22, 2023 at 9:15 AM</span>
      </div> */}
      <hr className="notifyhr" />
      <div className="allnotification" onClick={() => setAllnotification(true)}>
        View All
      </div>
    </div>
  ) : (
    <div className="showallnotoficationmaindiv" ref={notifyref}>
      <div className="showallnotoficationmaindivmini">
        <div className="notificationspan">Notification</div>
        {notifications?.notificationList
          ? notifications?.notificationList?.map((ele) => {
              return (
                <>
                  <hr className="notifyhr" />
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handle_read_notifications({ dispatch, id: ele._id });
                      setShownotification(false);
                      if (ele?.notificationType === "project")
                        navigate(`/home/project/${ele.project}`);
                    }}
                    className={`${
                      ele.notificationRead
                        ? "notificationdiv"
                        : "notificationdivblue"
                    }`}
                  >
                    <div>{ele?.notificationMessageData}</div>
                    <span>
                      {ele.createdAt
                        ? moment(ele.createdAt).format("DD/MM/YYYY hh:mm:ss A")
                        : null}
                    </span>
                  </div>
                </>
              );
            })
          : null}

        <hr className="notifyhr" />
      </div>
      <div
        className="allnotification"
        onClick={() => setAllnotification(false)}
      >
        Close Notification
      </div>
    </div>
  );
}

export default Notification;
