import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./Inviteuser.css";
import { FiX } from "react-icons/fi";
import { handle_invite_user } from "../../Functions/profileFunctions";
import { useState } from "react";
import { useDispatch } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "30%",
  border: "none",
  outline: "none",
};
export default function InviteUser({ openModal, setOpenmodal }) {
  const handleClose = () => setOpenmodal(false);
  const [email, setEmail] = useState();

  const dispatch = useDispatch();
  const handleChange = (element) => {
    setEmail(element.target.value);
  };

  const HandleInviteUser = (event) =>{
    if(event.keyCode === 13)
    {
      handle_invite_user({ email, handleClose, dispatch,setEmail })
    }
   
  }
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="inviteusermaindiv">
            <div className="inviteuserheader">
              <span>Invite User</span>
              <div className="inviteusercrosss" onClick={handleClose}>
                <FiX />
              </div>
            </div>

            <div className="inviteuserbody">
              <input
                type="email"
                placeholder="Enter email"
                onChange={handleChange}
                onKeyDown={(e)=>HandleInviteUser(e)}
              />

              <div className="modalsavebtn">
                <span
                  onClick={() => {
                    handle_invite_user({ email, handleClose, dispatch,setEmail });
                  }}
                >
                  Send Invite
                </span>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
