import React from "react";
import { MdOutlineReplyAll } from "react-icons/md";
function ReplyofSimpleMessage({ setChatreply, message, setreplymsg,forwardtoChatReplymsg }) {
  const replyofmessage = "Hii Ajay How R you Brother";
  return (
    <div className="recievedmessagereply">
      <div className="replyofamessagemaindiv" style={{ background: "red" }}>
        <div className="replyto">
          <div
            className="replytousername"
            onClick={() => {
              forwardtoChatReplymsg(message?.replyOf._id)
            }}
            // style={{ color: `${color}` }}
            style={{ color: "red" }}
          >
            {message?.replyOf?.sendFrom.name} (
            {message?.replyOf?.sendFrom.profession})
          </div>
          <div className="messagethatreply">
            {/* {data?.replyOf?.messageData?.description} */}
            {message?.replyOf?.messageData?.description.substring(0, 15)}...
          </div>
        </div>
        <div style={{ padding: "3%", fontSize: "13px" }}>
          {message?.messageData?.description}
        </div>
      </div>
      <div
        className="chatreplydiv"
        //   onClick={() =>
        //     handleReply(
        //       data?.sendFrom?.name,
        //       data?.messageData?.description,
        //       data?.sendFrom?.profession,
        //       color,
        //       data._id
        //     )
        //   }
        onClick={() => setChatreply(true)}
      >
        <MdOutlineReplyAll />
      </div>
    </div>
  );
}

export default ReplyofSimpleMessage;
