import React, { useState } from 'react'
import './Newpassword.css'
import Logo from '../../assest/Image/Logo.png'
import Building from '../../assest/Image/Building.png'
import * as Yup from 'yup';
import verifyfinal from '../../assest/Image/verifyfinal.png'
import password from '../../assest/Image/password.png'
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import BuildB from '../../assest/Image/BuildB.png'
import axios from 'axios';
function Newpassword() {
    const location = useLocation();
    const navigate = useNavigate();

    const handleNewpassword = async (password) => {
        try {
            const response = await axios.post(`${process.env.React_App_prod_url}/user/setnewpassword`, {
                email: location.state.email,
                newPassword: password.Password
            })
            if (response.status === 200) {
                return response.status
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    // const 
    const initialValues = {
        Password: "",
        Conform_Password: "",
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            Password: Yup.string().min(6).max(30).required(" Please Enter Your Password"),
            Conform_Password: Yup.string().required().oneOf([Yup.ref("Password"), null], "Password must match")
        }),
        onSubmit: async (values, action) => {
            const responsestatus = await handleNewpassword(values);
            if (responsestatus === 200) {
                action.resetForm();
                navigate('/')
            }

        }

    })
    return (
        <>
            <form>
                <div className="row p-0 m-0">
                    <div className="col-md-7 col-12 p-0">
                        <div className='newpasswordLogindiv'>
                            {/* <div className='newpasswordsmalldiv'> */}
                            <img src={BuildB} className='newpasswordimage' />
                            <h3 className='newpasswordh2designverify'>New password </h3>
                            <div className='newpasswordotpmsg'>Set a new password here </div>
                            <div className='chnagepassworddiv'>
                                <div className="input-group mb-2 newpasswordpassworddiv shadow-none ">
                                    <span className="input-group-text newpasswordemailspan" id="basic-addon1" htmlFor='Password'><img src={password} className='nameimage' /></span>
                                    <input type="password" className="form-control newpasswordnameinput shadow-none" onChange={formik.handleChange} placeholder="Password" value={formik.values.Password} name='Password' aria-describedby="basic-addon1" />
                                </div>
                                {
                                    formik.errors.Password && formik.touched.Password ? <p className='paragraph'>{formik.errors.Password}</p> : null
                                }
                                <div className="input-group mb-2 newpasswordpassworddiv2 shadow-none ">
                                    <span className="input-group-text newpasswordemailspan" id="basic-addon1" htmlFor='Conform_Password'><img src={password} className='nameimage' /></span>
                                    <input type="password" className="form-control newpasswordnameinput shadow-none" onChange={formik.handleChange} placeholder="Conform Password" value={formik.values.Conform_Password} name='Conform_Password' aria-describedby="basic-addon1" />
                                </div>
                                {formik.errors.Conform_Password && formik.touched.Conform_Password ? <p className='paragraph'>{formik.errors.Conform_Password}</p> : null}
                                <button className='newpasswordloginbtn' type='button' onClick={formik.handleSubmit}><span style={{ color: 'white', fontSize: "large" }}>Save</span></button>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="col-md-5 col-12 p-0">
                        <div className='Lineargradientdiv'>
                            <div className='lineargradientminidiv'>
                                <img src={verifyfinal} className='verifyimage' />
                                <img src={Building} className='building' />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Newpassword