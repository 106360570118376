import { GET_USER_NOTIFICATIONS } from "../constants";

export default function NotificationsReducer(state = {}, action) {
  switch (action.type) {
    case GET_USER_NOTIFICATIONS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
