import React from "react";
import pdf from "../../../../assest/Image/pdf.png";
import "../sendmessage.css";
import download from "../../../../assest/Image/download.png";
import { MdOutlineReplyAll } from "react-icons/md";
function PdfMessageSend({ setChatreply, message ,setreplymsg}) {
  const pdfname =
    "Sandeep Sokle Pdf sdvkjsdhv dkshgjkash sdgksajg ksadghsakl aghlkjsa dkglaksjkgsda lsdgsdlakj";

  return (
    <div className="selfpdfreply">
      <div
        className="selfreplydiv"
        //   onClick={() =>
        //     handleReply(
        //       data?.sendFrom?.name,
        //       data?.messageData?.doc
        //         ?.split("/")[4]
        //         ?.substring(13),
        //       data?.sendFrom?.profession,
        //       color,
        //       data._id
        //     )
        //   }
        onClick={() => setChatreply(true)}
      >
        <MdOutlineReplyAll 
        onClick={() => {
            setreplymsg({
              name: message.sendFrom.name,
              profession: message.sendFrom.profession,
              color: null,
              message: message.messageData.description,
              message_id: message._id,
            });
          }}/>
      </div>
      <div className="selfpdfmaindiv">
        <div className="sendispdftrue">
          <img src={pdf} className="sendpdfimage" />
          <div className="selfpdfspan">
            {/* {data?.messageData?.doc
            ?.split("/")[4]
            ?.substring(13)} */}
            {message?.messageData?.doc?.split("/")[4]?.substring(13)}...
          </div>

          <img
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              // window.location.href = message.messageData.doc;
              window.open(message?.messageData?.doc, "_blank");
            }}
            src={download}
            className="downladimage"
          />
        </div>
      </div>
    </div>
  );
}

export default PdfMessageSend;
