import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import "./Queryoption.css";
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";
import movequery from "../../assest/Image/movequery.png";
import movequerywhite from "../../assest/Image/movequerywhite.png";
import EditQuerymodal from "./EditQuerymodal";
import DeleteQuery from "./DeleteQuery";
import editpic from "../../assest/Image/editpic.png";
import whiteeditpencil from "../../assest/Image/whiteeditpencil.png";
import Movequery from "./Movequery";
function QueryOption({
  setSubQuerypopup,
  SubQueryid,
  SubQueryopen,
  subQuerypopup,
  selectedQuery,
  setAnchorEl,
  selectedSequence,
}) {
  // move query in sequence options are come here
  const [showmovequery, setShowmovequery] = useState(null);
  const handleMoveQuery = (event) => {
    setShowmovequery(event.currentTarget);
  };


  const movequeryopen = Boolean(showmovequery);
  const movequeryid = movequeryopen ? "simple-popover" : undefined;
  // end of move query options here

  const [openeditquerynamemodal, setOpeneditquerynamemodal] = useState(null);
  const [opendeletequerynamemodal, setOpendeletequerynamemodal] =
    useState(null);
  // handle hover
  const [moveequeryhover, setMovequeryhover] = useState(false);
  const [editqueryhover, setEditqueryhover] = useState(false);
  // handle hover end here
  const handleeditquerymodal = (event) => {
    setOpeneditquerynamemodal(event.target);
  };
  const handledeletequerynamemodal = (event) => {
    setOpendeletequerynamemodal(event.target);
  };
  const handleClose = () => {
    setSubQuerypopup(null);
  };
  return (
    <Popover
      id={SubQueryid}
      open={SubQueryopen}
      anchorEl={subQuerypopup}
      onClose={handleClose}
      sx={{
        borderColor: "#194895",
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Typography
        component={"div"}
        className="SubQueryOptions"
        style={{ boxShadow: "box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3)" }}
      >
        <div
          className="editquery"
          onClick={(e) => handleeditquerymodal(e)}
          onMouseOver={() => setEditqueryhover(true)}
          onMouseOut={() => setEditqueryhover(false)}
          style={{
            cursor: "pointer",
          }}
        >
          <img
            src={editqueryhover ? whiteeditpencil : editpic}
            className="editquerynamelogo"
          />
          <span>Edit</span>
        </div>
        <hr className="horizontalline" />
        <div
          className="movequery"
          onMouseOver={() => setMovequeryhover(true)}
          onMouseOut={() => setMovequeryhover(false)}
          onClick={(e) => handleMoveQuery(e)}
          style={{
            cursor: "pointer",
          }}
        >
          <img src={moveequeryhover ? movequerywhite : movequery} />
          <span>Move</span>
        </div>
        <hr className="horizontalline" />
        <div
          className="deletequery"
          onClick={(e) => handledeletequerynamemodal(e)}
          style={{
            cursor: "pointer",
          }}
        >
          <FaTrashAlt className="deletequerylogo" />
          <span>Delete</span>{" "}
        </div>
      </Typography>
      {openeditquerynamemodal ? (
        <EditQuerymodal
          setOpeneditquerynamemodal={setOpeneditquerynamemodal}
          openeditquerynamemodal={openeditquerynamemodal}
          selectedQuery={selectedQuery}
          setAnchorEl={setAnchorEl}
        />
      ) : null}
      {opendeletequerynamemodal ? (
        <DeleteQuery
          setOpendeletequerynamemodal={setOpendeletequerynamemodal}
          opendeletequerynamemodal={opendeletequerynamemodal}
          selectedQuery={selectedQuery}
          setAnchorEl={setAnchorEl}
        />
      ) : null}
      {showmovequery ? (
        <Movequery
          showmovequery={showmovequery}
          setShowmovequery={setShowmovequery}
          movequeryid={movequeryid}
          movequeryopen={movequeryopen}
          selectedQuery={selectedQuery}
          setAnchorEl={setAnchorEl}
          old_sequence_id={selectedSequence}
        />
      ) : null}
    </Popover>
  );
}

export default QueryOption;
