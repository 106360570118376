import React from 'react'
import exportimage from "../../assest/Image/export.png";
function CloseChat({setIsCloseChat}) {
  return (
    <div className="closechatboxmaindiv">
    <div style={{ marginLeft: "4%" }}>
      <div className="closechatredspan">
        This Chat is closed. You can not send any text here.
      </div>
      <div
        className="closechatbluespan"
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setIsCloseChat(false);
        }}
      >
        Tap to Re-Open this chat
      </div>
    </div>
    <div className="closechatexportbtn">
      <img src={exportimage} />
      <div>export chat</div>
    </div>
  </div>
  )
}

export default CloseChat