import React, { useState } from "react";
import "./chatside.css";
import projectchat from "../../assest/Image/projectchat.png";
import cross from "../../assest/Image/cross.png";
import whiteuserpic from "../../assest/Image/whiteuserpic.png";

import projectnameicon from "../../assest/Image/projectnameicon.png";

import pdf from "../../assest/Image/pdf.png";
import { ChatcolorFunction } from "../../Functions/Chatfunction";
import Chatinfo from "./Chatinfo";
import ChatArea from "./ChatArea";
import { useSelector } from "react-redux";
function Groupchat() {
  const [showchat, setShowchat] = useState(true);

  const [isCloseChat, setIsCloseChat] = useState(false);
  const selectedQuery = useSelector((state) => {
    return state.query;
  });

  const handleClick = () => {
    setShowchat(false);
  };
  const chatdata = [
    {
      firstnameletter: "A",
      Name: "ajay",
      occupation: "admin",
      message: "hey hii Everyone",
      isreply: false,
      ispdf: true,
    },
    {
      firstnameletter: "B",
      Name: "buland",
      occupation: "AOR",
      message: "how r you sir",
      isreply: false,
      ispdf: true,
    },
    {
      firstnameletter: "G",
      Name: "Garv",
      occupation: "Archetecture",
      message:
        "what is the update guys sdfjasgagasjgd agaksjhgskahsgad dahgakjsdhkasdgs sagdjaskhgsda ksdhgksdajhgsdak kajshgddaskhgads ksdagsadkjags asdkjhgaskjhgdsak kasghksdjahgdska ksahgkasd",
      isreply: false,
      ispdf: false,
    },
    {
      firstnameletter: "N",
      Name: "Nadeem",
      occupation: "Fabricator",
      message: "work in process",
      isreply: false,
      ispdf: false,
    },
    {
      firstnameletter: "G",
      Name: "Garv",
      occupation: "Archetecture",
      message: "how much time it will takea",
      isreply: false,
      ispdf: false,
    },
    {
      firstnameletter: "B",
      Name: "buland",
      occupation: "AOR",
      message:
        "does anyone response here jlfsjhso hjsdlkjhldshfsd lhjkfsdljsd fjhsdljhldshdsf lfsjhlsjhsl  dshdsljdfsl dshlkdshldfks lsdjhldsfkjsd",
      isreply: true,
      ispdf: false,
    },
    {
      firstnameletter: "B",
      Name: "buland",
      occupation: "AOR",
      message: "does anyone response here jlfsjhso hjsdlkjhldshf",
      isreply: true,
      ispdf: false,
    },
    {
      firstnameletter: "B",
      Name: "buland",
      occupation: "AOR",
      message:
        "does anyone response here  lsdjhldsfkjsd etkewk sgjg skhagksh jsagash jasgsak sajgsak jasgfsaj jsafgjas jhasgfjas jvasfaj ajsgfsja",
      isreply: false,
      ispdf: false,
    },
    {
      firstnameletter: "A",
      Name: "ajay",
      occupation: "admin",
      message: "hiii",
      isreply: false,
      ispdf: false,
    },
    {
      firstnameletter: "B",
      Name: "buland",
      occupation: "AOR",
      message: "Done ):",
      isreply: true,
      ispdf: false,
    },
    {
      firstnameletter: "A",
      Name: "ajay",
      occupation: "admin",
      message: "Done ):",
      isreply: false,
      ispdf: false,
    },
    {
      firstnameletter: "G",
      Name: "Garv",
      occupation: "Archetecture",
      message: "Done ):",
      isreply: false,
      ispdf: false,
    },
    {
      firstnameletter: "N",
      Name: "Nadeem",
      occupation: "Fabricator",
      message: "Done):",
      isreply: true,
      ispdf: false,
    },
  ];
  return showchat == true ? (
    <div className="mainchat">
      <div
        className="chatheader"
        onClick={handleClick}
        style={{
          cursor: "pointer",
        }}
      >
        <img src={projectnameicon} />
        <span>{selectedQuery?.queryTitle}</span>
        <div className="chatheaderimagespandiv">
          <img src={whiteuserpic} />
          <span>{selectedQuery?.queryUsers?.length + 1 || 0} users</span>
        </div>
      </div>
      <div className="chatdocs">
        <input type="file" className="changedocumentclass" />
        <img src={cross} style={{ height: "12px" }} />
        <img src={pdf} />
        <a target="_blank" href={selectedQuery?.document} className="anchor">
          {selectedQuery?.document?.split("/")[4]?.substring(13)}
        </a>
        <span>
          (The Query {selectedQuery?.queryTitle} chat is based on this document)
        </span>
      </div>
      <ChatArea isCloseChat={isCloseChat} setIsCloseChat={setIsCloseChat} />

    </div>
  ) : (
    <Chatinfo setShowchat={setShowchat} setIsCloseChat={setIsCloseChat} />
  );
}

export default Groupchat;
