import * as React from "react";
import Box from "@mui/material/Box";
import "./contact.css";
import Modal from "@mui/material/Modal";
import { FiX } from "react-icons/fi";
import { handleContactUs } from "../../Functions/profileFunctions";
import { useState } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "38%",
  border: "none",
  outline: "none",
};
export default function Contactus({
  showcontactusmadal,
  setShowcontactusmodal,
  // setShowProfile,
}) {
  const [message, setMessage] = useState("");
  const handleClose = () => {
    setShowcontactusmodal(false);
    // setShowProfile(false);
  };

  return (
    <div>
      <Modal
        open={showcontactusmadal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="contactusmodalmaindiv">
            <div className="contactusheader">
              <span>Conatct Us</span>
              <div className="contactcrosss" onClick={ ()=>setShowcontactusmodal(false)}>
                <FiX />
              </div>
            </div>

            <div className="contactbody">
              <textarea
                // type="text"
                className="contacttextarea"
                placeholder="Enter your message"
             
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className="contactsavebtn">
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleContactUs({ message, handleClose })}
                >
                  Send
                </span>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
