import React from "react";
import Addproject from "./Addproject";
import "./createproject.css";
import city from "../../assest/Image/city.png";
import createprojectimage from "../../assest/Image/createprojectimage.png";
import { useState } from "react";
import { BsCloudFog } from "react-icons/bs";
import { useEffect } from "react";
import companyimg from "../../assest/Image/companyimg.png";
import name from "../../assest/Image/name.png";
import Selectcompany from "../select/Selectcompany";
import {
  get_my_company,
  get_users_from_company,
  handle_create_project,
} from "../../Functions/projectsFunction";
import Adduserlist from "./Adduserlist";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
function Createproject() {
  const [projectName, setProjectName] = useState(""); // set Project Name
  const [selectedCompanyList, selectedSetCompanyList] = useState([]); // seceted Company
  const [selectedUserList, selectedSetUserList] = useState([]); // selected  Users From Company
  const [companyList, setCompanyList] = useState([]); // all company List of Admin User
  const [userList, setUserList] = useState([]); // all Users From Selected Company
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChangeProjectName = (element) => {
    setProjectName(element.target.value);
  };

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    get_my_company({ token, setCompanyList });
  }, []);

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    get_users_from_company({ token, setUserList, selectedCompanyList });
  }, [selectedCompanyList]);

  return (
    <div className="createprojectmaindiv">
      <div className="input-group mb-2 inputdiv">
        <span className="input-group-text addprojectspan" >
          <img src={city} className="createprojectimg" />
        </span>
        <input
          type="text"
          className="form-control createprojectinput shadow-none "
          placeholder="Project Name"
          aria-label="Username"
          aria-describedby="basic-addon1"
          onChange={handleChangeProjectName}
        />
      </div>

      <Addproject data={companyList} selectedData={selectedSetCompanyList} image={companyimg} text={"Select Company"} />
      <Addproject data={userList} selectedData={selectedSetUserList}  image={name} text={"Select User"}  />
      {/* <Selectcompany/> */}
      <div
        className="createproject"
        onClick={() => {
          handle_create_project({
            selectedUserList,
            projectName,
            setProjectName,
            selectedSetCompanyList,
            selectedSetUserList,
            navigate,
            dispatch,
          });
        }}
      >
        Create Project
      </div>
      <div className="createprojectimagediv">
        <img src={createprojectimage} />
      </div>
    </div>
  );
}

export default Createproject;
