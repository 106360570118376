import React, { useState } from "react";
import "./Query.css";
import "./Queryoption.css";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import sequencelogo from "../../assest/Image/sequencelogo.png";
import { GoKebabHorizontal } from "react-icons/go";
import QueryOption from "./QueryOption";
import { AiFillBell } from "react-icons/ai";
import AddRing from "../../assest/Image/AddRing.png";
import Addnewquery from "./Addnewquery";
import { useDispatch, useSelector } from "react-redux";
import {
  select_query_of_sequence_actionCreater,
  set_query_status_actionCreater,
} from "../../Redux/selectQuery/CreateActions";
import { useLocation, useNavigate } from "react-router-dom";
import { select_chat_actionCreater } from "../../Redux/chatData/CreateActions";
import { handle_read_chat_data } from "../../Functions/projectsFunction";
import { get_all_projects_actionCreater } from "../../Redux/projects/CreateActions";

export default function Query({
  id,
  anchorEl,
  handleClose,
  open,
  Querydata,
  projectData,
  selectedSequence,
  setAnchorEl,
}) {
  const [subQuerypopup, setSubQuerypopup] = useState(null);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const oldUserData_id = useSelector((state) => {
    return state.profileData._id;
  });
  let route = location?.pathname.split("/")[2];
  const handleclick = (event, data) => {
    event.preventDefault();
    setSubQuerypopup(event.currentTarget);
    setSelectedQuery(data);
    if (data) select_query_of_sequence_actionCreater({ data, dispatch });
  };

  const handleSelectQuery = ({ data }) => {
    if (data) select_query_of_sequence_actionCreater({ data, dispatch });
    console.log({ query: data });
    set_query_status_actionCreater({
      dispatch,
      status: true,
    });
    select_chat_actionCreater({
      dispatch,
      data,
    });
    get_all_projects_actionCreater({ oldUserData_id,dispatch, router: route });
    // read all message of Chat
    handle_read_chat_data({
      data,
      dispatch,
    });
    setSelectedQuery(data);
    navigate(`/home/${route}/${data.project}/${data._id}`);
    handleClose();
  };

  //  create new query modal
  const [addnewquerymodal, setAddnewquerymodal] = useState(false);
  const CreateNewQuery = () => {
    setAddnewquerymodal(true);
  };

  // create new query modal ewnd here

  const SubQueryopen = Boolean(subQuerypopup);
  const SubQueryid = SubQueryopen ? "simple-popover" : undefined;
  return (
    <Popover
      id={id}
      // style={{ boxShadow: "none" }}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      sx={{
        borderColor: "#194895",
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      className="querypopoverupdate"
    >
      <Typography
        component={"div"}
        className="PoPup1Sub"
        sx={{
          padding: ".7rem",
          background: "#194895",
          borderRadius: "20px",
        }}
      >
        <div className="queryheading">
          <img src={sequencelogo} style={{ height: "18px" }} />
          <span style={{ color: "white" }}>Query List</span>
        </div>
        <div className="querylistofdata">
          <ul>
            {Querydata?.map((data, index) => {
              if (data.queryDeleted) {
                return (
                  <div className="queryinnerdeletelist">
                    <div className="querychatreddotsdeleted"></div>
                    <div className="querydataname">
                      {data.serialNumber} (
                      <span
                        style={{
                          color: "red",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: " 12px",
                          lineHeight: "15px",
                        }}
                      >
                        Deleted
                      </span>
                      ) by {data.deletedBy.name}
                      {"/"}
                      {data.updatedAt}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="queryinnerlist">
                    <div className="querychatreddots"></div>
                    <div
                      className="querydataname"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSelectQuery({ data });
                      }}
                    >
                      {data.serialNumber} {data.queryTitle}{" "}
                    </div>
                    {data?.isNewRecord ? (
                      ""
                    ) : data?.readBy?.includes(oldUserData_id) ? (
                      ""
                    ) : (
                      <AiFillBell className="querybell" />
                    )}

                    <div
                      className="querythreedots"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleclick(e, data)}
                    >
                      <GoKebabHorizontal style={{ height: "3vh" }} />
                    </div>
                  </div>
                );
              }
            })}
          </ul>
          <div className="addquerybtn" onClick={CreateNewQuery}>
            <img className="addqueryimage" src={AddRing} />
            Add New Query
          </div>
        </div>
      </Typography>
      {addnewquerymodal && selectedSequence ? (
        <Addnewquery
          addnewquerymodal={addnewquerymodal}
          setAddnewquerymodal={setAddnewquerymodal}
          projectData={projectData}
          selectedSequence={selectedSequence}
          setAnchorEl={setAnchorEl}
        />
      ) : null}
      {SubQueryopen && selectedQuery ? (
        <QueryOption
          subQuerypopup={subQuerypopup}
          SubQueryopen={SubQueryopen}
          SubQueryid={SubQueryid}
          setSubQuerypopup={setSubQuerypopup}
          selectedQuery={selectedQuery}
          setAnchorEl={setAnchorEl}
          selectedSequence={selectedSequence}
        />
      ) : null}
    </Popover>
  );
}
