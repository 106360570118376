import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./editsequence.css";
import "./addnewsequence.css";
import upload from "../../assest/Image/upload.png";
import { FiX } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { handle_add_sequence } from "../../Functions/projectsFunction";
import { useDispatch } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "50%",
  border: "none",
  outline: "none",
};

export default function Addnewsequence({ addnewsequence, setAddnewsequence }) {
  const [sequenceName, setSequenceName] = React.useState("");

  const { id } = useParams();
  const dispatch = useDispatch();

  const handleClose = () => setAddnewsequence(false);
  const HandleNewSequence = (event) => {
    if (event.keyCode === 13) {
      handle_add_sequence({
        dispatch,
        sequenceName,
        id,
        handleClose,
      });
    }
  };
  return (
    <div>
      <Modal
        open={addnewsequence}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="editprojectname">
            <div className="editprojectnameheader">
              <span>Add Sequence</span>
              <div className="editprojectcrosss" onClick={handleClose}>
                <FiX />
              </div>
            </div>

            <div className="newsequencebody">
              <div className="newsequencediv">
                <input
                  type="text"
                  placeholder="Enter Sequence Name"
                  className="sequencenameinput"
                  onKeyDown={(e) => HandleNewSequence(e)}
                  onChange={(event) => {
                    setSequenceName(event.target.value);
                  }}
                />
                {/* <div className='uploadtext'>Upload text</div>

<input
      multiline
      rows="30"
      type="text"
      className='addnewsequenceinput'
    />
<div className='uploaddocument'><img  src={upload}style={{height:"2vh",marginRight:"1%"}}/>Upload Documents</div> */}

                <div
                  className="newsequencebtn"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handle_add_sequence({
                      dispatch,
                      sequenceName,
                      id,
                      handleClose,
                    });
                  }}
                >
                  save
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
