import React,{useState} from 'react'
import './selectedtype.css'
import { FiX } from "react-icons/fi";
import createprojectimage from '../../../assest/Image/createprojectimage.png'
import Selectedpdf from '../../../assest/Image/Selectedpdf.png'
function SelectedtypeImage({setShowImageandPdf ,SelectedDataofImageandPdf,IsImageSelected,Image,setImage}) {

const handleImageandpdf = () =>{
 
  setImage(null)
  setShowImageandPdf(false)
  // window.location.reload();
}
console.log(Image,'image');
  return (
    <div className={IsImageSelected === true ? 'selectedImage':"selectedPdf"}>
    <div className={IsImageSelected === true ? 'selectedImagediv':'selectedpdfdiv'}> <img src={IsImageSelected=== true ? Image :Selectedpdf} />
    <div className='imagename'>{SelectedDataofImageandPdf.name}</div>
    </div>
     <FiX style={{color:"gray"}}
     onClick={handleImageandpdf}
     />
    </div>
  )
}

export default SelectedtypeImage