import React,{useRef} from "react";
import email from "../../assest/Image/email.png";
import password from "../../assest/Image/password.png";
import BuildB from "../../assest/Image/BuildB.png";
import backgroundbuilding from "../../assest/Image/backgroundbuilding.png";
import "./weblogin.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import {loginSignupselect} from '../../Functions/LoginSingupfun.js'
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { show_toaster_actionCreater } from "../../Redux/toasers/CreateActions";
import { FiX } from "react-icons/fi";

function DetectOutsideClick(ref, setLoginstatus) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setLoginstatus(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function WebLogin({selectedbtn , setSelectedbtn , Show,setShow,loginstatus,setLoginstatus}) {
  const contianer = useRef();
  DetectOutsideClick(contianer, setLoginstatus);
  const navigate = useNavigate();


  const { values, onSubmit, touched, errors, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: Yup.object({
        email: Yup.string().email().required("Please Enter Your Email"),

        password: Yup.string()
          .min(2)
          .max(30)
          .required("Please Enter Your Password"),
      }),
      onSubmit: async (values, action) => {
        const response = await handleloginclick(values);
        if (response)
          setTimeout(() => {
            action.resetForm();
            // toggle(false)
          }, 1000);
      },
    });

  useEffect(() => {
    let token = window.localStorage.getItem("token");
    if (token) {
      navigate("/home");
    }
  }, []);

  const handleloginclick = async () => {
    try {
      const response = await axios.post(
        `${process.env.React_App_prod_url}/user/login`,
        {
          email: values.email,
          password: values.password,
        }
      );

      if (response.data) {
        window.localStorage.setItem("token", response.data.user.jwtToken);
        if(response.data.user.projects.length>0){
          console.log("project Available");
          navigate("/home/project");
        }
        else{
          navigate("/home");
        }
     
      }
      show_toaster_actionCreater({
        message: "login successfully",
        status: "success",
      });
      return true;
    } 
    catch (err) {
      if (err?.response?.data?.err) {
        show_toaster_actionCreater({
          message: err?.response?.data?.err,
          status: "error",
        });
      }
      return false;
    }
  };
 
  const LoginEnter= async(event)=> {
  if (event.keyCode === 13) {
    try {
      const response = await axios.post(
        `${process.env.React_App_prod_url}/user/login`,
        {
          email: values.email,
          password: values.password,
        }
      );

      if (response.data) {
        window.localStorage.setItem("token", response.data.user.jwtToken);
        if(response.data.user.projects.length>0){
          console.log("project Available");
          navigate("/home/project");
        }
        else{
          navigate("/home");
        }
      }
      show_toaster_actionCreater({
        message: "login successfully",
        status: "success",
      });
      return true;
    } 
    catch (err) {
      if (err?.response?.data?.err) {
        show_toaster_actionCreater({
          message: err?.response?.data?.err,
          status: "error",
        });
      }
      return false;
    }
  }
}
  
    useEffect(() => {
        loginstatus === true ? contianer.current.style.transform = "translate(0)"
        // contianer.current.style.transform
         :
         contianer.current.style.transform = "translate(1000px)";
        //  contianer.current.style.transform = "none";
    }, [loginstatus])



   

    return (
        <div
            ref={contianer}
     className="weblogindiv">
        <FiX  className="closesignupbtn" onClick={() => 
        setLoginstatus(false)
            // {  loginSignupselect({ tab: "", setSelectedbtn ,setShow}) }
            }/>
      <div className="webloginsmalldiv">
        <img src={BuildB} className="webloginimage" />
        <h3 className="webloginspan">Log In to Username </h3>
        <form className="webloginform">
          {/* <div className='animatedloginform'> */}

          <div className="input-group mb-2 nameinputdiv shadow-none ">
            <span
              className="input-group-text webloginemailspan"
              id="basic-addon1"
              htmlFor="email"
            >
              <img
                src={email}
                className="nameimage"
                style={{ height: "2vh" }}
              />
            </span>
            <input
              type="email"
     
              className="form-control webnameinput shadow-none"
              onChange={handleChange}
              value={values.email}
              placeholder="Email"
              aria-label="Username"
              name="email"
              aria-describedby="basic-addon1"
            />
          </div>
          {touched.email&&errors.email ? <p className="Errors">{errors.email}</p> : null}
          <div className="input-group mb-2 nameinputdiv shadow-none ">
            <span
              className="input-group-text webloginemailspan"
              id="basic-addon1"
              htmlFor="password"
            >
              <img
                src={password}
                className="nameimage"
                style={{ height: "2vh" }}
              />
            </span>
            <input
              type="password"
              className="form-control webnameinput shadow-none"
              onChange={handleChange}
              value={values.password}
              placeholder="password"
              aria-label="Username"
              name="password"
              onKeyDown={(e) => LoginEnter(e) }
              aria-describedby="basic-addon1"
            />
          </div>
          {touched.password &&errors.password ? <p className="Errors">{errors.password}</p> : null}
          <div
            className="webforgetpassword"
            onClick={() => navigate("/forgetpassword")}
          >
            <span>Forget Your Password?</span>
          </div>
          <div 
          className="buldingbackground"
           style={{
          backgroundImage: `url(${backgroundbuilding})`,
          backgroundSize: "cover",
        }}
        >
          <button
            type="button"
            className="webloginbtn"
            onClick={handleSubmit}
          >
            <span style={{ color: "white" }}>Log In</span>
          </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default WebLogin;

