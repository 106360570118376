import React, { useEffect, useState } from "react";
import "./Project.css";
import Accordian from "./Accordian";
import Chatside from "../Chatside/Chatside";
import Groupchat from "../Chatside/Groupchat";
import { useDispatch, useSelector } from "react-redux";
import { get_all_projects_actionCreater } from "../../Redux/projects/CreateActions";
import { handle_Change_Tab } from "../../Functions/projectsFunction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Projectdescription from "./Projectdescription";
import { get_single_user_list_actioncreator } from "../../Redux/profile/CreateActions";
import { change_base_router_actionCreater } from "../../Redux/routers/CreateActions";
import Chatsideproject from "../Chatside/Chatsideproject";
import Slidescreen3 from "../Slidescreen/Slidescreen3";
import {
  select_query_of_actionCreater,
  select_query_of_sequence_actionCreater,
} from "../../Redux/selectQuery/CreateActions";
import { select_chat_actionCreater } from "../../Redux/chatData/CreateActions";
export default function Project() {
  const [selectedBtn, setSelectedBtn] = useState("all");
  const [projectData, setProjectData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id, query_id } = useParams();
  const [forUpdate, setForUpdate] = useState(0);
  const projectList = useSelector((state) => {
    return state.projects;
  });
  const oldUserData_id = useSelector((state) => {
    return state && state.profileData
      ? state?.profileData?._id
      : state?.profileData;
  });
  const selectedQuery = useSelector((state) => {
    return state.query;
  });

  useEffect(() => {
    get_single_user_list_actioncreator({ dispatch });
  }, [0]);

  useEffect(() => {
    if (query_id != undefined)
      if (!selectedQuery?.query?._id) {
        // console.log("in selection", { query: query_id });
        select_query_of_actionCreater({
          query: query_id,
          dispatch,
        });
      }
  }, [query_id]);

  // useEffect(() => {
  //   if (selectedQuery) {
  //     select_chat_actionCreater({
  //       dispatch,
  //       data: selectedQuery,
  //     });
  //   }
  // }, [selectedQuery]);

  useEffect(() => {
    if (location) {
      let route = location?.pathname.split("/")[2];
      change_base_router_actionCreater({ dispatch, router: route });
      get_all_projects_actionCreater({
        oldUserData_id,
        dispatch,
        router: route,
      });
    }
  }, [location]);

  useEffect(() => {
    if (selectedBtn == "all") {
      setProjectData(projectList.allProjects);
    } else if (selectedBtn == "share") {
      setProjectData(projectList.shareWithMe);
    } else {
      setProjectData(projectList.myProjects);
    }
    setForUpdate(forUpdate + 1);
  }, [projectList]);

  // console.log({ projectData },"project Data");

  return (
    <>
      <div className="Allproject">
        <div className="projectbtns">
          <div
            style={{
              cursor: "pointer",
            }}
            className={
              selectedBtn === "all" ? "allproject" : "allprojectunselected"
            }
            onClick={() => {
              handle_Change_Tab({
                tab: "all",
                setSelectedBtn,
                projectList,
                setProjectData,
                navigate,
                route: location?.pathname.split("/")[2],
              });
            }}
          >
            All Project
          </div>
          <div
            style={{
              cursor: "pointer",
            }}
            className={
              selectedBtn === "share"
                ? "sharedwithme"
                : "sharedwithmeunselected"
            }
            onClick={() => {
              handle_Change_Tab({
                tab: "share",
                setSelectedBtn,
                projectList,
                setProjectData,
                navigate,
                route: location?.pathname.split("/")[2],
              });
            }}
          >
            Shared with Me{" "}
          </div>
          <div
            style={{
              cursor: "pointer",
            }}
            className={
              selectedBtn === "my" ? "myproject" : "myprojectunselected"
            }
            onClick={() => {
              handle_Change_Tab({
                tab: "my",
                setSelectedBtn,
                projectList,
                setProjectData,
                navigate,
                route: location?.pathname.split("/")[2],
              });
            }}
          >
            My Project
          </div>
        </div>
        <div className="Allprojectlist">
          {projectData?.map((ele, idx) => {
            return (
              <Accordian
                idx={idx}
                forUpdate={forUpdate}
                projectData={ele}
                selectedBtn={selectedBtn}
              />
            );
          })}
        </div>
      </div>
      {/* some changes */}

      {id == undefined ? (
        // <Chatside />
        <Chatsideproject />
      ) : // <Slidescreen3/>
      query_id == undefined ? (
        <Projectdescription />
      ) : (
        <Groupchat />
      )}
    </>
  );
}
