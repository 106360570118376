import * as React from "react";
import Box from "@mui/material/Box";
import { FiX } from "react-icons/fi";
import Modal from "@mui/material/Modal";
import { handle_delete_user_from_project } from "../../Functions/projectsFunction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const style = {
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  height: "50%",
  border: "none",
  outline: "none",
};

export default function DeleteUserFromProject(props) {
  const {
    open,
    handleCloseDeleteUser,
    setProjectoption,
    projectData,
    userForDelete,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myCurrentRouter = useSelector((state) => {
    return state.router;
  });
  const oldUserData_id = useSelector((state) => {
    return state.profileData._id;
  });

  const router = useSelector((state) => {
    return state.router;
  });

  return (
        <div>
      <Modal
        open={open}
        onClose={handleCloseDeleteUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className="inviteusermaindiv">
            <div className="inviteuserheader">
              <span>Remove User</span>
              <div
                className="inviteusercrosss"
                style={{
                  cursor: "pointer",
                }}
                onClick={handleCloseDeleteUser}
              >
                <FiX />
              </div>
            </div>
          <div
            className="removefilediv"
     
          >

            <div className="removefilespan">
              Are you sure you want to remove this User permanently ?
            </div>
            <div className="removefilebtn">
              <div className="filedeletecancle" onClick={handleCloseDeleteUser}>
                cancel
              </div>
              <div
                className="filedeleteconfrom"
                onClick={() => {
                              handle_delete_user_from_project({
                                oldUserData_id,
                                user: userForDelete,
                                projectData,
                                handleCloseDeleteUser,
                                setProjectoption,
                                dispatch,
                                navigate,
                                router,
                                myCurrentRouter,
                              });
                            }}
              >
                Confirm
              </div>
            </div>
          </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
