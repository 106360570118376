import React from "react";
import { MdOutlineReplyAll } from "react-icons/md";
import "../sendmessage.css";
import pdf from "../../../../assest/Image/pdf.png";
import download from "../../../../assest/Image/download.png";
function PdfWithSpan({ setChatreply, message,setreplymsg }) {
  const pdfname = "AjayTesting The pdf with the string";

  // console.log(
  //   message.messageData.doc.split("/")[4]?.substring(13),
  //   "message with doc"
  // );

  return (
    <div className="pdfchatselfreplymaindiv">
      <div className="selfchatreplydiv" onClick={() => setChatreply(true)}>
        <MdOutlineReplyAll
          onClick={() => {
            setreplymsg({
              name: message.sendFrom.name,
              profession: message.sendFrom.profession,
              color: null,
              message: message.messageData.description,
              message_id: message._id,
            });
          }}
        />
      </div>
      <div className="sendreplyofamessagemaindiv">
        {/* simple pdf send not reply */}
        <div className="selfreplytopdf">
          <img src={pdf} className="pdflogo" />
          <div className="replypdfname">
            {message.messageData.doc.split("/")[4]?.substring(13)}...
          </div>
          <img
            style={{
              cursor: "pointer",
            }}
            // target="_blank"
            // href={message.messageData.doc}
            onClick={() => {
              // window.location.href = message.messageData.doc;
              window.open(message.messageData.doc, "_blank");
            }}
            src={download}
            className="replydownladimage"
          />
        </div>

        {/* if pdf is a reply of a message then we can call him  */}
        {/* <div className="selfreplytopdf">
                    <img src={pdf} className="pdfreplylogo" />
                    <div>
                        <div className='replytopdfusername'>Reply to Sam (Fabricator)</div>
                        <div className='replytopdfname'>Shalini jha Docs File Reply</div>
                    </div>
                </div> */}

        <div style={{ padding: "3%", fontSize: "13px" }}>
          {message?.messageData?.description}
        </div>
      </div>
    </div>
  );
}

export default PdfWithSpan;
