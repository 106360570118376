import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import plus from "../../assest/Image/plus.png";
import project from "../../assest/Image/project.png";
import storageicon from "../../assest/Image/storageicon.png";
import closeproject from "../../assest/Image/closeproject.png";
import openproject from "../../assest/Image/openproject.png";
import mainlogo from "../../assest/Image/mainlogo.png";
import slidebar from "../../assest/Image/slidebar.png";
import add from "../../assest/Image/add.png";
import "./sidebar.css";
import Header from "../Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardRouting from "../Home/DashboardRouting";
import InviteUser from "../Header/InviteUser";
import { change_base_router_actionCreater } from "../../Redux/routers/CreateActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { get_single_user_list_actioncreator } from "../../Redux/profile/CreateActions";
const drawerWidth = 200;
const margin = 60;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: `calc(100% - ${margin}px)`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const [openModal, setOpenmodal] = React.useState(false);
  const location = useLocation();

  const [isEmailVerify, SetIsEmailVerify] = React.useState(true);
  // modal open
  const handleOpen = () => {
    setOpenmodal(true);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = [
    {
      name: "Project",
      image: project,
      key: "project",
    },
    {
      name: "Live Project",
      image: closeproject,
      key: "live_project",
    },
    {
      name: "Close Project",
      image: openproject,
      key: "closeproject",
    },
    {
      name: "Create New Project",
      image: plus,
      key: "add",
    },
    {
      name: "Storage",
      image: storageicon,
      key: "storage",
    },
  ];

  const theme = useTheme();
  const [active, setActive] = React.useState(null);
  const [open, setOpen] = React.useState(true);
  const myCurrentRouter = useSelector((state) => {
    return state.router;
  });

  useEffect(() => {
    get_single_user_list_actioncreator({ dispatch });
  }, [0]);

  const user_email_verification = useSelector((state) => {
    return state?.profileData?.emailVerified;
  });
  useEffect(() => {
    if (user_email_verification == true) SetIsEmailVerify(true);
    else if (user_email_verification == false) SetIsEmailVerify(false);
  }, [user_email_verification]);

  useEffect(() => {
    if (!myCurrentRouter) {
      change_base_router_actionCreater({
        dispatch,
        router: location?.pathname.split("/")[2],
      });
    } else setActive(myCurrentRouter);
  }, [myCurrentRouter]);

  const handleclick = (idx, key) => {
    setActive(key);
    if (idx === 0) {
      change_base_router_actionCreater({ dispatch, router: "project" });
      navigate("/home/project");
    } else if (idx === 1) {
      change_base_router_actionCreater({ dispatch, router: "live_project" });
      navigate("/home/live_project");
    } else if (idx === 3) {
      change_base_router_actionCreater({ dispatch, router: "add" });
      navigate("/home/add");
    } else if (idx === 2) {
      change_base_router_actionCreater({ dispatch, router: "closeproject" });
      navigate("/home/closeproject");
    } else if (idx === 4) {
      change_base_router_actionCreater({ dispatch, router: "storage" });
      navigate("/home/storage");
    } else {
      change_base_router_actionCreater({ dispatch, router: "editprofile" });
      navigate("/home/editprofile");
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {isEmailVerify === true ? (
        ""
      ) : (
        <div className="isverifyemail">
          Account Verification : Your Email is not yet verified! To continue
          please verify by clicking on the link sent on your Email.
        </div>
      )}
      <AppBar
        position="fixed"
        open={open}
        sx={{ background: "none", boxShadow: "none" }}
      >
        <Header isEmailVerify={isEmailVerify} />
        <div
          style={{
            display: "flex",
            position: "absolute",
            top: "95%",
            width: "100%",
          }}
        >
          <DashboardRouting />
        </div>
      </AppBar>
      <Drawer variant="permanent" open={open} className="sidebardrawer">
        {open === true ? (
          <div
            className={
              isEmailVerify === true
                ? "sidebarlogodiv"
                : "isverifyemailsidebarlogodiv"
            }
          >
            <button className="sidebarlogobtn">
              <img className="sidebarbtnlogo" src={mainlogo} />
            </button>
            <span className="QMSspan">BUILD INTRA</span>
            <button className="sidebarlogomenu" onClick={handleDrawerClose}>
              <img src={slidebar} className="sidebarbtnmenu" />
            </button>
          </div>
        ) : (
          <div
            className={
              isEmailVerify === true
                ? "sidebarlogodivclose"
                : "isverifyemailsidebarlogodivclose"
            }
          >
            <button
              className="sidebarlogomenuclose"
              onClick={handleDrawerClose}
            >
              <img src={slidebar} className="sidebarbtnmenu" />
            </button>
          </div>
        )}
        {open === true ? (
          <List sx={{ marginTop: "5vh", padding: "0%", marginTop: "10%" }}>
            {data.map((i, idx) => (
              <li
                className="list"
                key={idx}
                onClick={() => handleclick(idx, i.key)}
              >
                <div
                  className={
                    active == i.key
                      ? "sidebaroptionimagediv"
                      : "sidebaroptionimagediv1"
                  }
                >
                  <img
                    className={
                      active == i.key
                        ? "sidebaroptionimage"
                        : "sidebaroptionimage1"
                    }
                    src={i.image}
                  />
                </div>
                <div
                  className={
                    active == i.key
                      ? "sidebaroptionbtndiv"
                      : "sidebaroptionbtndiv1"
                  }
                >
                  <span
                    className={
                      active == i.key
                        ? "sidebaroptionspandiv"
                        : "sidebaroptionspandiv1"
                    }
                  >
                    {i.name}
                  </span>
                </div>
              </li>
            ))}
          </List>
        ) : (
          <List sx={{ marginTop: "100%" }}>
            {data.map((i, idx) => (
              <li className="listclose" key={idx}>
                <div
                  className="sidebaroptionimagedivclose"
                  onClick={() => handleclick(idx, i.key)}
                >
                  <img className="sidebaroptionimage" src={i.image} />
                </div>
              </li>
            ))}
          </List>
        )}
        {open === true ? (
          <div className="sidebaradduserbtn" onClick={handleOpen}>
            <span>+ Invite User</span>
          </div>
        ) : (
          <div className="sidebaradduserbtnclose" onClick={handleOpen}>
            <img src={add} />
          </div>
        )}
      </Drawer>
      <InviteUser openModal={openModal} setOpenmodal={setOpenmodal} />
    </Box>
  );
}
