import React from "react";
import "../sendmessage.css";
import { MdOutlineReplyAll } from "react-icons/md";
function ChatSimpleMessageSend({ setChatreply, message, setreplymsg ,isSameUser}) {
  console.log(message);
  return (
    <div className="selfmsgandreplydiv">
      <div className="selfreplydiv" onClick={() => setChatreply(true)}>
        <MdOutlineReplyAll
          onClick={() => {
            setreplymsg({
              name: message.sendFrom.name,
              profession: message.sendFrom.profession,
              color: null,
              message: message.messageData.description,
              message_id: message._id,
            });
          }}
        />
      </div>
      <div
        className="sendmessagediv"
        //   style={{ background: `${color}` }}
      >
        {message?.messageData?.description}
      </div>
    </div>
  );
}

export default ChatSimpleMessageSend;
