import React from 'react'
import './chatside.css'
import projectchat from '../../assest/Image/projectchat.png'

import whiteuserpic from '../../assest/Image/whiteuserpic.png'
import pdf from '../../assest/Image/pdf.png'
function Chatside() {
  return (
    <div className='chatsidescreen'>
        <div className='chatsidescreendiv'> <img src={projectchat}/></div>

    <div className='queryspan'> Hi! Your chat will be display here 
        When you select any Query in Sequence
    </div>
    </div>
   
  )
}

export default Chatside