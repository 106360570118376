import React, { useEffect, useRef, useState } from "react";
import "./addProject.css";
import calling from "../../assest/Image/calling.png";
import companyimg from "../../assest/Image/companyimg.png";
import { Multiselect } from "multiselect-react-dropdown";

function Addproject({ data, selectedData,image,text }) {
  const [Show, setShow] = useState(false)
  const selectedRef = useRef();
  const onSelect = async(item) => {
  
    selectedData([...item]);

  };

  const handlekeypress = (data) => {
  };

  const onRemove = (item) => {
    selectedData([...item]);
  };
  const style = {
  
    multiselectContainer: {
      height: "8vh",
      background: "#F0F0F0",
    },

    searchBox: {
      // To change search box element look
      border: "none",
      placeholder: "Add more",
      width: "60vw",
      fontSize: "15px",
      minHeight: "50px",
    },
    chips: {
      // To change css chips(Selected options)
      background: '#194895',
    },
    // option: {
    //   color: "black",
    // },
   
    inputField: {
      // To change input field position or margin
      margin: "5px",
      marginTop: "1.5vh",
    },

  };

  useEffect(()=>{
  },[0])

  return (
    <div className="addprojectdropdown">
      <img src={image} />
      {
        // !Show &&
        <Multiselect
        // closeOnSelect={true}
          // isObject={true}
          ref={selectedRef}
          options={data}
          displayValue="data"
          emptyRecordMsg={"No More Data Available"}
          style={style}
          // closeOnSelect={true}
          onKeyPressFn={(event) => handlekeypress(event)}
          avoidHighlightFirstOption={true}
          caseSensitiveSearch={false}
          placeholder={text}
          onRemove={onRemove}
          onSelect={onSelect}
          // singleSelect
        />
      }
      {/* <BsChevronDown/> */}
    </div>
  );
}

export default Addproject;
