import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import "./Inviteuser.css";
import "./adduser.css";
import { FiX } from "react-icons/fi";
import { useState } from "react";
import Adduser from "./Adduser";
import companyimg from "../../assest/Image/companyimg.png";
import name from "../../assest/Image/name.png";
import {
  get_my_company,
  get_users_from_company,
  handle_add_user_in_project,
} from "../../Functions/projectsFunction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "50%",
  border: "none",
  outline: "none",
};
export default function Adduserpopup({
  showadduser,
  setshowadduser,
  projectDetails,
  setProjectoption,
  place,
}) {
  const [selectedCompanyList, selectedSetCompanyList] = useState([]); // seceted Company
  const [selectedUserList, selectedSetUserList] = useState([]); // selected  Users From Company
  const [companyList, setCompanyList] = useState([]); // all company List of Admin User
  const [userList, setUserList] = useState([]); // all Users From Selected Company
  const oldUserData_id = useSelector((state) => {
    return state.profileData._id;
  });
  const handleClose = () => {
    setshowadduser(false);
    if (setProjectoption) setProjectoption(false);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    get_my_company({ token, setCompanyList });
  }, []);

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    get_users_from_company({ token, setUserList, selectedCompanyList });
  }, [selectedCompanyList]);

  const router = useSelector((state) => {
    return state.router;
  });

  return (
    <div>
      <Modal
        open={showadduser}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="inviteusermaindiv">
            <div className="inviteuserheader">
              <span>Add User</span>
              <div className="inviteusercrosss" onClick={handleClose}>
                <FiX />
              </div>
            </div>

            <div className="adduserprojectbody">
              <input
                type="email"
                placeholder="Project Name"
                className="adduserprojectnameinput"
                value={projectDetails.name}
                disabled
              />
              <Adduser
                data={companyList}
                selectedData={selectedSetCompanyList}
                text={"Select Company"}
                image={companyimg}
              />
              <Adduser
                data={userList}
                selectedData={selectedSetUserList}
                text={"Select User"}
                image={name}
              />

              <div
                onClick={() => {
                  handle_add_user_in_project({
                    oldUserData_id,
                    selectedUserList,
                    selectedSetCompanyList,
                    selectedSetUserList,
                    dispatch,
                    project: projectDetails.id,
                    handleClose,
                    router,
                    place,
                  });
                }}
                className="modalsavebtn"
              >
                <span>save</span>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
