import React,{useState,useEffect} from 'react'
import './Forgetpassword.css'
import Logo from '../../assest/Image/Logo.png'
import Building from '../../assest/Image/Building.png'
import question from '../../assest/Image/question.png'
import email from '../../assest/Image/email.png'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import blueLogo from "../../assest/Image/blueLogo.png";
import BuildB from "../../assest/Image/BuildB.png";
//  from "../../assest/Image/blueLogo.png";
function Forgetpassword() {
  const navigate = useNavigate();
  const [emailerror,setEmailerror] = useState('')
  const [inputemail,setInputemail] = useState('')
  useEffect(() => {
    setTimeout( ()=> {
      setEmailerror(false);
    }, 5000);
  }, [emailerror]);
  const handleinputemail = (event) =>{
    setInputemail(event.target.value)
  }
  const SendOtp = async() => {

   try{

    const response = await axios.post(`${process.env.React_App_prod_url}/user/generateOtp`,{
      email:inputemail
    })
    if(response.status === 200){
      navigate('/verifyotp', {
        state: {
           email:inputemail
        },
    })
    }
    // 
   }
   catch(err){
    setEmailerror(err.response.data.err)
    console.log(err,'err');
   }


  
  }

  return (
    <>
      <div className="row p-0 m-0">
        <div className="col-md-7 col-12 p-0">
          <div className='Logindivforget'>
            <div className='smalldivforget'>
              <img src={BuildB} className='imageforget' />
              <h3 className='h2designforget'>Forget Your Password </h3>
              <div className='otpmsgforget'>We will send you an OTP on your Email ID </div>

              <div className="input-group mb-2 passwordemailforget">
                <span className="input-group-text forgetpasswordspan " id="basic-addon1" htmlFor='email'><img src={email} className='forgetpasswordimage' /></span>
                <input type="email" className="form-control forgetpasswordinput shadow-none" onChange={(event)=>handleinputemail(event)}  placeholder="Email" aria-label="Username" name='email' aria-describedby="basic-addon1" />
              </div>
              {
                emailerror? <div className='emailerror'>{emailerror}</div>:null
              }
              <button className='loginbtnforget' onClick={SendOtp}><span style={{ color: 'white'}}>Send OTP</span></button>
            </div>
          </div>
        </div>
        <div className="col-md-5 col-12 p-0">
          <div className='Lineargradientdivforget'>
            <div className='lineargradientminidivforget'>
              <img src={question} className='questionimageforget' />
              <img src={Building} className='buildingforget' />
            </div>
          </div>

        </div>
      </div>

    </>


  )
}

export default Forgetpassword