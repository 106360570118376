import React from "react";
import { MdOutlineReplyAll } from "react-icons/md";
import createprojectimage from "../../../../assest/Image/createprojectimage.png";
import imagelogo from "../../../../assest/Image/imagelogo.png";
function ImageReply({ setChatreply, message, setreplymsg, type }) {
  const replytext = "Yes This Is The Reply of Image with Text";
  return (
    <div className="recievedmesssagepdfreply">
      <div className="replyofamessagemaindiv" style={{ background: "red" }}>
        <div className="replytoimage">
          <img
            src={message?.replyOf?.messageData?.doc}
            className="replytorealimage"
          />

          <div className="replytoimageusernameandlogo">
            <span>
              {" "}
              {message?.replyOf?.sendFrom.name} (
              {message?.replyOf?.sendFrom.profession})
            </span>
            {/* condition  Reply of a Simple Image */}

            {type == "imageWithMessage" ? (
              <div className="imagewithtextreplyspan">
                {message?.replyOf?.messageData?.description.substring(0, 16)}...
              </div>
            ) : (
              <img src={imagelogo} className="replytologo" />
            )}

            {/* or  replying of Image with Text  */}
          </div>
        </div>
        <div style={{ padding: "3%", fontSize: "13px" }}>
          {message?.messageData?.description}
        </div>
      </div>
      <div className="chatreplydiv" onClick={() => setChatreply(true)}>
        <MdOutlineReplyAll />
      </div>
    </div>
  );
}

export default ImageReply;
