import React from "react";
import "./useraccesspopover.css";
import { IoIosArrowDown } from "react-icons/io";
import { Popover } from "@mui/material";
import { useState } from "react";
import admin from "../../assest/Image/admin.png";
import dco from "../../assest/Image/dco.png";
import userimg from "../../assest/Image/userimg.png";
import nestedsearch from "../../assest/Image/nestedsearch.png";
import {
  handle_click_on_check,
  handle_click_on_save_to_updateUserAccess,
} from "../../Functions/profileFunctions";
import { useDispatch } from "react-redux";
function UserAccesspopover(props) {
  const {
    isAccessopen,
    openaccessid,
    OpenAccess,
    setOpneAccess,
    project_detail,
    handle_selectedData,
    set_handle_selectedData,
    selected_user,
  } = props;

  const [admindrop, setAdmindrop] = useState(false);
  const [poc, setPoc] = useState(false);
  const [user, setUser] = useState(false);

  const dispatch = useDispatch();
  const handleClose = () => {
    setOpneAccess(null);
  };

  const handleClick = (data) => {
    if (data === "user") {
      setUser(!user);
      setAdmindrop(false);
      setPoc(false);
    } else if (data === "admin") {
      setAdmindrop(!admindrop);
      setUser(false);
      setPoc(false);
    } else {
      setPoc(!poc);
      setAdmindrop(false);
      setUser(false);
    }
  };

  return (
    <div>
      {/* <Popover
        id={openaccessid}
        open={openaccessid}
        anchorEl={OpenAccess}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      > */}
      <Popover
        id={openaccessid}
        style={{ boxShadow: "none" }}
        open={openaccessid}
        anchorEl={OpenAccess}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="popovermaindiv">
          <div className="popoveralldataofuseraccess">
            <div className="admindropdowndiv">
              <div className="admindiv">
                <div className="admindivimageandnamediv">
                  <div className="admindivimage">
                    <img src={admin} />
                  </div>
                  <span>Admin</span>
                </div>
                <IoIosArrowDown
                  className="innerdataoftable"
                  onClick={() => handleClick("admin")}
                />
              </div>
              {admindrop === true ? (
                <div className="admindropdownmaindiv">
                  <div className="adminsearchproject">
                    <img src={nestedsearch} />
                    <input
                      type="text"
                      placeholder="search project.."
                      className="admininput"
                    />
                  </div>
                  <ul className="adminul">
                    {project_detail
                      ? project_detail.map((ele, idx) => (
                          <div className="admindatadiv" key={idx}>
                            <li className="adminprojectlist">
                              {ele.projectName}
                            </li>{" "}
                            {ele.myRole == "admin" ? (
                              <input type="checkbox" checked={true} disabled />
                            ) : handle_selectedData.find(
                                (e) => e.project_id == ele.project_id
                              ).myRole == "admin" ? (
                              <input
                                type="checkbox"
                                checked={true}
                                onClick={() =>
                                  handle_click_on_check({
                                    handle_selectedData,
                                    project_detail,
                                    set_handle_selectedData,
                                    project_id: ele.project_id,
                                    role: "admin",
                                  })
                                }
                              />
                            ) : (
                              <input
                                type="checkbox"
                                onClick={() =>
                                  handle_click_on_check({
                                    handle_selectedData,
                                    project_detail,
                                    set_handle_selectedData,
                                    project_id: ele.project_id,
                                    role: "admin",
                                  })
                                }
                              />
                            )}
                          </div>
                        ))
                      : null}
                  </ul>
                </div>
              ) : null}
            </div>
            <div className="pocdropdowndiv">
              <div className="pocdiv">
                <div className="pocdivimageandnamediv">
                  <div className="pocdivimage">
                    <img src={dco} />
                  </div>
                  <span>DCO</span>
                </div>
                <IoIosArrowDown
                  className="innerdataoftable"
                  onClick={() => handleClick("dco")}
                />
              </div>
              {poc === true ? (
                <div className="admindropdownmaindiv">
                  <div className="adminsearchproject">
                    <img src={nestedsearch} />
                    <input
                      type="text"
                      placeholder="search project.."
                      className="admininput"
                    />
                  </div>
                  <ul className="adminul">
                    {project_detail
                      ? project_detail.map((ele, idx) => (
                          <div className="admindatadiv" key={idx}>
                            <li className="adminprojectlist">
                              {ele.projectName}
                            </li>{" "}
                            {ele.myRole == "dco" ? (
                              <input type="checkbox" checked={true} disabled />
                            ) : handle_selectedData.find(
                                (e) => e.project_id == ele.project_id
                              ).myRole == "dco" ? (
                              <input
                                type="checkbox"
                                checked={true}
                                onClick={() =>
                                  handle_click_on_check({
                                    handle_selectedData,
                                    project_detail,
                                    set_handle_selectedData,
                                    project_id: ele.project_id,
                                    role: "dco",
                                  })
                                }
                              />
                            ) : (
                              <input
                                type="checkbox"
                                onClick={() =>
                                  handle_click_on_check({
                                    handle_selectedData,
                                    project_detail,
                                    set_handle_selectedData,
                                    project_id: ele.project_id,
                                    role: "dco",
                                  })
                                }
                              />
                            )}
                          </div>
                        ))
                      : null}
                  </ul>
                </div>
              ) : null}
            </div>
            <div className="userdropdowndiv">
              <div className="userdiv">
                <div className="userdivimageandnamediv">
                  <div className="userdivimage">
                    <img src={userimg} />
                  </div>
                  <span>User</span>
                </div>
                <IoIosArrowDown
                  className="innerdataoftable"
                  onClick={() => handleClick("user")}
                />
              </div>
              {user === true ? (
                <div className="admindropdownmaindiv">
                  <div className="adminsearchproject">
                    <img src={nestedsearch} />
                    <input
                      type="text"
                      placeholder="search project.."
                      className="admininput"
                    />
                  </div>
                  <ul className="adminul">
                    {project_detail
                      ? project_detail.map((ele, idx) => (
                          <div className="admindatadiv" key={idx}>
                            <li className="adminprojectlist">
                              {ele.projectName}
                            </li>{" "}
                            {ele.myRole == "user" ? (
                              <input type="checkbox" checked={true} disabled />
                            ) : handle_selectedData.find(
                                (e) => e.project_id == ele.project_id
                              ).myRole == "user" ? (
                              <input
                                type="checkbox"
                                checked={true}
                                onClick={() =>
                                  handle_click_on_check({
                                    handle_selectedData,
                                    project_detail,
                                    set_handle_selectedData,
                                    project_id: ele.project_id,
                                    role: "user",
                                  })
                                }
                              />
                            ) : (
                              <input
                                type="checkbox"
                                onClick={() =>
                                  handle_click_on_check({
                                    handle_selectedData,
                                    project_detail,
                                    set_handle_selectedData,
                                    project_id: ele.project_id,
                                    role: "user",
                                  })
                                }
                              />
                            )}
                          </div>
                        ))
                      : null}
                  </ul>
                </div>
              ) : null}
            </div>
            <button
              className="submitbtn"
              onClick={() => {
                handle_click_on_save_to_updateUserAccess({
                  handle_selectedData, // NEW SELECTED DATA
                  project_detail, /// default Data
                  selected_user, // selected user email
                  dispatch,
                  handleClose,
                });
              }}
            >
              <span>save</span>
            </button>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default UserAccesspopover;
