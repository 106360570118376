// import { useNavigate } from "react-router-dom"
export const logoutuser = ({ navigate }) => {
  // const navigate=useNavigate();
  localStorage.removeItem("token");
  if(navigate)   navigate("/");
  if (localStorage.getItem("token") === null) {
    return {
      isDeleted: "successfull",
    };
  } else {
    return {
      isDeleted: "failed",
    };
  }
};
