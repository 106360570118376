
import React,{useState} from "react";
import AnimatedLogin from "./AnimatedLogin";
import AnimatedSignup from "./AnimatedSignup";
import * as Componentstyle from "./componentstyle";
import Building from '../../assest/Image/Building.png'
import FinalLogo from '../../assest/Image/FinalLogo.png'
import whitelogo from "../../assest/Image/whitelogo.png";
import BuildW from "../../assest/Image/BuildW.png";
import './logonfile.css'
function MainAnimatedLoginandSignup() {
    const [signIn, toggle] = useState(true);
  return (
    <Componentstyle.Container>
    <Componentstyle.SignUpContainer signingIn={signIn}>
   <AnimatedSignup toggle={toggle} />
    </Componentstyle.SignUpContainer>
    <Componentstyle.SignInContainer signingIn={signIn}>
<AnimatedLogin toggle={toggle}/>
    </Componentstyle.SignInContainer>
    <Componentstyle.OverlayContainer signingIn={signIn}>
      <Componentstyle.Overlay signingIn={signIn}>
        <Componentstyle.LeftOverlayPanel signingIn={signIn}>
        <div className='animatedLineargradientdiv'>
          <div className='animatedlineargradientminidiv'>
            <div className='animatedspandiv'>
              <span className='animatedhellospan'>Hello, User!</span><br></br>
              <span className='animateddetailspan'>Enter  your  details  and  start </span><br></br>
              <span className='animatedjourneyspan'>journey with us </span>
            </div>
            <button className='animatedsignupbtn' onClick={() => toggle(true)}><span style={{ color: 'white', fontWeight: '700' }} >Log In</span></button>
       
            <img src={Building} className='animatedbuilding' />
          </div>
        </div>
        </Componentstyle.LeftOverlayPanel>
  
        <Componentstyle.RightOverlayPanel signingIn={signIn}>
        <div className='animatedLineargradientdiv'>
                  <div className='animatedlineargradientminidiv'>
                      <div className='animatedspandiv'>
                          <img src={BuildW}  style={{height:"20vh"}} />
                          <span className='animatedhellospan1'>Welcome Back!</span><br></br>
                          <span className='animateddetailspan1'>To keep connected with us please
                         login with your personal info</span>
                          <button  className='animatedloginbtn1' onClick={() => toggle(false)}><span >Sign Up</span></button>

                      </div>
                      <img src={Building} className='animatedbuilding' />
                  </div>
              </div>
      
        </Componentstyle.RightOverlayPanel>
      </Componentstyle.Overlay>
    </Componentstyle.OverlayContainer>
  </Componentstyle.Container>
  )
}

export default MainAnimatedLoginandSignup