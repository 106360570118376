import axios from "axios";
import { get_user_access_list_actionCreater } from "../Redux/profile/CreateActions";
import { logoutuser } from "./Logout";
import { show_toaster_actionCreater } from "../Redux/toasers/CreateActions";

export const handle_open_popover = (data) => {
  const {
    set_project_detail,
    value,
    set_handle_selectedData,
    set_selected_user,
  } = data;
  set_handle_selectedData(value.project_details);
  set_project_detail(value.project_details);
  set_selected_user(value.user_detail.email);
};

export const handle_click_on_check = (data) => {
  const {
    handle_selectedData,
    project_detail,
    set_handle_selectedData,
    project_id,
    role,
  } = data;

  let findSelectedProject = handle_selectedData.find(
    (e) => e.project_id == project_id
  );
  let find_selected_project_in_project_detail = project_detail.find(
    (e) => e.project_id == project_id
  );

  if (findSelectedProject.myRole == role) {
    set_handle_selectedData([
      ...handle_selectedData.filter((e) => e.project_id != project_id),
      {
        ...find_selected_project_in_project_detail,
        myRole: find_selected_project_in_project_detail.myRole,
      },
    ]);
  } else {
    {
      set_handle_selectedData([
        ...handle_selectedData.filter((e) => e.project_id != project_id),
        {
          ...find_selected_project_in_project_detail,
          myRole: role,
        },
      ]);
    }
  }
};

export const handle_click_on_save_to_updateUserAccess = async (data) => {
  const {
    handle_selectedData,
    project_detail,
    selected_user,
    dispatch,
    handleClose,
  } = data;

  const finalList = handle_selectedData.filter((ele) => {
    return (
      project_detail.find((e) => e.project_id == ele.project_id).myRole !=
      ele.myRole
    );
  });

  // out when no change in user access
  if (finalList.length <= 0) {
    alert("No changes");
    return;
  }
  const final_access_list = finalList.reduce(
    (ans, element) => {
      return {
        ...ans,
        [`${element.myRole}`]: [...ans[element.myRole], element.project_id],
      };
    },
    {
      admin: [],
      dco: [],
      user: [],
    }
  );

  try {
    const token = window.localStorage.getItem("token");
    let verifiedData = await axios.post(
      `${process.env.React_App_prod_url}/project/update_user_access`,
      {
        projectData: final_access_list,
        user: selected_user,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    get_user_access_list_actionCreater({ dispatch });
    handleClose();
  } catch (err) {
    console.log({ err });
  }
};

export const handle_delete_user_updateUserAccess = async (data) => {
  const { selected_user, dispatch } = data;

  try {
    const token = window.localStorage.getItem("token");
    let verifiedData = await axios.post(
      `${process.env.React_App_prod_url}/user/delete_user`,
      {
        email: selected_user,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    get_user_access_list_actionCreater({ dispatch });
  } catch (err) {
    console.log({ err });
  }
};

export const handle_invite_user = async (data) => {
  const { email, handleClose, dispatch, setEmail } = data;

  try {
    if (!email || email == "") {
      show_toaster_actionCreater({
        message: "Please Enter email of user",
        status: "error",
      });
      return;
    }

    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email.match(mailformat)) {
      show_toaster_actionCreater({
        message: "Email not valid",
        status: "error",
      });
      return;
    }
    const token = window.localStorage.getItem("token");
    let verifiedData = await axios.post(
      `${process.env.React_App_prod_url}/user/inviteuser`,
      {
        email,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    show_toaster_actionCreater({
      message: "User Invited successfully",
      status: "success",
    });
    handleClose();
    setEmail("");
    get_user_access_list_actionCreater({ dispatch });
  } catch (err) {
    console.log({ err });
    if (err.response)
      show_toaster_actionCreater({
        message: err.response?.data.err,
        status: "error",
      });
    setEmail("");
    handleClose();
  }
};

export const handle_change_password_for_user = async (data) => {
  const { currentPassword, Password, Conform_Password, handleClose, navigate } =
    data;

  try {
    const token = window.localStorage.getItem("token");
    let verifiedData = await axios.post(
      `${process.env.React_App_prod_url}/user/change_password`,
      {
        oldPassword: currentPassword,
        password: Password,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    handleClose();
    logoutuser({ navigate });
  } catch (err) {
    console.log({ err });
    if (err.response) alert(err.response?.data.err);
    handleClose();
  }
};

export const handleContactUs = async (data) => {
  const { message, handleClose } = data;

  try {
    const token = window.localStorage.getItem("token");
    let data = await axios.post(
      `${process.env.React_App_prod_url}/user/contact_us`,
      {
        message,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    handleClose();
  } catch (err) {
    console.log({ err });
    if (err.response) alert(err.response?.data.err);
    handleClose();
  }
};
