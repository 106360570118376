import React from 'react'
import './slidescreen3.css'
import slide3pic from '../../assest/Image/slide3pic.png'
import inviteuserarrow from '../../assest/Image/inviteuserarrow.png'
import inviteuserdownarrow from '../../assest/Image/inviteuserdownarrow.png'
import { useNavigate } from 'react-router-dom'
function Slidescreen3() {
  const navigate = useNavigate();
  return (
    <div className='SlideScreen3div'>

    <div className='spares3'>
        <div className='spare3'></div>
        <div className='spare3'>

        </div>
        <div className='activespare3'></div>
    </div>
    <div className='slider3paragraphmain' >What you can do</div>
    <div className='inviteuseruparrow'><img src={inviteuserarrow}/></div>
   
        <div className='slider3paragraph'>
        Create a New Project and Add Users to proceed further.    </div>
    <button  className='sliderbtn3' onClick={ ()=>navigate('/home/add')} ><span>Create New Project</span></button>
<div className='inviteuserdownarrow'><img src={inviteuserdownarrow}/></div>
    <div className='slider3img'><img src={slide3pic}/></div>
    </div>
  )
}

export default Slidescreen3