import { SELECT_QUERY_OF_SEQUENCE, SELECT_QUERY_STATUS } from "../constants";

export const select_query_of_sequence_action = ({ data }) => {
  return {
    type: SELECT_QUERY_OF_SEQUENCE,
    payload: data,
  };
};

export const set_query_status_action = ({ status }) => {
  return {
    type: SELECT_QUERY_STATUS,
    payload: status,
  };
};
