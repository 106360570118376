import { EDIT_SEQUENCE, GET_PROJECTS, SELECT_PROJECT_DATA } from "../constants";

export const get_projects_Action = ({ data }) => {
  return {
    type: GET_PROJECTS,
    payload: data,
  };
};

export const edit_project_sequence_Action = ({ data }) => {
  return {
    type: EDIT_SEQUENCE,
    payload: data,
  };
};

export const set_project_data_Action = ({ data }) => {
  return {
    type: SELECT_PROJECT_DATA,
    payload: data,
  };
};
