import React, { useState } from "react";
import {
    BsFillPersonFill,
    BsChevronCompactDown,
    BsChevronDown,
} from "react-icons/bs";
import email from "../../assest/Image/email.png";
import password from "../../assest/Image/password.png";
import name from "../../assest/Image/name.png";
import calling from "../../assest/Image/calling.png";
import city from "../../assest/Image/city.png";
import company from "../../assest/Image/company.png";
import countryimage from "../../assest/Image/countryimage.png";
import profession from "../../assest/Image/profession.png";
import * as Yup from "yup";
import { useFormik } from "formik";
// import "./logonfile.css";
import { loginSignupselect } from '../../Functions/LoginSingupfun.js'
import backgroundbuilding from "../../assest/Image/backgroundbuilding.png";
import axios from "axios";
import { show_toaster_actionCreater } from "../../Redux/toasers/CreateActions";
import { FiX } from "react-icons/fi";
import { useRef } from "react";
import { useEffect } from "react";

function DetectOutsideClick(ref, setstate) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setstate(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  
function WebSignup({ Show, setShow, selectedbtn, setSelectedbtn, toggle, Signupstatus, setSignupstatus }) {
    const contianer = useRef();
    const companyref = useRef();
    const professionref = useRef();
    const numberRef = useRef();
    const countryref = useRef();
    const [handlenumber, SetHandlenumber] = useState(false);
    const [CompanyNameArray,setCompanyNameArray] = useState([])
    const [showcountry, setShowcountry] = useState(false);
    const [showprofession, setShowprofession] = useState(false)
    const [showCompanyName, setShowCompanyName] = useState(false)
    const [Showcompanyvalue,setShowcompanyvalue] = useState("")
    
    DetectOutsideClick(contianer, setSignupstatus);
    DetectOutsideClick(professionref, setShowprofession);
    DetectOutsideClick(companyref, setShowCompanyName);
    DetectOutsideClick(numberRef, SetHandlenumber);
    DetectOutsideClick(countryref, setShowcountry);
    const professiondata = [
        "Archetecture",
        "EOR",
        "Fabricator",
        "DCO",
        "Joist Detailer",
        "Structure Detailer",
        "GC (General Constructor)"
    ]

    const CompanyData = [
        "Aliste",
        "GraphKeting",
        "TCS",
        "Infosys",
        "Wipro"
    ]
    const handleCountry = () => {
        setShowcountry(!showcountry);
    };
    const handlenumberclick = () => {
        SetHandlenumber(!handlenumber);
    };

    const {
        values,
        onSubmit,
        setFieldValue,
        touched,
        errors,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: {
            Name: "",
            Email: "",
            Number: "",
            Selectnumber: "1",
            Password: "",
            Profession: "",
            Companyname: "",
            Country: "",
            City: "",
        },
        validationSchema: Yup.object({
            Name: Yup.string().min(2).max(34).required("Please Enter Your Name"),
            Selectnumber: Yup.string().required("Please Select any number"),
            Email: Yup.string().email().required("Please Enter Your Email"),
            Number: Yup.string().min(1).max(12).required("Please Enter Your Number"),
            Password: Yup.string()
                .min(10)
                .max(30)
                .required("Please Enter Your Password"),
            Profession: Yup.string()
                .min(2)
                .max(34)
                .required("Please Enter Your profession"),
            Companyname: Yup.string()
                .min(2)
                .max(34)
                .required("Please Enter Your CompanyName"),
            Country: Yup.string().required("Please select anyone"),
            City: Yup.string().min(2).max(100).required("Please Enter Your City"),
        }),
        onSubmit: async (values, action) => {
            console.log(values,"values are here");
            const response = await signupfunction(values);
            if (response) {
                setTimeout(() => {
                    action.resetForm();
                    setSignupstatus(false)

                }, 100);
            }
        },
    });

    const signupfunction = async (values) => {
        try {
            const responsedata = await axios.post(
                // "http://localhost:8080/api/2023/user/signup",
                `${process.env.React_App_prod_url}/user/signup`,
                {
                    name: values.Name,
                    email: values.Email,
                    phone: values.Number,
                    password: values.Password,
                    profession: values.Profession,
                    country: values.Country,
                    city: values.City,
                    companyName: values.Companyname,
                }
            );
            show_toaster_actionCreater({
                message: " Account created. Please login now.",
                status: "success",
            });
            return true;
        } catch (err) {
            if (err?.response?.data?.err?.code == 11000) {
                if (err?.response?.data?.err.keyPattern.email == 1) {
                    show_toaster_actionCreater({
                        message: " Account already exists.",
                        status: "error",
                    });
                } else if (err?.response?.data?.err.keyPattern.phone == 1) {

                    show_toaster_actionCreater({
                        message: "Phone Number Already Registered.",
                        status: "error",
                    });
                }
            }

            return false;
        }
    };

 
    useEffect(() => {
        Signupstatus ? contianer.current.style.transform = "translate(0)"
            // contianer.current.style.transform
            :
            contianer.current.style.transform = "translate(1000px)";
        // Signupstatus ? contianer.current.style.top = "0" : contianer.current.style.top = "-150%"
    }, [Signupstatus])

    useEffect(()=>{
        AllCompany();
      },[])
      
      const AllCompany = async()=>{
        try{
          const companyNameList  = await axios.get(
            `${process.env.React_App_prod_url}/company/all`);
            console.log(companyNameList,'companyNameList');
            setCompanyNameArray(companyNameList.data.company)
        }
      
        catch(error){
          console.log(error,'error');
        }
      }

      const handlecompany = (e) => {
        setFieldValue("Companyname", e.target.value);
        setShowCompanyName(true);
        setShowcompanyvalue(e.target.value);
        const resultcompanyname = CompanyNameArray.filter((names) => {
          console.log(names,'names');
          const value = names.companyName.toLowerCase().includes(e.target.value.toLowerCase());
          if (value === true) {
            return names.companyName;
          }
        });
        console.log(resultcompanyname,'resultcompanyname');
    
        setShowcompanyvalue(resultcompanyname);
      };

    return (
        <div
            ref={contianer}
            className="weblogindiv"
            style={{
                backgroundImage: `url(${backgroundbuilding})`,
                backgroundSize: "90% auto",
                backgroundRepeat: 'no-repeat',
                backgroundPosition: "bottom"
            }}
        >
            {/* <div className="webloginsmalldiv"> */}
            <FiX className="closesignupbtn" onClick={() =>
                setSignupstatus(false)
                //  {  loginSignupselect({ tab: "", setSelectedbtn ,setShow}) }
            } />
            <div className="webcreateAccount">
                <span className="animatedcreateAccountspan">Create Your Account</span>
            </div>
            <div className="webformdiv">
                <form className="websignupform" autoComplete="off">
                    <div className={errors.Name && touched.Name ? "input-group mb-3 nameinputdiverror" : "input-group mb-3 nameinputdiv1"}>
                        <span
                            className="input-group-text websignupemailspan"
                            id="basic-addon1"
                            htmlFor="Name"
                        >
                            <img src={name} className="nameimage" />
                        </span>
                        <input
                            type="text"
                            className="form-control animatednameinput1 shadow-none"
                            onChange={handleChange}
                            placeholder="Name"
                            value={values.Name}
                            name="Name"
                            aria-describedby="basic-addon1"
                        />
                    </div>
                    {/* {errors.Name && touched.Name ? (
                        <p className="Errors">{errors.Name}</p>
                    ) : null} */}
                    <div className={errors.Email && touched.Email ? "input-group mb-3 nameinputdiverror" : "input-group mb-3 nameinputdiv1"}>
                        <span
                            className="input-group-text websignupemailspan"
                            id="basic-addon1"
                            htmlFor="Email"
                        >
                            <img src={email} className="nameimage" />
                        </span>
                        <input
                            type="email"
                            className="form-control animatednameinput1 shadow-none "
                            onChange={handleChange}
                            placeholder="Email"
                            value={values.Email}
                            name="Email"
                            aria-describedby="basic-addon1"
                        />
                    </div>
                    {/* {errors.Email && touched.Email ? (
                        <p className="Errors">{errors.Email}</p>
                    ) : null} */}
                    <div className="animatedmainnumberselectclass">
                        <div className="animatedselectclass" onClick={handlenumberclick}>
                            <div className={errors.Number && touched.Number || errors.Selectnumber && touched.Selectnumber  ? "webselectdropdownerror" : "webselectdropdown"}>
                                {" "}
                                <span>
                                {
                                    values.Selectnumber ?  `+${values.Selectnumber}` : ''
                                }
                                </span>
                                <BsChevronDown style={{ color: "black" }} />
                            </div>
                        </div>
                        <div className={errors.Number && touched.Number ? "webselectedinputdiverror" : "webselectedinputdiv"}>
                            <span
                                className="input-group-text websignupemailspan"
                                id="basic-addon1"
                                htmlFor="Number"
                            >
                                <img src={calling} className="nameimage" />
                            </span>
                            <input
                                // type="number"
                                className={errors.Number && touched.Number ? "form-control animatedselectedinput1error shadow-none" : "form-control animatedselectedinput1 shadow-none"}
                                onChange={handleChange}
                                placeholder="Number"
                                value={values.Number}
                                name="Number"
                            />
                        </div>
                    </div>
                    {/* {errors.Number && touched.Number ? (
                        <p className="Errors">{errors.Number}</p>
                    ) : null} */}
                    <div className={errors.Password && touched.Password ? "input-group mb-3 nameinputdiverror" : "input-group mb-3 nameinputdiv1"}>
                        <span
                            className="input-group-text websignupemailspan"
                            id="basic-addon1"
                            htmlFor="Password"
                        >
                            <img src={password} className="nameimage" />
                        </span>
                        <input
                            type="password"
                            className="form-control animatednameinput1 shadow-none"
                            onChange={handleChange}
                            placeholder="Password"
                            value={values.Password}
                            aria-label="Username"
                            name="Password"
                            aria-describedby="basic-addon1"
                        />
                    </div>
                    {/* {errors.Password && touched.Password ? (
                        <p className="Errors">{errors.Password}</p>
                    ) : null} */}
                    <div className={errors.Profession && touched.Profession ? "input-group mb-3 nameinputdiverror" : "input-group mb-3 nameinputdiv1"} onClick={() => setShowprofession(true)}>
                        <span
                            className="input-group-text websignupemailspan"
                            id="basic-addon1"
                            htmlFor="Profession"
                        >
                            <img src={profession} className="nameimage" />
                        </span>

                        <div className="professionselect">
                            <span style={{ marginTop: "1%" }}>
                                {
                                    values.Profession === "" ? "Select Profession" : values.Profession
                                }
                            </span>
                            <BsChevronDown style={{ marginRight: "3%", color: "black" }} />
                        </div>
                        {/* <input
                            type="text"
                            className="form-control animatednameinput1 shadow-none "
                            onChange={handleChange}
                            placeholder="Profession"
                            value={values.Profession}
                            name="Profession"
                            aria-describedby="basic-addon1"
                        /> */}
                    </div>
                    {/* {errors.Profession && touched.Profession ? (
                        <p className="Errors">{errors.Profession}</p>
                    ) : null} */}

                    <div className={errors.Companyname && touched.Companyname ? "input-group mb-3 nameinputdiverror" : "input-group mb-3 nameinputdiv1"}>
                        <span
                            className="input-group-text websignupemailspan"
                            id="basic-addon1"
                            htmlFor="Companyname"
                        >
                            <img src={company} className="nameimage" />
                        </span>
                        <input
                            type="text"
                            className="form-control animatednameinput1 shadow-none "
                            // onChange={handleChange}
                            onChange={(e)=>{
                                handlecompany(e)
                                // setFieldValue("Companyname",e.target.value)
                                // handleCompanyNameChange(e)
                            }}
                            placeholder="Company Name"
                            value={values.Companyname}
                            name="Companyname"
                            aria-describedby="basic-addon1"
                        />
                    </div>
                    {/* {errors.Companyname && touched.Companyname ? (
                        <p className="Errors">{errors.Companyname}</p>
                    ) : null} */}
                    <div className="animatedmainnumberselectclass">
                        <div
                            className="animatedselectclass"
                            htmlFor="Country"
                            onClick={handleCountry}
                        >
                            <div className={errors.Country && touched.Country ? "webselectdropdownerror" : "webselectdropdown"}>
                                {" "}
                                <BsChevronDown style={{ color: "black" }} />
                            </div>
                        </div>
                        <div className={errors.Country && touched.Country ? "webselectedinputdiverror" : "webselectedinputdiv"}>
                            <span
                                className="input-group-text websignupemailspan"
                                id="basic-addon1"
                                htmlFor="Country"
                            >
                                <img src={countryimage} className="nameimage" />
                            </span>
                            <input
                                type="text"
                                className="form-control  shadow-none"
                                onChange={handleChange}
                                placeholder="Country"
                                value={values.Country}
                                name="Country"
                            />
                        </div>
                    </div>
                    {/* {errors.Country && touched.Country ? (
                        <p className="Errors">{errors.Country}</p>
                    ) : null} */}
                    <div className={errors.City && touched.City ? "input-group mb-3 nameinputdiverror" : "input-group mb-3 nameinputdiv1"}>
                        <span
                            className="input-group-text websignupemailspan"
                            id="basic-addon1"
                            htmlFor="City"
                        >
                            <img src={city} className="nameimage" />
                        </span>
                        <input
                            type="text"
                            className="form-control animatednameinput1 shadow-none "
                            onChange={handleChange}
                            placeholder="City"
                            value={values.City}
                            aria-label="Username"
                            name="City"
                            // onKeyDown={(e) =>SingupEnter(e)}
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    {/* {errors.City && touched.City ? (
                        <p className="Errors">{errors.City}</p>
                    ) : null} */}
                    <div
                        className="animatednamedivbtn"

                    >
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className="animatedsignbtn"
                        >
                            <span style={{ color: "white", fontSize: "large" }}>Sign In</span>
                        </button>
                    </div>

                    {handlenumber === true ? (
                        <div className="shownumbermaindiv" htmlFor="Selectnumber" ref={numberRef}>
                            <div
                                className="numberdiv"
                                value={values.Selectnumber}
                                name="Selectnumber"
                            >
                                <input type="checkbox" className="checkboxnumber"
                                    onChange={() => {
                                        setFieldValue("Selectnumber", 91);
                                        SetHandlenumber(!handlenumber);
                                    }} />
                                <div className="countrycode">   +91 </div>
                                <div className="numbercountryname">India</div>
                            </div>
                            <div
                                className="numberdiv"
                                value={values.Selectnumber}
                                name="Selectnumber"
                            // onClick={() => {
                            //     setFieldValue("Selectnumber", 91);
                            //     SetHandlenumber(!handlenumber);
                            // }}
                            >
                                <input type="checkbox" className="checkboxnumber"
                                    onChange={() => {
                                        setFieldValue("Selectnumber", 1);
                                        SetHandlenumber(!handlenumber);
                                    }} />
                                <div className="countrycode">   +1 </div>
                                <div className="numbercountryname">US</div>
                            </div>
                            <div
                                className="numberdiv"
                                value={values.Selectnumber}
                                name="Selectnumber"
                            // onClick={() => {
                            //     setFieldValue("Selectnumber", 224);
                            //     SetHandlenumber(!handlenumber);
                            // }}
                            >
                                <input type="checkbox" className="checkboxnumber"
                                    onChange={() => {
                                        setFieldValue("Selectnumber", 44);
                                        SetHandlenumber(!handlenumber);
                                    }} />
                                <div className="countrycode">   +44 </div>
                                <div className="numbercountryname">UK</div>
                            </div>
                            <div
                                className="numberdiv"
                                value={values.Selectnumber}
                                name="Selectnumber"
                            >
                                <input type="checkbox" className="checkboxnumber"
                                    onChange={() => {
                                        setFieldValue("Selectnumber", 61);
                                        SetHandlenumber(!handlenumber);
                                    }} />
                                <div className="countrycode">   +61 </div>
                                <div className="numbercountryname">Aus.</div>
                            </div>
                            <div
                                className="numberdiv"
                                value={values.Selectnumber}
                                name="Selectnumber"

                            >
                                <input type="checkbox" className="checkboxnumber" 
                                  onChange={() => {
                                    setFieldValue("Selectnumber", 41);
                                    SetHandlenumber(!handlenumber);
                                }}  />
                                <div className="countrycode">   +41 </div>
                                <div className="numbercountryname">Switzerland</div>
                            </div>
                            <div
                                className="numberdiv"
                                value={values.Selectnumber}
                                name="Selectnumber"

                            >
                                <input type="checkbox" className="checkboxnumber" 
                                  onChange={() => {
                                    setFieldValue("Selectnumber", 1);
                                    SetHandlenumber(!handlenumber);
                                }}  />
                                <div className="countrycode">   +01 </div>
                                <div className="numbercountryname">canada</div>
                            </div>
                            

                        </div>
                    ) : null}
                    {
                        showprofession === true ?

                            <div className="Scroll_DIv" >
                                <div className="showprofessionmaindiv" htmlFor="Profession"
                                 ref={professionref}
                                 >
                                    {
                                        professiondata.map((element, index) => (
                                            <div
                                                className="countrydiv"
                                                value={values.Profession}
                                                name="Country"
                                                onClick={() => {
                                                    setShowprofession(!showprofession);
                                                    setFieldValue("Profession", element);
                                                }}
                                            >
                                                {
                                                    element
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            : null
                    }
                    {
                        showCompanyName === true ?
                       
                        <div className="Scroll_DIv" > 
                           <div className="showcompanyname" htmlFor="Companyname" 
                           ref={companyref}
                           >   
                           {
                            Showcompanyvalue &&  Showcompanyvalue.map((element, index) => (
                               
                                        <div
                                            className="companynamediv"
                                            // value={values.Companyname}
                                            name="companyName"
                                            onClick={() => {
                                                setShowCompanyName(!showCompanyName);
                                                setFieldValue("Companyname", element.companyName);
                                            }}
                                        >
                                            {element.companyName}
                                        </div>
                            ))}
                                </div>
                            </div>
                            : null
                    }
                    {showcountry === true ? (
                        <div className="Scroll_DIv">
                            <div className="showcountrymaindiv" htmlFor="Country" ref={countryref}>
                                <div
                                    className="countrydiv"
                                    value={values.Country}
                                    name="Country"
                                    onClick={() => {
                                        setShowcountry(!showcountry);
                                        setFieldValue("Country", "India");
                                    }}
                                >
                                    India
                                </div>
                         
                            {/* <div className="showcountrymaindiv" htmlFor="Country"> */}
                                <div
                                    className="countrydiv"
                                    value={values.Country}
                                    name="Country"
                                    onClick={() => {
                                        setShowcountry(!showcountry);
                                        setFieldValue("Country", "US");
                                    }}
                                >
                                    US
                                </div>
                            {/* </div> */}
                            {/* <div className="showcountrymaindiv" htmlFor="Country"> */}
                                <div
                                    className="countrydiv"
                                    value={values.Country}
                                    name="Country"
                                    onClick={() => {
                                        setShowcountry(!showcountry);
                                        setFieldValue("Country", "UK");
                                    }}
                                >
                                    UK
                                </div>
                            {/* </div> */}
                            {/* <div className="showcountrymaindiv" htmlFor="Country"> */}
                                <div
                                    className="countrydiv"
                                    value={values.Country}
                                    name="Country"
                                    onClick={() => {
                                        setShowcountry(!showcountry);
                                        setFieldValue("Country", "canada");
                                    }}
                                >
                                    Canada
                                </div>
                            {/* </div> */}
                            {/* <div className="showcountrymaindiv" htmlFor="Country"> */}
                                <div
                                    className="countrydiv"
                                    value={values.Country}
                                    name="Country"
                                    onClick={() => {
                                        setShowcountry(!showcountry);
                                        setFieldValue("Country", "australia");
                                    }}
                                >
                                    Australia
                                </div>
                            {/* </div> */}
                            {/* <div className="showcountrymaindiv" htmlFor="Country"> */}
                                <div
                                    className="countrydiv"
                                    value={values.Country}
                                    name="Country"
                                    onClick={() => {
                                        setShowcountry(!showcountry);
                                        setFieldValue("Country", "switzerland");
                                    }}
                                >
                                    Switzerland
                                </div>
                            {/* </div> */}
                            </div>
                        </div>
                    ) : null}
                </form>
            </div>

        </div>
    );
}

export default WebSignup;

