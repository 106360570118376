import { GET_USER_ACCESS_LIST } from "../constants";
import { GET_SINGLE_USER_ACCESS_LIST } from "../constants";
export const get_user_access_list_Action = ({ data }) => {
  return {
    type: GET_USER_ACCESS_LIST,
    payload: data,
  };
};

export const get_single_user_list_Action = ({ data }) => {
  return {
    type: GET_SINGLE_USER_ACCESS_LIST,
    payload: data,
  };
};
