import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import editpic from "../../assest/Image/editpic.png";
import useraccessblue from "../../assest/Image/useraccessblue.png";
import userblue from "../../assest/Image/userblue.png";
import deleteuser from "../../assest/Image/deleteuser.png";
import whiteeditpencil from "../../assest/Image/whiteeditpencil.png";
import "./Projectoptions.css";
import useraccesswhite from "../../assest/Image/useraccesswhite.png";
import { useNavigate } from "react-router-dom";
// import addblueicon from "../../assest/Image/addblueicon.png";
import addblueicon from "../../assest/Image/addblueicon.png";
import { FaUserAlt } from "react-icons/fa";

import EditProjectname from "./EditProjectname";
import DeleteUserFromProject from "./deleteUserFromProject";
import CloseProjectModal from "./closeProjectModal";
import Adduserpopup from "../Adduser/Adduserpopup";
function ProjectOptions({
  projectopen,
  projectopenid,
  projectoption,
  setProjectoption,
  projectData,
}) {
  // "open add user popup"
  const [showadduser, setshowadduser] = useState(false);
  // "open add user popup end "
  const [Editprojectnamemodal, setEditprojectnamemodal] = useState(false);
  const [ishovereditproject, setIshovereditproject] = useState(false);
  const [ishoveruseraccess, setIshoveruseraccess] = useState(false);
  const [ishovercloseproject, setIshovercloseproject] = useState(false);

  const [openDeleteUser, setOpenDeleteUser] = React.useState(false);
  const [userForDelete, setuserForDelete] = useState(null);
  const handleOpenDeleteUser = () => setOpenDeleteUser(true);
  const handleCloseDeleteUser = () => setOpenDeleteUser(false);

  const [openCloseProject, setOpenCloseProject] = React.useState(false);
  const handleOpenCloseProject = () => setOpenCloseProject(true);
  const handleCloseCloseProject = () => setOpenCloseProject(false);
  const navigate = useNavigate();

  const handleprojectmodal = () => {
    setEditprojectnamemodal(true);
  };
  const handleClose = () => {
    setProjectoption(null);
  };
  return (
    <div className="handleprojectoptionpopoverdiv">
      <Popover
        id={projectopenid}
        style={{ boxShadow: "none" }}
        open={projectopen}
        anchorEl={projectoption}
        onClose={handleClose}
        className="projectoptionpopover"
        sx={{
          borderColor: "#194895",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography
          component={"div"}
          className="PoPup1Sub"
          sx={{
            // height:"47vh",
            background: "#F4F4F4",
            boxShadow: " 0px 3px 10px rgba(0, 0, 0, 0.3)",
          }}
        >
          <div
            className="projectheading"
            onClick={handleprojectmodal}
            onMouseOver={() => setIshovereditproject(true)}
            onMouseOut={() => setIshovereditproject(false)}
            style={{
              cursor: "pointer",
            }}
          >
            <img
              src={ishovereditproject ? whiteeditpencil : editpic}
              style={{ height: "11px", paddingLeft: "8%" }}
            />
            <span className="editprojectspan">Edit Project Name</span>
          </div>
          <hr className="hrclass"></hr>
          <div
            className="CloseProject"
            style={{
              cursor: "pointer",
            }}
            onClick={handleOpenCloseProject}
            onMouseOver={() => setIshovercloseproject(true)}
            onMouseOut={() => setIshovercloseproject(false)}
          >
            <img
              src={ishovercloseproject ? whiteeditpencil : editpic}
              style={{ height: "11px", paddingLeft: "8%" }}
            />
            <span className="closeprojectspan">Close Project</span>
          </div>
          <hr className="hrclass"></hr>
          {/* <div
            className="UserAccess"
            onMouseOver={() => setIshoveruseraccess(true)}
            onMouseOut={() => setIshoveruseraccess(false)}
            onClick={() => navigate("/home/adduser")}
          >
            <img
              src={ishoveruseraccess ? useraccesswhite : useraccessblue}
              style={{ height: "14px", paddingLeft: "8%" }}
            />
            <span className="UserAccessspan">User Access</span>
          </div> */}
          <hr className="hrclass"></hr>
          <div className="ProjectUser">
            <img src={userblue} style={{ height: "14px", paddingLeft: "8%" }} />
            <span style={{ color: "#142B59" }}>Project User</span>
          </div>

          {/* adding by default user  */}
          <div className="projectaddeduser">
            <div className="projectaddedusername">
              {" "}
              <FaUserAlt
                className="projectoptionuserlogo"
                style={{ marginLeft: "6%" }}
              />{" "}
              <div className="projectoptionuserName">{`${projectData?.createrName?.name} (super-admin)`}</div>
            </div>
            {/* <img
            src={deleteuser}
            style={{
              height: "12px",
              marginBottom: "4%",
              marginRight: "8%",
            }}
          /> */}
          </div>
          {/* adding by default user  */}

          {projectData?.projectUsers?.map((ele) => {
            return (
              <div className="projectaddeduser">
                <div className="projectaddedusername">
                  {" "}
                  <FaUserAlt
                    style={{ marginLeft: "6%" }}
                    className="projectoptionuserlogo"
                  />{" "}
                  <div
                    className="projectoptionuserName"
                    // style={{ marginLeft: "6%" }}
                  >{`${ele?.user?.name} (${ele?.role})`}</div>
                </div>
                <img
                  src={deleteuser}
                  style={{
                    height: "12px",
                    marginBottom: "4%",
                    marginRight: "8%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleOpenDeleteUser();
                    setuserForDelete(ele.user);
                  }}
                />
              </div>
            );
          })}

          <div className="adduser" onClick={() => setshowadduser(true)}>
            <img src={addblueicon} />
            Add User
          </div>
        </Typography>
        <EditProjectname
          Editprojectnamemodal={Editprojectnamemodal}
          setEditprojectnamemodal={setEditprojectnamemodal}
          projectData={projectData}
          setProjectoption={setProjectoption}
        />
        {showadduser ? (
          <Adduserpopup
            showadduser={showadduser}
            setshowadduser={setshowadduser}
            setProjectoption={setProjectoption}
            place="project"
            projectDetails={{
              name: projectData?.projectName,
              id: projectData._id,
            }}
          />
        ) : null}
        {openDeleteUser ? (
          <DeleteUserFromProject
            projectData={projectData}
            open={openDeleteUser}
            userForDelete={userForDelete}
            handleCloseDeleteUser={handleCloseDeleteUser}
            setProjectoption={setProjectoption}
          />
        ) : null}

        {openCloseProject ? (
          <CloseProjectModal
            projectData={projectData}
            open={openCloseProject}
            handleCloseCloseProject={handleCloseCloseProject}
            setProjectoption={setProjectoption}
          />
        ) : null}
      </Popover>
    </div>
  );
}

export default ProjectOptions;

// import React from 'react'

// function ProjectOptions() {
//   return (
//     <div>ProjectOptions</div>
//   )
// }

// export default ProjectOptions
