import React from "react";
import pdf from "../../../../assest/Image/pdf.png";
import download from "../../../../assest/Image/download.png";
import { MdOutlineReplyAll } from "react-icons/md";

function PdfWithSpanRecieved({ setChatreply, message, setreplymsg, type }) {
  const pdfname = "AjayTesting The pdf with the string";
  return (
    <div className="recievedmesssagepdfreply">
      <div className="replyofamessagemaindiv" style={{ background: "red" }}>
        {/* simple pdf reply message  */}
        {type === "receiveOnlyPdfWithMessage" ? (
          <>
            <div className="replytopdf">
              <img src={pdf} className="pdflogo" />
              <div className="replypdfname">{pdfname.substring(0, 14)} ..</div>
              <img src={download} className="replydownladimage" />
            </div>
            <div style={{ padding: "3%", fontSize: "13px" }}>
              {/* {data?.messageData?.description} */}
              {message.messageData.description}
            </div>
          </>
        ) : (
          <>
            <div className="replytopdf">
              <img src={pdf} className="pdfreplylogo" />
              <div>
                <div className="replytopdfusername">
                  {message?.replyOf?.sendFrom.name} (
                  {message?.replyOf?.sendFrom.profession})
                </div>
                <div className="replytopdfname">
                  {message?.replyOf?.messageData?.doc
                    ?.split("/")[4]
                    ?.substring(13)}
                </div>
              </div>
            </div>

            <div style={{ padding: "3%", fontSize: "13px" }}>
              {/* {data?.messageData?.description} */}
              {message.messageData.description}
            </div>
          </>
        )}

        {/* if pdf is a reply of any message  */}
        {/* <div className="replytopdf">
          <img src={pdf} className="pdfreplylogo" />
          <div>
            <div className="replytopdfusername">Reply to Sam (Fabricator)</div>
            <div className="replytopdfname">Shalini jha Docs File Reply</div>
          </div>
        </div> */}

        {/* {data?.messageData?.description} */}
        {/* <div style={{ padding: "3%", fontSize: "13px" }}>
          haa Bhai bilkul thik tu btta kya kr rha h aajkal :
        </div> */}
      </div>
      <div className="chatreplydiv" onClick={() => setChatreply(true)}>
        <MdOutlineReplyAll
          onClick={() => {
            setreplymsg({
              name: message.sendFrom.name,
              profession: message.sendFrom.profession,
              color: null,
              message: message.messageData.description,
              message_id: message._id,
            });
          }}
        />
      </div>
    </div>
  );
}

export default PdfWithSpanRecieved;
