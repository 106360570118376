import React from "react";
import Messagereplypop from "./Messagereplypop";
import { RxCross2 } from "react-icons/rx";
import "./reply.css";
import ImageReplypop from "./ImageReplypop";
import PdfReply from "./PdfReply";
import ReplyScreenWithImage from "../SimpleImageReplyWithDoc/ReplyScreenWithImage";
function Reply({ setChatreply,replymsg }) {
  console.log({replymsg})
  return (
    <div
      // className='boxshadowinreply' this class is for the without the pdf reply
      className="pdfboxshadowinreply"
    >
      <div
        // className="replymaindivclass" this class is used to without pdf reply
        className="pdfreplymaindivclass"
        style={{ background: "green" }}
      >
        <Messagereplypop setChatreply={setChatreply} replymsg={replymsg}/>
        {/* <PdfReply setChatreply={setChatreply} replymsg={replymsg}/> */}
        {/* <ImageReplypop setChatreply={setChatreply} replymsg={replymsg} /> */}
        {/* <ReplyScreenWithImage/> */}
      </div>
    </div>
  );
}

export default Reply;
