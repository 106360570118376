import axios from "axios";
import { get_notification_Action } from "../Redux/notifications/Actions";

export const get_user_data = async ({ token, setUserData }) => {
  try {
    let verifiedData = await axios.get(
      `${process.env.React_App_prod_url}/user/get_user_data_from_token`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    setUserData(verifiedData.data.user);
    return 0;
  } catch (err) {
    console.log({ err });
  }
};

export const get_user_notifications = async ({ dispatch }) => {
  try {
    let token = window.localStorage.getItem("token");
    let notifications = await axios.get(
      `${process.env.React_App_prod_url}/notifications/10`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    dispatch(
      get_notification_Action({
        data: notifications.data,
      })
    );
  } catch (err) {
    console.log({ err });
  }
};

export const handle_read_notifications = async ({ dispatch, id }) => {
  try {
    let token = window.localStorage.getItem("token");
    let notifications = await axios.post(
      `${process.env.React_App_prod_url}/notifications/read`,
      {
        id,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    get_user_notifications({ dispatch });
  } catch (err) {
    console.log({ err });
  }
};
