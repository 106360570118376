import React, { useState, useEffect } from "react";
import editpic from "../../assest/Image/editpic.png";
import name from "../../assest/Image/name.png";
import email from "../../assest/Image/email.png";
import calling from "../../assest/Image/calling.png";
import companyimg from "../../assest/Image/companyimg.png";
import profession from "../../assest/Image/profession.png";
import cityimg from "../../assest/Image/cityimg.png";
import "./editprofile.css";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  BsFillPersonFill,
  BsChevronCompactDown,
  BsChevronDown,
} from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { get_single_user_list_actioncreator } from "../../Redux/profile/CreateActions";

function Editprofile() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.profileData);

  const [CompanyNameArray, setCompanyNameArray] = useState([]);

  const companyArray = [
    "Altech",
    "Graphketing",
    "Aliste",
    "tcs",
    "Wipro",
    "Zydex",
    "Mahindra",
    "TATA",
    "Infosyses",
    "Admin",
  ];
  const ProfessionArray = [
    "Archetecture",
    "EOR",
    "Fabricator",
    "DCO",
    "Joist Detailer",
    "Structure Detailer",
    "GC (General Constructor)",
  ];

  const [showcompanynames, setShowcompanynames] = useState(false);
  const [showcompanyvalue, setShowcompanyvalue] = useState("");
  const [showproffessionnames, setShowprofessionnames] = useState(false);
  const [showproffessionvalue, setShowprofessionvalue] = useState("");
  const handleprofession = (e) => {
    setFieldValue("Profession", e.target.value);
    setShowprofessionnames(true);
    setShowprofessionvalue(e.target.value);
    const resultprofession = ProfessionArray.filter((names) => {
      const value = names.toLowerCase().includes(e.target.value.toLowerCase());
      if (value === true) {
        return names;
      }
    });
    setShowprofessionvalue(resultprofession);
  };
  const handlecompany = (e) => {
    setFieldValue("Companyname", e.target.value);
    setShowcompanynames(true);
    setShowcompanyvalue(e.target.value);
    const resultcompanyname = CompanyNameArray.filter((names) => {
      console.log(names, "names");
      const value = names.companyName
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
      if (value === true) {
        return names.companyName;
      }
    });
    console.log(resultcompanyname, "resultcompanyname");

    setShowcompanyvalue(resultcompanyname);
  };

  //  const handleSearch = (event) => {
  //     event.preventDefault();
  //     const results = props.data.filter((item) =>
  //       item.name.toLowerCase().includes(searchTerm.toLowerCase())
  //     );
  //     setSearchResults(results);
  //   };
  // end handle company name and profession search

  const [handlenumber, SetHandlenumber] = useState(false);
  const [showcountry, setShowcountry] = useState(false);
  const handleCountry = () => {
    setShowcountry(!showcountry);
  };
  const handlenumberclick = () => {
    SetHandlenumber(!handlenumber);
  };

  useEffect(() => {
    AllCompany();
  }, []);

  const AllCompany = async () => {
    try {
      const companyNameList = await axios.get(
        `${process.env.React_App_prod_url}/company/all`
      );
      console.log(companyNameList, "companyNameList");
      setCompanyNameArray(companyNameList.data.company);
    } catch (error) {
      console.log(error, "error");
    }
  };
  console.log(CompanyNameArray, "companyNameArray");

  const [initialValue, setinitialValue] = useState({
    Name: data?.name,
    // Email: "",
    Number: data?.phone || "",
    Selectnumber: "91",
    // Password: "",
    Profession: data?.profession || "",
    Companyname: data?.companyName || "",
    Country: data?.country || "",
    City: data?.city || "",
  });
  const {
    values,
    onSubmit,
    setFieldValue,
    // touched,
    // errors,
    // action,
    handleChange,
    handleSubmit,
    // enableReinitialze,
    // dirty,
  } = useFormik({
    enableReinitialize: true,
    dirty: true,
    initialValues: initialValue,
    validationSchema: Yup.object({
      Name: Yup.string().min(2).max(34).required("Please Enter Your Name"),
      Selectnumber: Yup.string().required("Please Select any number"),
      // Email: Yup.string().email().required("Please Enter Your Email"),
      Number: Yup.string().min(1).max(12).required("Please Enter Your Number"),
      Profession: Yup.string()
        .min(2)
        .max(34)
        .required("Please Enter Your profession"),
      Companyname: Yup.string()
        .min(2)
        .max(34)
        .required("Please Enter Your CompanyName"),
      Country: Yup.string().required("Please select anyone"),
      City: Yup.string().min(2).max(100).required("Please Enter Your City"),
    }),
    onSubmit: async (values, action) => {
      console.log(data, "values of profile");
    },
  });
  useEffect(() => {
    if (data)
      setinitialValue({
        Name: data?.name,
        // Email: "",
        Number: data?.phone || "",
        Selectnumber: "91",
        // Password: "",
        Profession: data?.profession || "",
        Companyname: data?.companyName || "",
        Country: data?.country || "",
        City: data?.city || "",
      });
  }, [data]);

  return (
    <div className="editprofilemaindiv">
      <div className="editprofilespan">
        Edit Profile
        <img src={editpic} />
      </div>
      <form>
        <div className="editprofilediv1">
          <div
            className="editprofileusername"
            style={{ display: "flex" }}
            htmlFor="Name"
          >
            <AiOutlineUser className="profilenamespan" />
            <input
              type="text"
              className="form-control profilenameinput shadow-none"
              onChange={handleChange}
              value={values?.Name}
              placeholder="Name"
              name="Name"
              aria-describedby="basic-addon1"
            />
          </div>
          {/* {errors.Name && touched.Name ? (
                        <p className="Errors">{errors.Name}</p>
                    ) : null} */}
          <div className="editprofileuseremail">
            <span
              className="profileuseremail "
              htmlFor="Email"
              id="basic-addon1"
            >
              <img src={email} className="nameimage" />
            </span>
            {/* <input type="email" className="form-control profilenameinput shadow-none" onChange ={handleChange} value = {values.Email} placeholder="email" name='Email' aria-describedby="basic-addon1" disabled /> */}
            <span className="profilenameinput ">{data?.email}</span>
          </div>
        </div>
        <div className="profilediv2">
          <div className="selectednumber">
            <div className="profileselect" onClick={handlenumberclick}>
              <span style={{ color: "black", fontSize: "11px" }}>
                +{values.Selectnumber}
              </span>
              <BsChevronDown style={{ color: "black", fontSize: "11px" }} />
            </div>
            <div className="profileselectedinputdiv">
              <span
                className=" profileuseremail"
                id="basic-addon1"
                htmlFor="Number"
              >
                <img src={calling} className="nameimage" />
              </span>
              <input
                className="form-control profileselectedinput shadow-none"
                onChange={handleChange}
                value={values.Number}
                placeholder="Number"
                name="Number"
              />
            </div>
            {/* {errors.Number && touched.Number ? (
                        <p className="Errors">{errors.Number}</p>
                    ) : null} */}
          </div>

          <div className="profilecompanyname">
            <span
              className="profileuseremail "
              htmlFor="Companyname"
              id="basic-addon1"
            >
              <img src={companyimg} className="profilecompanyimagae" />
            </span>
            <input
              type="text"
              className="form-control profilenameinput shadow-none"
              autoComplete="off"
              value={values.Companyname}
              name="Companyname"
              onChange={(event) => handlecompany(event)}
              placeholder="ABC company"
              aria-describedby="basic-addon1"
            />
          </div>
          {/* {errors.Companyname && touched.Companyname ? (
                        <p className="Errors">{errors.Companyname}</p>
                    ) : null} */}
        </div>

        <div className="profilediv3">
          <div className="profileprofession" style={{ display: "flex" }}>
            <span
              className="profileuseremail "
              id="basic-addon1"
              htmlFor="Profession"
            >
              <img src={profession} className="profileprofessionimage" />
            </span>
            <input
              type="text"
              className="form-control profilenameinput shadow-none"
              placeholder="Profession"
              name="Profession"
              value={values.Profession}
              onChange={(event) => handleprofession(event)}
              aria-describedby="basic-addon1"
            />
          </div>
          {/* {errors.Profession && touched.Profession ? (
                        <p className="Errors">{errors.Profession}</p>
                    ) : null} */}
          <div className="profilecity">
            <span
              className="profileuseremail "
              htmlFor="City"
              id="basic-addon1"
            >
              <img src={cityimg} className="profilecityimage" />
            </span>
            <input
              type="text"
              className="form-control profilenameinput shadow-none"
              autoComplete="off"
              placeholder="City"
              aria-describedby="basic-addon1"
              name="City"
              value={values.City}
              onChange={handleChange}
            />
          </div>
          {/* {errors.City && touched.City ? (
                        <p className="Errors">{errors.City}</p>
                    ) : null} */}
        </div>
        <div className="profilediv4">
          <div className="selectednumber">
            <div className="profilecountryselect" onClick={handleCountry}>
              <BsChevronDown style={{ color: "black" }} />
            </div>
            <div className="profilecountryselectinput">
              <span
                className=" profileuseremail"
                id="basic-addon1"
                htmlFor="Country"
              >
                <img src={calling} className="profilecountryimg" />
              </span>
              <input
                type="text"
                className="form-control profileselectedinput shadow-none"
                placeholder="Country"
                value={values.Country}
                onChange={handleChange}
                name="Country"
              />
            </div>
            {/* {errors.Country && touched.Country ? (
                        <p className="Errors">{errors.Country}</p>
                    ) : null} */}
          </div>
        </div>
        <div className="profilediv5">
          {/* <div className='profilecancle ' >Cancel</div> */}
          <button type="button" onClick={handleSubmit} className="profilesave">
            Save changes
          </button>
        </div>

        {handlenumber === true ? (
          <div className="shownumberdivinprofile" htmlFor="Selectnumber">
            <div
              className="shownumberminidivinprofile"
              value={values.Selectnumber}
              name="Selectnumber"
              onClick={() => {
                setFieldValue("Selectnumber", 224);
                SetHandlenumber(!handlenumber);
              }}
            >
              +91 India
            </div>
            <div
              className="shownumberminidivinprofile"
              value={values.Selectnumber}
              name="Selectnumber"
              onClick={() => {
                setFieldValue("Selectnumber", 91);
                SetHandlenumber(!handlenumber);
              }}
            >
              +224 Pakistan
            </div>
            <div
              className="shownumberminidivinprofile"
              value={values.Selectnumber}
              name="Selectnumber"
              onClick={() => {
                setFieldValue("Selectnumber", 224);
                SetHandlenumber(!handlenumber);
              }}
            >
              +710 SouthAfrica
            </div>
            <div
              className="shownumberminidivinprofile"
              value={values.Selectnumber}
              name="Selectnumber"
              onClick={() => {
                setFieldValue("Selectnumber", 710);
                SetHandlenumber(!handlenumber);
              }}
            >
              +96 England
            </div>
            <div
              className="shownumberminidivinprofile"
              value={values.Selectnumber}
              name="Selectnumber"
              onClick={() => {
                setFieldValue("Selectnumber", 222);
                SetHandlenumber(!handlenumber);
              }}
            >
              +222 America
            </div>
          </div>
        ) : null}
        {showcountry === true ? (
          <div className="Scroll_DIv">
            <div className="showcountrydivinprofile">
              <div htmlFor="Country">
                <div
                  className="showcountryminidivinprofile"
                  value={values.Country}
                  name="Country"
                  onClick={() => {
                    setShowcountry(!showcountry);
                    setFieldValue("Country", "India");
                  }}
                >
                  India
                </div>
              </div>
              <div htmlFor="Country">
                <div
                  className="showcountryminidivinprofile"
                  value={values.Country}
                  name="Country"
                  onClick={() => {
                    setShowcountry(!showcountry);
                    setFieldValue("Country", "South Africa");
                  }}
                >
                  South Africa
                </div>
              </div>
              <div htmlFor="Country">
                <div
                  className="showcountryminidivinprofile"
                  value={values.Country}
                  name="Country"
                  onClick={() => {
                    setShowcountry(!showcountry);
                    setFieldValue("Country", "America");
                  }}
                >
                  America
                </div>
              </div>
              <div htmlFor="Country">
                <div
                  className="showcountryminidivinprofile"
                  value={values.Country}
                  name="Country"
                  onClick={() => {
                    setShowcountry(!showcountry);
                    setFieldValue("Country", "England");
                  }}
                >
                  England
                </div>
              </div>
              <div htmlFor="Country">
                <div
                  className="showcountryminidivinprofile"
                  value={values.Country}
                  name="Country"
                  onClick={() => {
                    setShowcountry(!showcountry);
                    setFieldValue("Country", "Sri Lanka");
                  }}
                >
                  Sri Lanka
                </div>
              </div>
              <div htmlFor="Country">
                <div
                  className="showcountryminidivinprofile"
                  value={values.Country}
                  name="Country"
                  onClick={() => {
                    setShowcountry(!showcountry);
                    setFieldValue("Country", "SwitzerLand");
                  }}
                >
                  SwitzerLand
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* show company Names that are already available */}

        {showcompanynames === true ? (
          // <div className="Scroll_DIv">
          <div className="showcompanydivinprofile">
            {showcompanyvalue
              ? showcompanyvalue.map((name) => {
                  return (
                    <div htmlFor="Companyname">
                      <div
                        className="showcountryminidivinprofile"
                        value={values.Companyname}
                        name="Companyname"
                        onClick={() => {
                          setShowcompanynames(!showcompanynames);
                          setFieldValue("Companyname", name.companyName);
                        }}
                      >
                        {name.companyName}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        ) : // </div>
        null}

        {/* End here show company Names that are already available  */}

        {showproffessionnames === true ? (
          // <div className="Scroll_DIv">
          <div className="showprofessiondivinprofile">
            {showproffessionvalue
              ? showproffessionvalue.map((name) => {
                  return (
                    <div htmlFor="Profession">
                      <div
                        className="showcountryminidivinprofile"
                        value={values.Profession}
                        name="Profession"
                        onClick={() => {
                          setShowprofessionnames(!showproffessionnames);
                          setFieldValue("Profession", name);
                        }}
                      >
                        {name}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        ) : // </div>
        null}

        {/* End here show company Names that are already available  */}
        {/* showproffessionnames */}
      </form>
    </div>
  );
}

export default Editprofile;
