import { CHANGE_ROUTER } from "../constants";

export default function RouterReducer(state = null, action) {
  switch (action.type) {
    case CHANGE_ROUTER:
      return action.payload;
    default:
      return state;
  }
}
