import React from "react";
import { MdOutlineReplyAll } from "react-icons/md";
import "../sendmessage.css";
import pdf from "../../../../assest/Image/pdf.png";
import download from "../../../../assest/Image/download.png";
function PdfReplysend({ setChatreply, message, setreplymsg, type }) {
  const pdfname = "AjayTesting The pdf with the string";

  return (
    <div className="pdfchatselfreplymaindiv">
      <div className="selfchatreplydiv" onClick={() => setChatreply(true)}>
        <MdOutlineReplyAll
          onClick={() => {
            setreplymsg({
              name: message.sendFrom.name,
              profession: message.sendFrom.profession,
              color: null,
              message: message.messageData.description,
              message_id: message._id,
            });
          }}
        />
      </div>
      <div className="sendreplyofamessagemaindiv">
        <div className="selfreplytopdf">
          <img src={pdf} className="pdfreplylogo" />
          <div
            style={{
              fontSize: "12px",
              color: "red",
            }}
          >
            <div className="replytopdfusername">
              {message?.replyOf?.sendFrom.name} ({message?.replyOf?.sendFrom.profession})
            </div>

            {message?.replyOf?.messageData?.doc?.split("/")[4]?.substring(13)}
            {/* {message?.replyOf?.messageData?.description} */}
          </div>
        </div>
        <div style={{ padding: "3%", fontSize: "13px" }}>
          {message?.messageData?.description}
        </div>
      </div>
    </div>
  );
}

export default PdfReplysend;
