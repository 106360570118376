import React from "react";
import { MdOutlineReplyAll } from "react-icons/md";
import "../sendmessage.css";
function ChatSelfReply({
  setChatreply,
  forwardtoChatReplymsg,
  message,
  setreplymsg,
}) {
  return (
    <div className="chatselfreplymaindiv">
      <div className="selfchatreplydiv" onClick={() => setChatreply(true)}>
        <MdOutlineReplyAll
          onClick={() => {
            setreplymsg({
              name: message.sendFrom.name,
              profession: message.sendFrom.profession,
              color: null,
              message: message.messageData.description,
              message_id: message._id,
            });
          }}
        />
      </div>
      <div
        className="sendreplyofamessagemaindiv"
        // style={{ background: `${color}` }}
      >
        <div className="selfsendreplyto">
          <div
            className="sendreplytousername"
            onClick={() => {
              forwardtoChatReplymsg(message?.replyOf._id)
            }}
            // style={{ color: `${color}` }}
            style={{ color: "pink" }}
          >
            {/* {data?.replyOf?.sendFrom?.name}{" "}
        {`(${data?.replyOf?.sendFrom?.profession})`} */}
            {message?.replyOf?.sendFrom.name} (
            {message?.replyOf?.sendFrom.profession})
          </div>
          <div className="sendmessagethatreply">
            {message?.replyOf?.messageData?.description}
            {/* Some Thing Not Working Properly In your Project */}
          </div>
        </div>
        <div style={{ padding: "3%", fontSize: "13px" }}>
          {message?.messageData?.description}
          {/* I will Check that And Inform You Later
          About All Of That */}
        </div>
      </div>
    </div>
  );
}

export default ChatSelfReply;
