import React from "react";
import "./messagerecived.css";
import { MdOutlineReplyAll } from "react-icons/md";
import SimpleMessageRecieved from "./ChatMessageRecievedFolder/SimpleMessageRecieved/SimpleMessageRecieved";
import PdfMessageRecieved from "./ChatMessageRecievedFolder/PdfRecieved/PdfMessageRecieved";
import ReplyofSimpleMessage from "./ChatMessageRecievedFolder/SimpleMessageRecieved/ReplyofSimpleMessage";
import ImageMessageRecieved from "./ChatMessageRecievedFolder/ImagesRecieved/ImageMessageRecieved";
import ImageWithSpan from "./ChatMessageRecievedFolder/ImagesRecieved/ImageWithSpan";
import PdfWithSpanRecieved from "./ChatMessageRecievedFolder/PdfRecieved/PdfWithSpanRecieved";
import ImageReply from "./ChatMessageRecievedFolder/ImagesRecieved/ImageReply";
import moment from "moment";
function MessageRecieved({
  setChatreply,
  replymsgRef,
  forwardtoChatReplymsg,
  message,
  setreplymsg,
  isSameUser
}) {
  return (
    <div className="messagerecievediv" 
    ref={ref => replymsgRef.current[message._id] = ref}
    >
      {
        isSameUser  === true ?    <div
        className="namelogo"
        //  style={{ background: `${color}` }}
        style={{ background: "red" }}
      >
         {message?.sendFrom?.name?.charAt(0)}
      </div>
      : <div
      className="namelogo"
    >
  
    </div>
      }
    

      <div className="msgdiv">
       { isSameUser && <div className="usernametime">
          {" "}
          {message.sendFrom.name} ({message.sendFrom.profession}){" "}
          <span>
            {" "}
            {moment(message?.createdAt).format("DD/MM/YYYY hh:mm:ss")}
          </span>{" "}
        </div>}

        {message?.replyOf == null ? (
          message?.messageData?.doc === null ? (
            <>
              {/* simple text Message received */}
              <SimpleMessageRecieved
                message={message}
                setChatreply={setChatreply}
                setreplymsg={setreplymsg}
              />
            </>
          ) : message?.messageData?.description &&
            message?.messageData?.description != "" ? (
            <>
              {/* file with message */}
              {/* {console.log(
                message.messageData.doc,
                message.messageData.doc.split(".")[
                  message.messageData.doc.split(".").length - 1
                ],
                "message with doc"
              )} */}
              {message?.messageData?.doc?.split(".")[
                message?.messageData?.doc?.split(".")?.length - 1
              ] === "pdf" ? (
                <>
                  {/* pdf file with message */}
                  <PdfWithSpanRecieved
                    message={message}
                    type="receiveOnlyPdfWithMessage"
                    setChatreply={setChatreply}
                    setreplymsg={setreplymsg}
                  />
                </>
              ) : (
                <>
                  {/* image file with message */}
                  <ImageWithSpan
                    message={message}
                    setChatreply={setChatreply}
                    setreplymsg={setreplymsg}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {/*  only file */}
              {message?.messageData?.doc?.split(".")[
                message?.messageData?.doc?.split(".")?.length - 1
              ] === "pdf" ? (
                <>
                  {/* only pdf file */}

                  <PdfMessageRecieved
                    setreplymsg={setreplymsg}
                    message={message}
                    setChatreply={setChatreply}
                  />
                </>
              ) : (
                <>
                  {/* only image file */}

                  <ImageMessageRecieved
                    message={message}
                    setChatreply={setChatreply}
                    setreplymsg={setreplymsg}
                  />
                </>
              )}
            </>
          )
        ) : message?.replyOf?.messageData?.doc === null ? (
          <>
            {/* simple text Message send */}
            <ReplyofSimpleMessage
              setChatreply={setChatreply}
              forwardtoChatReplymsg={forwardtoChatReplymsg}
              message={message}
              setreplymsg={setreplymsg}
            />
          </>
        ) : message?.replyOf?.messageData?.description &&
          message?.replyOf?.messageData?.description != "" ? (
          <>
            {/* file with message */}
            {message.replyOf.messageData.doc.split(".")[
              message.replyOf.messageData.doc.split(".").length - 1
            ] === "pdf" ? (
              <>
                {/* pdf file with message */}
                <PdfWithSpanRecieved
                  setChatreply={setChatreply}
                  type="replyPdfWithMessage"
                  message={message}
                  setreplymsg={setreplymsg}
                />
              </>
            ) : (
              <>
                {/* image file with message */}
                <ImageReply
                  setChatreply={setChatreply}
                  type="imageWithMessage"
                  message={message}
                  setreplymsg={setreplymsg}
                />
              </>
            )}
          </>
        ) : (
          <>
            {/*  only file */}
            {message.replyOf.messageData.doc.split(".")[
              message.replyOf.messageData.doc.split(".").length - 1
            ] === "pdf" ? (
              <>
                {/* only pdf file */}
                <PdfWithSpanRecieved
                  type="pdf"
                  setChatreply={setChatreply}
                  setreplymsg={setreplymsg}
                  message={message}
                />
              </>
            ) : (
              <>
                {/* only image file */}
                <ImageReply
                  setChatreply={setChatreply}
                  type="image"
                  message={message}
                  setreplymsg={setreplymsg}
                />
              </>
            )}
          </>
        )}

        {/* use as recieved simple text message */}
        {/* <SimpleMessageRecieved setChatreply={setChatreply} /> */}

        {/* message recieved as simple pdf */}
        {/* <PdfMessageRecieved setChatreply={setChatreply} /> */}

        {/* simple Image Message Recieved */}
        {/* <ImageMessageRecieved setChatreply={setChatreply} /> */}

        {/* Image With span message RECIEVED */}
        {/* <ImageWithSpan setChatreply={setChatreply} /> */}

        {/* PDF message recieved it has three condition it can be also reply of a pdf and pdf with span and reply of a simple pdf */}
        {/* <PdfWithSpanRecieved setChatreply={setChatreply} /> */}

        {/* Reply of a simple text message */}
        {/* <ReplyofSimpleMessage setChatreply={setChatreply} /> */}

        {/* Reply of a image  */}
        {/* <ImageReply setChatreply={setChatreply} /> */}
      </div>
    </div>
  );
}

export default MessageRecieved;
