import React, { useState } from 'react'
import './reportpopover.css'
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import sequencelogo from '../../assest/Image/sequencelogo.png'
import { GoKebabHorizontal } from "react-icons/go";
import { sizeHeight } from '@mui/system';
import { FaPlus } from "react-icons/fa"
import QueryOption from './QueryOption';
export default function Query({ openreportpopover,setOpenreportpopover,openreportpopoveropen,openreportpopoverid}) {


    const [subQuerypopup, setSubQuerypopup] = useState(null)
    const Querydata = ['Status Report', 'CD Report', 'RFI Report']

    const handleClose = () =>{
        setOpenreportpopover(false)
    }
    const handleclick = (event) => {
        setSubQuerypopup(event.currentTarget)
    }

    const SubQueryopen = Boolean(subQuerypopup);
    const SubQueryid = SubQueryopen ? 'simple-popover' : undefined;
    return (
        <Popover
            id={openreportpopoverid}
            style={{ boxShadow: "none" }}
            open={openreportpopoveropen}
            anchorEl={openreportpopover}
            onClose={handleClose}
            sx={{
                borderColor: "#194895",
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Typography component={'div'}
                className='PoPup1Sub'
                sx={{
                    padding: ".7rem",
                    background: "#194895",
                    borderRadius: "20px",
                }}
            >
                {/* <div className='queryheading'><img src={sequencelogo} style={{ height: "18px" }} /><span style={{ color: "white" }}>Query List</span></div> */}
                <div className='reportlistofdata'>
                    <ul className='reportul'>
                        {Querydata.map((data, index) => {
                            return (
                                <div className='reportinnerlist'>
                                    <div className='reportchatreddots'></div>
                                    <div className='reportdataname'> {data}</div>
                                </div >
                            )
                        })}
                    </ul>
                    {/* <div style={{fontSize : '14px', paddingLeft :'.7rem'}}><FaPlus/> Add New Query</div> */}
                </div>
            </Typography>
            <QueryOption subQuerypopup={subQuerypopup} SubQueryopen={SubQueryopen} SubQueryid={SubQueryid} setSubQuerypopup={setSubQuerypopup} />
        </Popover>
    );
}