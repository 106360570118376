import React, { useEffect, useRef, useState } from "react";
import "./adduser.css";
import calling from "../../assest/Image/calling.png";
import companyimg from "../../assest/Image/companyimg.png";
import { Multiselect } from "multiselect-react-dropdown";

function Adduser({ data, selectedData, image, text }) {
  const selectedRef = useRef();

  // const showdata = [
  //   { name: "sokle" },
  //   { name: "digvijay" },
  //   { name: "akhil" },
  //   { name: "sandeep" },
  //   { name: "ajay" },
  //   { name: "garv" },
  //   { name: "nadeem" },
  //   { name: "aman" },
  // ];

  const onSelect = async (item) => {
    selectedData([...item]);
  };

  const handlekeypress = (data) => {};

  const onRemove = (item) => {
    selectedData([...item]);
  };
  const style = {
    multiselectContainer: {
      height: "5vh",
      background: "#F0F0F0",
    },
    // searchBox: {
    //   border: "none",
    //   placeholder: "Add more",
    //   width: "23vw",
    //   overflowX:"scroll",
    //   whiteSpace:"nowrap",
    //   fontSize: "10px",
    //   minHeight: "50px",
    // },

    chips: {
      background: "#194895",
    },
    inputField: {
      margin: "5px",
    },
  };



  return (
    <div className="adduserdropdown">
      <img src={image} />
      {
        <Multiselect
          ref={selectedRef}
          options={data}
          className="addusermultiselector"
          displayValue="data"
          emptyRecordMsg={"No More Data Available"}
          style={style}
          onKeyPressFn={(event) => handlekeypress(event)}
          avoidHighlightFirstOption={true}
          caseSensitiveSearch={false}
          placeholder={text}
          onRemove={onRemove}
          onSelect={onSelect}
        />
      }
    </div>
  );
}

export default Adduser;
