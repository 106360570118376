import * as React from "react";
import Box from "@mui/material/Box";
import "./deletequery.css";
import Modal from "@mui/material/Modal";
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";
// import './Inviteuser.css'
import { FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { handle_delete_query } from "../../Functions/projectsFunction";
import {
  get_project_data_actionCreater,
  get_sequence_of_project_actionCreater,
} from "../../Redux/projects/CreateActions";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "50%",
  border: "none",
  outline: "none",
};

export default function DeleteQuery({
  opendeletequerynamemodal,
  setOpendeletequerynamemodal,
  selectedQuery,
  setAnchorEl,
}) {
  const handleClose = () => {
    setOpendeletequerynamemodal(false);
  };

  const router = useSelector((state) => {
    return state.router;
  });
  const dispatch = useDispatch();

  return (
    <div>
      <Modal
        open={opendeletequerynamemodal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="deletequerymodal">
            <FaTrashAlt className="deletequerytrash" />
            <div className="deletequeryspan1">Delete 01 Query</div>
            <div className="deletequeryspan2">
              Deleting 01 Query will delete all data. Are you sure you want to
              delete?
            </div>
            <div className="querydeletebtns">
              <div
                className="querydeletebtncancle"
                style={{
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                cancel
              </div>
              <div
                className="querydeletebtndelete"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  handle_delete_query({
                    dispatch,
                    handleClose,
                    finalData: {
                      project: selectedQuery.project,
                      query_id: selectedQuery._id,
                    },
                  });
                  setAnchorEl(null);

                  // get_sequence_of_project_actionCreater({
                  //   dispatch,
                  //   project_id: selectedQuery.project,
                  // });
                  // get_project_data_actionCreater({
                  //   dispatch,
                  //   projectData: {
                  //     _id: selectedQuery.project,
                  //   },
                  //   router,
                  // });
                }}
              >
                Delete
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
