import React, { useState } from "react";
import "./addnewquery.css";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import chatinfopic from "../../assest/Image/chatinfopic.png";
import upload from "../../assest/Image/upload.png";
import name from "../../assest/Image/name.png";
import { FiX } from "react-icons/fi";
import { handle_add_query } from "../../Functions/projectsFunction";
import { useDispatch } from "react-redux";
const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "50%",
  border: "none",
  outline: "none",
};

function Addnewquery({
  addnewquerymodal,
  setAddnewquerymodal,
  projectData,
  selectedSequence,
  setAnchorEl,
}) {
  const [finalData, setFinalData] = useState({
    project: projectData._id,
    sequence: selectedSequence,
    queryTitle: "",
    document: null,
    description: "",
    queryUsers: [],
  });
  const [filename, setFileName] = useState("");
  const dispatch = useDispatch();

  const handleChange = ({ data, field }) => {
    switch (field) {
      case "queryTitle":
      case "description":
        setFinalData({ ...finalData, [`${field}`]: data.target.value });
        break;
      case "document":
        setFileName(data.target.files[0].name);
        setFinalData({ ...finalData, [`${field}`]: data.target.files });
        break;
      case "queryUsers":
        let obj = finalData.queryUsers.find((e) => {
          return e.user.email == data.user.email;
        });
        let userList = [];
        if (obj) {
          userList = finalData.queryUsers.filter((e) => {
            return e.user.email != data.user.email;
          });
        } else {
          userList = [...finalData.queryUsers, data];
        }
        setFinalData({ ...finalData, [`${field}`]: userList });
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setAddnewquerymodal(false);
  };

  return (
    <Modal
      open={addnewquerymodal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="inviteusermaindiv">
          <div className="addnewqueryheader">
            <span>Add New Query</span>
            <div
              className="addnewquerycrosss"
              style={{
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <FiX />
            </div>
          </div>

          <div className="addnewquerybody">
            <div className="addnewqueryname">
              {" "}
              <img src={chatinfopic} />
              <input
                placeholder="Enter Query Name"
                className="newqueryinput"
                onChange={(e) => {
                  handleChange({
                    data: e,
                    field: "queryTitle",
                  });
                }}
              />
            </div>

            <div className="uploadtextinquery">
              <input
                className="uploadtextinput"
                placeholder="Upload Text"
                onChange={(e) => {
                  handleChange({
                    data: e,
                    field: "description",
                  });
                }}
              />
            </div>
            <div className="inputfileupload">
              {filename ? (
                " "
              ) : (
                <input
                  type="file"
                  className="uploadfileinput"
                  onChange={(e) => {
                    handleChange({
                      data: e,
                      field: "document",
                    });
                  }}
                />
              )}

              <div className="uploadtextspan">
                {filename ? (
                  <div className="uploadtextspanmini">
                    <div className="uploadspan">{filename}</div>
                    <FiX
                      className="cancleimagelogo"
                      onClick={() => setFileName("")}
                    />
                  </div>
                ) : (
                  <div
                    className="uploadtextspanmini"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <img src={upload} />
                    <div className="uploadspan">Upload Document</div>
                  </div>
                )}

                {/* {
                  filename ? <div className="uploadedfilename">
                  <div>{filename}</div>
               
                  <FiX onClick={()=>setFileName("")}/>
                </div>:""
                } */}
              </div>
            </div>

            <div className="adduserinquerydiv">
              <img src={name} />
              <div>Add user in Query</div>
            </div>

            <div className="userslistinquery">
              {projectData?.projectUsers !== null
                ? projectData?.projectUsers.map((value, idx) => {
                    return (
                      <div className="showusernameandcheckbox" key={idx}>
                        <div className="addnewusernameinquery">
                          {value.user.name}
                        </div>
                        <input
                          type="checkbox"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleChange({
                              data: value,
                              field: "queryUsers",
                            });
                          }}
                        />
                      </div>
                    );
                  })
                : null}
              {/* </div> */}
            </div>
            <div
              className="createnewquerybtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                handle_add_query({
                  finalData,
                  dispatch,
                  handleClose,
                  setAnchorEl,
                });
              }}
            >
              Submit
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default Addnewquery;
