import React, { useState } from 'react'
import users from '../../assest/Image/users.png'
import uploadfilepic from '../../assest/Image/uploadfilepic.png'
import chatinfopic from '../../assest/Image/chatinfopic.png'
import totalsequence from '../../assest/Image/totalsequence.png'
import sequencelogoblue from '../../assest/Image/sequencelogoblue.png'
// import addblueicon from '../../assest/Image/addblueicon.png'
import addblueicon from '../../assest/Image/addblueicon.png'
// import sequencelogoblue from '../../assest/Image/sequencelogoblue.png'
import { BiChevronRight,BiArrowBack,BiX } from "react-icons/bi";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import fileupload from '../../assest/Image/fileupload.png'
import { IoIosArrowDown } from "react-icons/io";
// import { IoIosArrowDown } from "react-icons/io";
import memory from '../../assest/Image/memory.png'
import Removefile from './Removefile'
import Addfile from './Addfile'
function StorageDescription({ isupload, setIsupload }) {

    const[openaddfile,setOpenaddfile] = useState(false)
    const handleshowfile = (id) =>{
        setshowsequencefile(id)
    }

    const [showsequencefile, setshowsequencefile] = useState(null)
    const [deletefile,setDeletefile] =  useState(false)
    const sequencename = [
        {
            name:"ajay1st Sequence",
            id:"hii ajay"
        },
        {
            name:"ajay1st Sequence",
            id:"hiisokle"
        },
        {
            name:"ajay1st Sequence",
            id:"hiirajiv"
        },
        {
            name:"ajay1st Sequence",
            id:"hiiakhil"
        },
        {
            name:"ajay1st Sequence",
            id:"hiidigvijay"
        }
    ]

    const filename = [
        {
            name:"file1st/document"
        },
        {
            name:"file/document2"
        },
        {
            name:"file1st/document3"
        },
        {
            name:"file1st/document4"
        },
        {
            name:"file1st/document5"
        },
         {
            name:"file1st/document6"
        },
    ]
    return (
        <div className='descriptionmaindiv'>
            <div className='descriptionheader'>
                <div className='descriptionprojectname'>
                    {
                        isupload === true ?   <BiArrowBack style={{marginRight:"3%"}} onClick={()=>setIsupload(false)}/>:null
                    }
                 
                    <div>Project Name</div>
                </div>
                <div className='descriptionprojectuser'>
                    <img src={users} />
                    <div>5 users</div>
                </div>
            </div>

            {isupload === true ?
                <div className='uploadscreen'>
                    <img src={uploadfilepic} />
                    <div className='uploadscreenspan'> Upload some data here </div>
                    <input type='file' accept='image/*' className='storageinputupload'/>
                    <div className='uploadbtn'>
                        Upload
                    </div>
                </div>
                : <>
                    <div className='projectsquarestorage'>
                        <div className='projectclosequerysquarestorage'>
                            <div className='imagediv'>
                                <img src={totalsequence} />
                            </div>
                            <div className='optionspans'>
                                <div className='total'>
                                    4
                                </div>
                                <div className='projectsquarespans'>
                                    Total Files
                                </div>
                            </div>
                        </div>
                        <div className='projectclosequerysquarestorage'>
                            <div className='imagediv'>
                                <img src={memory} />
                            </div>
                            <div className='optionspans'>
                                <div className='total'>
                                    1 TB
                                </div>
                                <div className='projectsquarespans'>
                                    Total Space
                                </div>
                            </div>
                        </div>
                        <div className='projectclosequerysquarestorage'>
                            <div className='imagediv'>
                                <img src={memory} />
                            </div>
                            <div className='optionspans'>
                                <div className='totalfree'>
                                    999 GB
                                </div>
                                <div className='projectsquarespans'>
                                    Free Space
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='upgradeplan'>
                        <div className='upgradeprojectstorage'>
                            <div className='imagedivstorage'>
                                <img src={totalsequence} />
                            </div>
                            <div className='optionspans'>
                                <div className='total'>
                                    4
                                </div>
                                <div className='projectsquarespans'>
                                    Uploaded Files
                                </div>
                            </div>
                        </div>
                        <div className='upgradeplanbtn'>Upgrade Your Plan</div>
                        {/* <div style={{ marginRight: '4%' }} >
                            <IoIosArrowDown />
                        </div> */}
                    </div>
                    <div className='projectStoragefilemaindiv'>
                    <div className='projectStoragefile'>

                {
    sequencename.map((element,index)=>(
<div className='sequnecestoragelist'>

        <div className='listofsequencename'>
    <div className='fileupload'><img src={sequencelogoblue} /></div>
    <div className='name'>{element.name}</div>
    {
        showsequencefile === element.id ? 
        <div style={{color:"black",marginLeft:"5%"}} onClick={()=> setshowsequencefile(null)}>  <FaAngleUp style={{color:"black"}}/></div>
        :     <div style={{color:"black",marginLeft:"5%"}} onClick={()=>handleshowfile(element.id)}>  <FaAngleDown style={{color:"black"}}/></div>
    }

</div>
   
{
    showsequencefile === element.id ? 
    filename.map((element)=>(
        <div>
    <div className='projectlistuser'>
    <div className='listofadminandsimpleuser'>
        <div className='fileupload'><img src={fileupload} /></div>
        <div className='userlistname'>{element.name}</div>
    </div>
    <div className='chatinfouserbtn' onClick={()=>setDeletefile(true)}>Remove File</div>
</div> 
</div>
    ))
    
: null
}
</div>
))
}
                       
  </div>
    <div className='addmorefilebtn' onClick={()=>setOpenaddfile(true)}><img src={addblueicon}/><div style={{marginLeft:"2%"}}>Add More File</div></div>    
     </div>
                </>
            }
<Removefile deletefile={deletefile}setDeletefile={setDeletefile}/>
<Addfile openaddfile={openaddfile} setOpenaddfile={setOpenaddfile}/>
        </div>
    )
}

export default StorageDescription









// {
//     sequencename.map((element,index)=>(
// <div className='projectlistuser'>

//         <div className='listofadminandsimpleuser'>
//     <div className='fileupload'><img src={sequencelogoblue} /></div>
//     <div className='userlistname'>{element.name}</div>
//     <div style={{color:"black",marginLeft:"5%"}} onClick={()=>setshowsequencefile(!showsequencefile)}>  <IoIosArrowDown style={{color:"black"}}/></div>

// </div>
   
// {
//     showsequencefile === true ? 
//     <div>
//     <div className='projectlistuser'>
//     <div className='listofadminandsimpleuser'>
//         <div className='fileupload'><img src={fileupload} /></div>
//         <div className='userlistname'>File/Document1</div>
//     </div>
//     <div className='chatinfouserbtn' onClick={()=>setDeletefile(true)}>Remove File</div>
// </div> 
// </div>
// : null
// }
// </div>
// ))
// }