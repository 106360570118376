import React, { useState } from 'react'
import './Verification.css'
import Logo from '../../assest/Image/Logo.png'
import Building from '../../assest/Image/Building.png'
import axios from 'axios';
import OTPInput, { ResendOTP } from "otp-input-react";
import verifyfinal from '../../assest/Image/verifyfinal.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import BuildB from "../../assest/Image/BuildB.png";
function Verification() {
    const navigate = useNavigate();
    const location = useLocation();
    const useremail = location.state.email
    const [inputboxborder, setInputboxborder] = useState(true)
    const [otperror, setOtperror] = useState("")
    // otp resend btn 

    const [counter, setCounter] = useState(20)
    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer)
    }, [counter])



    // click on resend btn

    // error handle

    useEffect(() => {
        setTimeout( ()=> {
            setOtperror(false);
        }, 5000);
      }, [otperror]);
    // end error handle
    const handleresendbtn = async () => {
        try {
            const response = await axios.post(`${process.env.React_App_prod_url}/user/generateOtp`, {
                email: location.state.email
            })
            setCounter(20)
            if (response.status === 200) {
                setOtperror(response.data.msg)
            }
        }
        catch (err) {
            console.log(err, 'err');
        }
    }

    // otp resend btn end here 
    const handleOTPverification = async () => {
        try {
            const response = await axios.post(`${process.env.React_App_prod_url}/user/otpverify`, {
                email: location.state.email,
                otp: OTP
            })
            if (response) {
                navigate('/newpassword', {
                    state: {
                        email: useremail
                    },
                })
            }
        }
        catch (err) {
            setOtperror(err.response.data.msg);
            setInputboxborder(false)
            console.log(err, 'err');
        }

    }
    const [OTP, setOTP] = useState("");
    return (
        <>
            <div className="row p-0 m-0">
                <div className="col-md-7 col-12 p-0">
                    <div className='Logindivverify'>

                        <div className='smalldivverify'>
                            <img src={BuildB} className='imageverify' />
                            <h3 className='h2designverifyverify'>Verification </h3>
                            <div className='otpmsgverify'>Please enter OTP send on your Email ID </div>
                          
                            <OTPInput className={inputboxborder === true ? 'verifyotpverify' : 'verifyotpverifyred'} value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} />
                            {
                                counter === 0 &&

                                <div className='resendverify1'>Didn't recieve an otp?
                                    <span className='ms-2 text-primary ' onClick={handleresendbtn}>Resend</span>   </div>
                            }
                            <button className='loginbtnverify1' onClick={handleOTPverification}><span style={{ color: 'white', fontSize: "large" }}>Verify</span></button>
                            {
                                counter !== 0 && <div className='counter'> 00:{counter} </div>
                            }
                            {
                                otperror ? <div className='otperr'>{otperror}</div> : null
                            }
                        </div>
                    </div>
                </div>
              
                <div className="col-md-5 col-12 p-0">
                    <div className='Lineargradientdivverify'>
                        <div className='lineargradientminidivverify'>

                            <img src={verifyfinal} className='verifyimage1' />

                            <img src={Building} className='building' />
                        </div>
                    </div>

                </div>
            </div>
        </>


    )
}

export default Verification