import * as React from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
// import './Inviteuser.css'
import { FiX } from "react-icons/fi";
import { useState } from "react";
import {
  get_query_of_sequence,
  handle_Edit_query,
} from "../../Functions/projectsFunction";
import { useDispatch } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "34%",
  border: "none",
  outline: "none",
};

export default function EditQuerymodal({
  openeditquerynamemodal,
  setOpeneditquerynamemodal,
  selectedQuery,
  setAnchorEl,
}) {
  const handleClose = () => setOpeneditquerynamemodal(false);
  const dispatch = useDispatch();
  const [queryName, setQueryName] = useState("");

  return (
    <div>
      <Modal
        open={openeditquerynamemodal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="inviteusermaindiv">
            <div className="inviteuserheader">
              <span>Edit Query Name</span>
              <div
                className="inviteusercrosss"
                style={{
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <FiX />
              </div>
            </div>

            <div className="inviteuserbody">
              <input
                type="text"
                placeholder="Enter Name"
                onChange={async (e) => {
                  setQueryName(e.target.value);
                }}
              />

              <div className="modalsavebtn">
                <span
                  onClick={() => {
                    handle_Edit_query({
                      dispatch,
                      handleClose,
                      finalData: {
                        project: selectedQuery.project,
                        sequence: selectedQuery.sequence,
                        query_id: selectedQuery._id,
                        queryTitle: queryName,
                      },
                      setAnchorEl,
                    });
                  }}
                >
                  save
                </span>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
