import React from "react";
import "../recivedmessages.css";
import { MdOutlineReplyAll } from "react-icons/md";
function SimpleMessageRecieved({ setChatreply, message, setreplymsg }) {
  return (
    <div className="msgandreplydiv">
      <div
        className="recievedmsgdiv"
        //   style={{ background: `${color}`, margin: "0%" }}
        style={{ background: "blue" }}
      >
        {/* {data?.messageData?.description} */}
        {message?.messageData?.description}
      </div>
      <div className="chatreplydiv" onClick={() => setChatreply(true)}>
        <MdOutlineReplyAll
          onClick={() => {
            setreplymsg({
              name: message.sendFrom.name,
              profession: message.sendFrom.profession,
              color: null,
              message: message.messageData.description,
              message_id: message._id,
            });
          }}
        />
      </div>
    </div>
  );
}

export default SimpleMessageRecieved;
