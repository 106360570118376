import * as React from "react";
import Box from "@mui/material/Box";
import "./removefile.css";
import Modal from "@mui/material/Modal";
import moveto from "../../assest/Image/moveto.png";
import { FiX } from "react-icons/fi";
const style = {
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  height: "50%",
  border: "none",
  outline: "none",
};

export default function Removefile({
    deletefile,setDeletefile
}) {
  const handleClose = () => setDeletefile(false);

  return (
    <div>
      <Modal
        open={deletefile}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className="inviteusermaindiv">
            <div className="inviteuserheader">
              <span>Remove File</span>
              <div
                className="inviteusercrosss"
                style={{
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <FiX />
              </div>
            </div>
          <div
            className="removefilediv"
     
          >

            <div className="removefilespan">
              Are you sure you want to remove this file permanently ?
            </div>
            <div className="removefilebtn">
              <div className="filedeletecancle" onClick={handleClose}>
                cancel
              </div>
              <div
                className="filedeleteconfrom"
              >
                Confirm
              </div>
            </div>
          </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
