import { SELECT_PROJECT_DATA } from "../constants";

export default function SelectProjectReducer(state = {}, action) {
  switch (action.type) {
    case SELECT_PROJECT_DATA:
      return {
        ...state,
        projectData: {
          ...action.payload.projectData,
          new: !state?.projectData?.new,
        },
        externalInfo: { ...action.payload.externalInfo },
      };

    default:
      return state;
  }
}
