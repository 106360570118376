import React, { useState, useRef } from "react";
import "./Header.css";
import Notificationimg from "../../assest/Image/Notificationimg.png";
import { FiSearch } from "react-icons/fi";
import { FiArrowRight } from "react-icons/fi";
import Profile from "../../assest/Image/Profile.png";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Changepassword from "../Changepassword/Changepassword";
import InviteUser from "./InviteUser";
import Showminiprofile from "./Showminiprofile";
import Notification from "./Notification";
import { useEffect } from "react";
import axios from "axios";
import {
  get_user_data,
  get_user_notifications,
} from "../../Functions/headerFunction";
import Contactus from "../contactus/Contactus";
import { Badge } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

function Header({ isEmailVerify }) {
  const [openModal, setOpenmodal] = useState(false);
  const [openchangepasswordmodal, setOpenchangepasswordmodal] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showcontactusmadal, setShowcontactusmodal] = useState(false);
  const dispatch = useDispatch();
  // modal open
  const handleOpen = () => {
    setOpenmodal(true);
  };

  const notifications = useSelector((state) => {
    return state.notification;
  });
  useEffect(() => {
    get_user_notifications({ dispatch });
  }, []);
  // end here

  // use effect for close profile from outside

  const profileref = useRef();
  //  useEffect(()=>{
  //   let handleprofile = (e) =>{
  //     if(!profileref.current.contains(e.target)){
  //       setShowProfile(false)
  //     }
  //     else{
  //       setShowProfile(true)
  //     }
  //   }
  //   document.addEventListener("click",handleprofile)
  //   return () =>{
  //     document.removeaddEventListener("click",handleprofile)
  //   }
  //     },[])

  // use effect for close profile from outside  end here

  // open notification modal

  const [shownotification, setShownotification] = useState(false);

  // open notiifcation modal end here
  // const [showprofilesetting, setShowprofilesetting] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const handlenotofication = () => {
    setShownotification(!shownotification);
  };
  const handleprofilepopup = (event) => {
    // setShowProfile(event.currentTarget);
    setShowProfile(!showProfile);
  };

  const navigate = useNavigate();

  const headeropen = Boolean(showProfile);
  const headerid = headeropen ? "simple-popover" : undefined;

  /// by sandeep

  useEffect(() => {
    let token = window.localStorage.getItem("token");
    if (token) {
      get_user_data({ token, setUserData });
    }
  }, []);


  return (
    <>
      <div
        className={
          isEmailVerify === true ? "homeheader " : "isEmailverifyhomeheader"
        }
      >
        <div className="homeheaderspan">
          Welcome, {userData ? userData.userName : ""}!
        </div>
        <div className="homeheadersearch">
          <FiSearch className="homeheadersearchicon" />
          <input
            className="homeheadersearchinput"
            placeholder="Search here..."
            type="text"
          />
          <FiArrowRight className="homeheaderarrow" />
        </div>
        <div className="homeheaderadduser" onClick={handleOpen}>
          {" "}
          <span> + Invite User</span>
        </div>
        <div className="homeheadernotification" onClick={handlenotofication}>
          <Badge
            badgeContent={notifications ? notifications.unreadCount : 0}
            color="primary"
          >
          {/* <img
              src={Notificationimg}
              className="homeheadernotificationimage"
            /> */}
          {/* <script src="https://cdn.lordicon.com/bhenfmcm.js"></script> */}
          {/* <lord-icon
            src="https://cdn.lordicon.com/psnhyobz.json"
            trigger="hover"
            style="width:700px;height:700px">
          </lord-icon> */}
          <lord-icon
                className="notification_icon"
                src="https://cdn.lordicon.com/kjsfgazx.json"
                trigger="hover"
                colors="primary:#f7c028"
                style={{
                  width: '29px',
                  height: '29px',
                  cursor: 'pointer',
                  // marginLeft : '40px',
                  marginTop : '5px'
                }}>
              </lord-icon>
          </Badge>
        </div>  
        <div
          className="homeheaderprofileimage d-flex align-items-center justify-content-center"
          onClick={(e) => handleprofilepopup(e)}
        >
          <img src={Profile} />
          <IoIosArrowDown
            ref={profileref}
            style={{ color: "black ", fontSize: "1.4rem" }}
          />
        </div>
      </div>
      {showProfile === true ? (
        <Showminiprofile
          showProfile={showProfile}
          setShowProfile={setShowProfile}
          openchangepasswordmodal={openchangepasswordmodal}
          setOpenchangepasswordmodal={setOpenchangepasswordmodal}
          showcontactusmadal={showcontactusmadal}
          setShowcontactusmodal={setShowcontactusmodal}
          userData={userData}
        />
      ) : null}
      {shownotification === true ? (
        <Notification
          shownotification={shownotification}
          setShownotification={setShownotification}
        />
      ) : null}

      <InviteUser openModal={openModal} setOpenmodal={setOpenmodal} />

      <Changepassword
        openchangepasswordmodal={openchangepasswordmodal}
        setOpenchangepasswordmodal={setOpenchangepasswordmodal}
      />
      <Contactus
        showcontactusmadal={showcontactusmadal}
        setShowcontactusmodal={setShowcontactusmodal}
      />
    </>
  );
}

export default Header;
