import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import "./movequery.css";
import Movequerymodal from "./Movequerymodal";
import moveto from "../../assest/Image/moveto.png";
import { useSelector } from "react-redux";
function Movequery({
  showmovequery,
  setShowmovequery,
  movequeryid,
  movequeryopen,
  selectedQuery,
  setAnchorEl,
  old_sequence_id,
}) {
  // move  query modal open
  const [openmovequerymodal, setOpenmovequerymodal] = useState(false);
  const [selected_sequence, set_selected_sequence] = useState(null);
  const sequence = useSelector((state) => state.projects.sequence);
  // end of MoVE query MODAL
  const handleClose = () => {
    setShowmovequery(null);
  };

  const handleMovequery = (e, value) => {
    setOpenmovequerymodal(true);
    set_selected_sequence(value);
    // setShowmovequery(null)
  };
  return (
    <Popover
      id={movequeryid}
      open={movequeryopen}
      anchorEl={showmovequery}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {/* <Typography
                component={'div'}
                className='SubQueryOptions'
            > */}
      <div className="movequerymaindiv">
        <div className="moveto">
          <img src={moveto} className="moveicon" />
          Move To
        </div>
        <hr className="horizontalline"></hr>
        <div className="scrollmove">
          {sequence !== null
            ? sequence
                .filter((e) => e._id != old_sequence_id)
                .map((value, idx) => {
                  return (
                    <>
                      <div
                        className="movequerytosequencediv"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <div className="movequerydots"></div>
                        <div
                          className="movetosequencename"
                          onClick={(e) => {
                            handleMovequery(e, value);
                          }}
                        >
                          {value.sequenceName}
                        </div>
                      </div>
                      <hr className="horizontalline"></hr>
                    </>
                  );
                })
            : null}
        </div>
        <div className="blankdiv"></div>
      </div>
      {/* </Typography> */}
      {selected_sequence && openmovequerymodal && selectedQuery ? (
        <Movequerymodal
          openmovequerymodal={openmovequerymodal}
          setOpenmovequerymodal={setOpenmovequerymodal}
          selectedQuery={selectedQuery}
          setAnchorEl={setAnchorEl}
          selected_sequence={selected_sequence}
        />
      ) : null}
    </Popover>
  );
}

export default Movequery;
