import { SELECT_CHAT, SEND_MESSAGE } from "../constants";

export default function ChatDataReducer(state = [], action) {
  switch (action.type) {
    case SELECT_CHAT:
      return [...action.payload];
    case SEND_MESSAGE:
      return [...state, action.payload];
    default:
      return state;
  }
}
