import axios from "axios";
import {
  edit_project_sequence_Action,
  get_projects_Action,
  select_query_of_sequence_action,
  set_project_data_Action,
} from "./Actions";
import { closeLoaderAction, startLoaderAction } from "../loader/Actions";

export const get_all_projects_actionCreater = async ({
  oldUserData_id,
  dispatch,
  router,
}) => {
  try {
    dispatch(startLoaderAction());

    const token = window.localStorage.getItem("token");

    let project = await axios.get(
      `${process.env.React_App_prod_url}/project/my_project_list`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (router && router == "live_project") {
      project.data.myProjects = project.data.myProjects.filter(
        (ele) => ele.projectStatus
      );
      project.data.my_all_project_list =
        project.data.my_all_project_list.filter((ele) => ele.projectStatus);
      project.data.sharedWithMe = project.data.sharedWithMe.filter(
        (ele) => ele.projectStatus
      );
    } else if (router && router == "closeproject") {
      project.data.myProjects = project.data.myProjects.filter(
        (ele) => !ele.projectStatus
      );
      project.data.my_all_project_list =
        project.data.my_all_project_list.filter((ele) => !ele.projectStatus);
      project.data.sharedWithMe = project.data.sharedWithMe.filter(
        (ele) => !ele.projectStatus
      );
    }

    // count project
    // console.log(project.data.my_all_project_list[0]);
    let projectCount = 0;
    if (oldUserData_id)
      project.data.my_all_project_list.map((ele) => {
        if (ele?.isNewRecord) {
        } else if (ele?.readBy?.includes(oldUserData_id)) {
        } else {
          console.log("count++");
          projectCount++;
        }

        // console.log({ele});
      });
    if (projectCount == 0) {
      document.title = `Build Intra`;
    } else {
      document.title = `Build Intra (${projectCount})`;
    }

    dispatch(get_projects_Action({ data: project.data }));
    dispatch(closeLoaderAction());
  } catch (err) {
    dispatch(closeLoaderAction());
    console.log({ err });
  }
};

// get sequence of project
export const get_sequence_of_project_actionCreater = async ({
  dispatch,
  project_id,
}) => {
  try {
    const token = window.localStorage.getItem("token");
    dispatch(startLoaderAction());
    let project_data = await axios.post(
      `${process.env.React_App_prod_url}/project/get_sequence_of_project`,
      {
        project: project_id,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    dispatch(closeLoaderAction());
    dispatch(
      edit_project_sequence_Action({ data: project_data.data.sequence })
    );
  } catch (err) {
    dispatch(closeLoaderAction());
    console.log({ err });
  }
};

export const get_project_data_actionCreater = async ({
  dispatch,
  projectData,
  selectedProject,
  router,
}) => {
  try {
    let id = projectData?._id || selectedProject;
    const token = window.localStorage.getItem("token");
    dispatch(startLoaderAction());
    let project_data = await axios.get(
      `${process.env.React_App_prod_url}/project/get_project_data/${id}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    dispatch(
      set_project_data_Action({
        data: {
          projectData: project_data.data.project,
          externalInfo: project_data.data,
        },
      })
    );

    dispatch(closeLoaderAction());
  } catch (err) {
    dispatch(closeLoaderAction());
    console.log({ err });
  }
};
