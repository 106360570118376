import axios from "axios";
import { select_chat_action, send_message_action } from "./Actions";
import { closeLoaderAction, startLoaderAction } from "../loader/Actions";

// get sequence of project
export const select_chat_actionCreater = async ({ dispatch, data }) => {
  try {
    const token = window.localStorage.getItem("token");
    dispatch(startLoaderAction());
    let chat_data = await axios.post(
      `${process.env.React_App_prod_url}/message/get_message`,
      {
        query: data._id,
        sequence: data.sequence,
        project: data.query,
        page: 1,
        limit_of_message: 100,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    // console.log({ chat_data: chat_data.data.messages });
    dispatch(select_chat_action({ data: chat_data.data.messages }));
    dispatch(closeLoaderAction());
  } catch (err) {
    dispatch(closeLoaderAction());
    console.log({ err });
  }
};

export const send_message_actionCreater = async ({ dispatch, message }) => {
  try {
    dispatch(send_message_action({ message }));
  } catch (err) {
    console.log({ err });
  }
};
