import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Project from "../Project/Project";
import SlideScreen1 from "../Slidescreen/SlideScreen1";
import UserAccess from "../UserAccess/UserAccess";
import Editprofile from "../Profile/Editprofile";
import Createproject from "../Addproject/Createproject";
import Adduserproject from "../Adduserproject.js/Adduserproject";
import Closeproject from "../closeproject/closeproject";
import Storage from "../Storage/Storage";
function DashboardRouting() {
  return (
    <Routes>
      <Route path="/" element={<SlideScreen1 />} />
      <Route path="/project" element={<Project />} />
      <Route path="/project/:id" element={<Project />} />
      <Route path="/project/:id/:query_id" element={<Project />} />
      <Route path="/add" element={<Createproject />} />
      <Route path="/useraccess" element={<UserAccess />} />
      <Route path="/editprofile" element={<Editprofile />} />
      <Route path="/adduser" element={<Adduserproject />} />
      <Route path="/closeproject" element={<Project />} />
      <Route path="/closeproject/:id" element={<Project />} />
      <Route path="/closeproject/:id/:query_id" element={<Project />} />
      <Route path="/live_project" element={<Project />} />
      <Route path="/live_project/:id" element={<Project />} />
      <Route path="/live_project/:id/:query_id" element={<Project />} />
      <Route path="/storage" element={<Storage />} />
    </Routes>
  );
}

export default DashboardRouting;
