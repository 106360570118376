import React from "react";
import { MdOutlineReplyAll } from "react-icons/md";
import "../sendmessage.css";
import createprojectimage from "../../../../assest/Image/createprojectimage.png";
function SendImageWithSpan({ setChatreply, message ,setreplymsg}) {
  return (
    <div className="sendimagewithspanmaindiv">
      <div className="selfchatreplydiv" onClick={() => setChatreply(true)}>
        <MdOutlineReplyAll
          onClick={() => {
            setreplymsg({
              name: message.sendFrom.name,
              profession: message.sendFrom.profession,
              color: null,
              message: message.messageData.description,
              message_id: message._id,
            });
          }}
        />
      </div>
      <div
        className="sendreplyofamessagemaindiv"
        // style={{ background: `${color}` }}
      >
        <div className="sendimagewithspan">
          <img src={message?.messageData?.doc} />
        </div>
        <div style={{ padding: "3%", fontSize: "13px" }}>
          {message?.messageData?.description}
        </div>
      </div>
    </div>
  );
}

export default SendImageWithSpan;
