export const GET_PROJECTS = "GET_PROJECTS";
export const EDIT_SEQUENCE = "EDIT_SEQUENCE";
export const START_LOADER = "START_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";
export const GET_USER_ACCESS_LIST = "GET_USER_ACCESS_LIST";
export const SELECT_QUERY_OF_SEQUENCE = "SELECT_QUERY_OF_SEQUENCE";
export const SELECT_PROJECT_DATA = "SELECT_PROJECT_DATA";
export const GET_SINGLE_USER_ACCESS_LIST = "GET_SINGLE_USER_ACCESS_LIST";
export const CHANGE_ROUTER = "CHANGE_ROUTER";
export const GET_USER_NOTIFICATIONS = "GET_USER_NOTIFICATIONS";
export const SELECT_QUERY_STATUS = "SELECT_QUERY_STATUS";
export const SELECT_CHAT = "SELECT_CHAT";
export const SEND_MESSAGE = "SEND_MESSAGE";
