import React, { useEffect, useState } from "react";
import "./Accordian.css";
import { GoKebabHorizontal, GoChevronRight } from "react-icons/go";
import projectnamevectorblue from "../../assest/Image/projectnamevectorblue.png";
import usersbold from "../../assest/Image/usersbold.png";
import ReportLogoBlue from "../../assest/Image/ReportLogoBlue.png";
import closeprojectreport from "../../assest/Image/closeprojectreport.png";
import reportwhite from "../../assest/Image/reportwhite.png";
import sequencelogo from "../../assest/Image/sequencelogo.png";
import sequencelogoblue from "../../assest/Image/sequencelogoblue.png";
import projectnameicon from "../../assest/Image/projectnameicon.png";
import bell from "../../assest/Image/bell.png";
import Query from "../Query/Query";
import users from "../../assest/Image/users.png";
import reporticon from "../../assest/Image/reporticon.png";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { AiFillBell } from "react-icons/ai";
import AddRing from "../../assest/Image/AddRing.png";
import addanyicon from "../../assest/Image/addanyicon.png";
import { BsFillPencilFill } from "react-icons/bs";
import Addnewsequence from "./Addnewsequence";
import Reportpopover from "../Query/Reportpopover";
import ProjectOptions from "./ProjectOptions";
import editpic from "../../assest/Image/editpic.png";
import Editsequencename from "./Editsequencename";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  get_all_projects_actionCreater,
  get_project_data_actionCreater,
  get_sequence_of_project_actionCreater,
} from "../../Redux/projects/CreateActions";
import {
  get_query_of_sequence,
  handle_update_status_project,
} from "../../Functions/projectsFunction";
import {
  select_query_of_sequence_actionCreater,
  set_query_status_actionCreater,
} from "../../Redux/selectQuery/CreateActions";

function Accordian(props) {
  const { projectData, idx, forUpdate, setToggle } = props;
  const [addnewsequence, setAddnewsequence] = useState(false);
  const [Opensequencenamemodal, setOpensequencenamemodal] = useState(false);
  const [openreportpopover, setOpenreportpopover] = useState(null);
  const [showsequenceandreport, setShowsequenceandreport] = useState(false);
  const [Querydata, setQuerydata] = useState([]);

  // to see isproject close or open in


  const oldUserData_id = useSelector((state) => {
    return state && state.profileData
      ? state?.profileData?._id
      : state?.profileData;
  });
  // end of close project

  // handleReOpenProject

  const handleReOpenProject = () => {
    console.log("click reopen project");
    let route = window.location.href.split("/");
    handle_update_status_project({
      router,
      dispatch,
      selectedProject: projectData?._id,
      newStatus: "Open",
    });
    get_all_projects_actionCreater({
      oldUserData_id,
      dispatch,
      router: route[4],
    });
  };

  // end handleReOpenProject

  // const [sequenceoption,setSequenceoption] = useState(null)
  // state use for store sequences from redux
  const sequence = useSelector((state) => state.projects.sequence);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, query_id } = useParams();
  const location = useLocation();

  const router = useSelector((state) => {
    return state.router;
  });

  let route = location?.pathname.split("/")[2];
  const showdata = (id, query_id) => {
    get_sequence_of_project_actionCreater({
      dispatch,
      project_id: id ? id : projectData._id,
    });
    get_project_data_actionCreater({
      dispatch,
      projectData,
      router,
    });
    if (query_id == undefined)
      select_query_of_sequence_actionCreater({ data: null, dispatch });
    set_query_status_actionCreater({
      dispatch,
      status: false,
    });
    setShowsequenceandreport(false);
    if (query_id == undefined)
      navigate(`/home/${route}/${id ? id : projectData._id}`);
    else {
      navigate(`/home/${route}/${id ? id : projectData._id}/${query_id}`);
    }
  };

  useEffect(() => {
    if (id == projectData._id) {
      showdata(id, query_id);
    }
  }, []);

  useEffect(() => {
    if (id == projectData._id && projectData && router) {
      get_project_data_actionCreater({
        dispatch,
        projectData,
        router,
      });
    }
  }, [location]);

  useEffect(() => {
    if (projectData._id == id) {
      setShowsequenceandreport(true);
    } else setShowsequenceandreport(false);
  }, [id, idx, forUpdate]);

  // open report popup
  const handlereportpopup = (event) => {
    setOpenreportpopover(event.target);
  };

  const openreportpopoveropen = Boolean(openreportpopover);
  const openreportpopoverid = openreportpopoveropen
    ? "simple-popover"
    : undefined;

  // end of report popup

  // this is for sequence and to open  Query
  const [selected, setSelected] = useState(null);
  const [selectedsublist, setselectedsublist] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // end here

  // open the option of edit name project popup
  const [projectoption, setProjectoption] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState();

  const openProjectpopup = (event) => {
    setProjectoption(event.target);
  };
  const projectopen = Boolean(projectoption);
  const projectopenid = projectopen ? "simple-popover" : undefined;
  // option of edit name project popup end here

  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget);
    setselectedsublist(true);
    setSelectedSequence(value._id);
    get_query_of_sequence({
      setQuerydata,
      project_id: value.project_id,
      sequence_id: value._id,
      dispatch,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const queryid = open ? "simple-popover" : undefined;

  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  const data = [
    {
      question: "Sequence",
      answer: [
        "list here1 ",
        "list here2 ",
        "list here3 ",
        "list here4 ",
        "list here5 ",
        "list here6 ",
        "list here7 ",
        "list here8 ",
        "list here9 ",
      ],
    },
  ];

  // add new sequence

  const handleAddnew = () => {
    setAddnewsequence(true);
  };
  // end handle new sequence

  // handle sequence modal for name change
  const [sequence_data, set_sequence_data] = useState({
    project: null,
    sequence_id: null,
  });
  const handleeditsequencenameClick = ({ value }) => {
    setOpensequencenamemodal(true);
    set_sequence_data({
      project: value.project_id,
      sequence_id: value._id,
    });
  };

  // sequence modal for name change end here

  return projectData.projectStatus === true ? (
    <div className="wrapper">
      <div className="accordian">
        <div className={showsequenceandreport === true ? "itemblue" : "item"}>
          <div className="projecthead">
            <div
              className="projectNamediv1"
              onClick={() => {
                showdata();
              }}
            >
              <img
                src={
                  showsequenceandreport === true
                    ? projectnameicon
                    : projectnamevectorblue
                }
                className="projecticonlogo"
              />
              <div
                className={
                  showsequenceandreport === true
                    ? "whiteprojectnamespan"
                    : "blueprojectnamespan"
                }
              >
                {/* <marquee> */}
                {projectData?.projectName} {/* </marquee> */}
              </div>
              {projectData?.isNewRecord ? (
                ""
              ) : projectData?.readBy?.includes(oldUserData_id) ? (
                ""
              ) : (
                <AiFillBell className="projectbellicon" />
              )}
            </div>
            <GoKebabHorizontal
              size={25}
              className={
                showsequenceandreport === true ? "threedotswhite" : "threedots"
              }
              onClick={(e) => openProjectpopup(e)}
            />
          </div>
          <div className="showuser2">
            <img src={showsequenceandreport === true ? users : usersbold} />
            <div
              className={
                showsequenceandreport === true
                  ? "totaluserinprojectwhite"
                  : "totaluserinproject"
              }
            >
              {projectData?.projectUsers?.length + 1 || 1} Users
            </div>
          </div>
          {showsequenceandreport === true
            ? data.map((i, idx) => {
                return (
                  <>
                    <div className="title" onClick={() => toggle(idx)}>
                      <div className="imageContainer ">
                        <img src={sequencelogo} />
                      </div>
                      <div className="sequenceName">{i.question}</div>
                      <div className="sequenceupdownclass">
                        {projectData?.isNewRecord ? (
                          ""
                        ) : projectData?.readBy?.includes(oldUserData_id) ? (
                          ""
                        ) : (
                          <AiFillBell className="projectbellicon" />
                        )}
                        {selected === idx ? <FaAngleUp /> : <FaAngleDown />}
                      </div>
                    </div>
                    <div
                      className={selected === idx ? "content show" : "content"}
                    >
                      <ul
                        className="projectinnerdataul "
                        style={{ marginTop: "4%" }}
                      >
                        {sequence?.map((value, index) => {
                          return (
                            <div className="projectinnerlistdiv  ">
                              {/* {" "} */}
                              <div className="sequencedots"></div>
                              <div className="projectinnerdatalist">
                                {value?.sequenceName}
                              </div>
                              <div className="editnotifydiv">
                                {value?.isNewRecord ? (
                                  ""
                                ) : value?.readBy?.includes(oldUserData_id) ? (
                                  ""
                                ) : (
                                  <AiFillBell className="sequencebell" />
                                )}

                                <BsFillPencilFill
                                  className="editsequencenameicon"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleeditsequencenameClick({ value })
                                  }
                                />
                                <div>
                                  <GoChevronRight
                                    onClick={(e) => handleClick(e, value)}
                                    style={{
                                      cursor: "pointer",
                                      color: "#FFFFFF ",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="addnewsequencediv" onClick={handleAddnew}>
                      {" "}
                      <img src={addanyicon} className="plusseqclass" />
                      Add New {i.question}
                    </div>
                    <div className="report">
                      <div>
                        <img src={reportwhite} />
                        <span>Report</span>
                      </div>
                      <GoChevronRight
                        onClick={(e) => handlereportpopup(e)}
                        className="reporticonside"
                      />
                    </div>
                  </>
                );
              })
            : null}
        </div>
        {open ? (
          <Query
            anchorEl={anchorEl}
            handleClose={handleClose}
            id={queryid}
            open={open}
            Querydata={Querydata}
            projectData={projectData}
            selectedSequence={selectedSequence}
            setAnchorEl={setAnchorEl}
          />
        ) : null}
        {sequence_data.project && Opensequencenamemodal ? (
          <Editsequencename
            Opensequencenamemodal={Opensequencenamemodal}
            setOpensequencenamemodal={setOpensequencenamemodal}
            sequence_data={sequence_data}
            set_sequence_data={set_sequence_data}
          />
        ) : null}
        {addnewsequence ? (
          <Addnewsequence
            setAddnewsequence={setAddnewsequence}
            addnewsequence={addnewsequence}
          />
        ) : null}
        {projectopen ? (
          <ProjectOptions
            projectopen={projectopen}
            projectopenid={projectopenid}
            projectoption={projectoption}
            setProjectoption={setProjectoption}
            projectData={projectData}
          />
        ) : null}
        {openreportpopover ? (
          <Reportpopover
            openreportpopover={openreportpopover}
            openreportpopoverid={openreportpopoverid}
            openreportpopoveropen={openreportpopoveropen}
            setOpenreportpopover={setOpenreportpopover}
          />
        ) : null}
      </div>
    </div>
  ) : (
    // the project that is closed
    <div className="wrapper">
      <div className="closeprojectaccordian">
        <div className={"item"}>
          <div className="closeprojecthead">
            <div
              className="projectNamediv1"
              onClick={() => {
                showdata();
              }}
            >
              <img src={projectnamevectorblue} className="projecticonlogo" />
              <div className={"blueprojectnamespan"}>
                {projectData?.projectName}
              </div>
              <AiFillBell className="projectbellicon" />
            </div>
            <GoKebabHorizontal
              size={25}
              className={
                // showsequenceandreport === true ? "threedotswhite" :
                "threedots"
              }
              onClick={(e) => openProjectpopup(e)}
            />
          </div>
          <div className="closeprojectshowuser">
            <img
              src={
                // showsequenceandreport === true ? users :
                usersbold
              }
            />
            <div
              className={
                // showsequenceandreport === true
                //   ? "totaluserinprojectwhite"
                //   :
                "totaluserinproject"
              }
            >
              {projectData?.projectUsers?.length + 1 || 1} Users
            </div>
          </div>
          {showsequenceandreport === true
            ? data.map((i, idx) => {
                return (
                  <>
                    <div
                      className="closeprojectTitle"
                      onClick={() => toggle(idx)}
                    >
                      <div className="imageContainer ">
                        <img src={sequencelogoblue} />
                      </div>
                      <div className="sequenceName">{i.question}</div>

                      <div className="sequenceupdownclass">
                        {projectData?.isNewRecord ? (
                          ""
                        ) : projectData?.readBy?.includes(oldUserData_id) ? (
                          ""
                        ) : (
                          <AiFillBell className="projectbellicon" />
                        )}
                        {selected === idx ? (
                          <FaAngleUp style={{ color: "#142B59" }} />
                        ) : (
                          <FaAngleDown style={{ color: "blue" }} />
                        )}
                      </div>
                    </div>
                    <div
                      className={selected === idx ? "content show" : "content"}
                    >
                      <ul
                        className="projectinnerdataul "
                        style={{ marginTop: "4%" }}
                      >
                        {sequence?.map((value, index) => {
                          return (
                            <div className="projectinnerlistdiv  ">
                              <div className="sequencedots"></div>
                              <div className="projectinnerdatalist">
                                {value?.sequenceName}
                              </div>
                              <div className="editnotifydiv">
                                <AiFillBell className="sequencebell" />
                                <BsFillPencilFill
                                  className="editsequencenameicon"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleeditsequencenameClick({ value })
                                  }
                                />
                                <div>
                                  <GoChevronRight
                                    onClick={(e) => handleClick(e, value)}
                                    style={{
                                      cursor: "pointer",
                                      color: "#FFFFFF ",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </ul>
                    </div>
                    {/* <div className="addnewsequencediv" onClick={handleAddnew}>
                    {" "}
                    <img src={addanyicon} className="plusseqclass" />
                    Add New {i.question}
                  </div> */}
                    <div className="closeprojectreport">
                      <div>
                        <img src={closeprojectreport} />
                        <span>Report</span>
                      </div>
                      <GoChevronRight
                        onClick={(e) => handlereportpopup(e)}
                        className="reporticonside"
                      />
                    </div>
                  </>
                );
              })
            : null}
        </div>

        <div
          className={
            showsequenceandreport === true
              ? "closeprojectshowsequence"
              : "closeprojectcoverdiv"
          }
          onClick={() => {
            showdata();
          }}
        >
          <div className="closeprojectclosebtn">Closed!</div>
          <div
            className="closeprojectreopenproject"
            onClick={handleReOpenProject}
          >
            Tap-to-Re-Open
          </div>
        </div>
        {openreportpopover ? (
          <Reportpopover
            openreportpopover={openreportpopover}
            openreportpopoverid={openreportpopoverid}
            openreportpopoveropen={openreportpopoveropen}
            setOpenreportpopover={setOpenreportpopover}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Accordian;
