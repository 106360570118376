import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { handle_close_project } from "../../Functions/projectsFunction";

import './closeproject.css'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "50%",
  border: "none",
  outline: "none",
};

export default function CloseProjectModal(props) {
  const { open, handleCloseCloseProject, setProjectoption, projectData } =
    props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const oldUserData_id = useSelector((state) => {
    return state.profileData._id;
  });
  const myCurrentRouter = useSelector((state) => {
    return state.router;
  });
  const router = useSelector((state) => {
    return state.router;
  });

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseCloseProject}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        
          <div className="closeprojectmodalbody">
            <div className="closeprojectword">Close Project</div>
            <div className="closeprojectdescription">Are you sure you want to close that project ?</div>
        <div className="closeprojectbtns">
        <div className="canclebtncloseproject" onClick={handleCloseCloseProject}>Cancel</div>
            <div
             className="closebtncloseproject"
              onClick={() => {
                handle_close_project({
                  oldUserData_id,
                  projectData,
                  setProjectoption,
                  dispatch,
                  navigate,
                  handleCloseCloseProject,
                  router,
                  myCurrentRouter,
                });
              }}
            >
              Confirm
            </div>
        </div>

          </div>

        </Box>
      </Modal>
    </div>
  );
}
