import { SELECT_QUERY_OF_SEQUENCE, SELECT_QUERY_STATUS } from "../constants";

export default function SelectQueryReducer(
  state = {
    queryStatus: false ,
  },
  action
) {
  switch (action.type) {
    case SELECT_QUERY_OF_SEQUENCE:
      return { ...state, ...action.payload };
    case SELECT_QUERY_STATUS:
      return { ...state, queryStatus: action.payload };

    default:
      return state;
  }
}
