import * as React from "react";
import Box from "@mui/material/Box";
import "./movemodal.css";
import Modal from "@mui/material/Modal";
// import './Inviteuser.css'
import moveto from "../../assest/Image/moveto.png";
import { FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { handle_move_query } from "../../Functions/projectsFunction";
import {
  get_all_projects_actionCreater,
  get_project_data_actionCreater,
  get_sequence_of_project_actionCreater,
} from "../../Redux/projects/CreateActions";
import { useLocation, useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "50%",
  border: "none",
  outline: "none",
};

export default function Movequerymodal({
  openmovequerymodal,
  setOpenmovequerymodal,
  selectedQuery,
  setAnchorEl,
  selected_sequence,
}) {
  const handleClose = () => setOpenmovequerymodal(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const router = useSelector((state) => {
    return state.router;
  });

  const oldUserData_id = useSelector((state) => {
    return state.profileData._id;
  });

  return (
    <div>
      <Modal
        open={openmovequerymodal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="movemodalmaindiv"
            style={{ height: "35vh", background: "white" }}
          >
            <div className="movemodalimgdiv">
              <img src={moveto} />
            </div>
            <div className="movemodalmovespan">Move Query</div>
            <div className="movemodalspan">
              Are you sure you want to move Query 1 in Sequence Name?
            </div>
            <div className="movemodalbtn">
              <div className="movecancle" onClick={handleClose}>
                cancel
              </div>
              <div
                className="moveconfrom"
                onClick={async () => {
                  let res = await handle_move_query({
                    dispatch,
                    handleClose,
                    finalData: {
                      project: selectedQuery.project,
                      query_id: selectedQuery._id,
                      sequence_move_from: selectedQuery.sequence,
                      sequence_move_to: selected_sequence._id,
                      oldSequenceName: selected_sequence,
                    },
                  });
                  if (res) {
                    setAnchorEl(null);
                    handleClose();
                    let route = window.location.href.split("/");
                    let router = location?.pathname.split("/")[2];
                    get_all_projects_actionCreater({
                      oldUserData_id,
                      dispatch,
                      router: route[4],
                    });
                    navigate(`/home/${router}/${selectedQuery.project}`);
                  }

                  get_sequence_of_project_actionCreater({
                    dispatch,
                    project_id: selectedQuery.project,
                  });

                  get_project_data_actionCreater({
                    dispatch,
                    projectData: {
                      _id: selectedQuery.project,
                    },
                    router,
                  });
                }}
              >
                Confirm
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
