import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./editsequence.css";
import { FiX } from "react-icons/fi";
import { useActionData, useNavigate, useParams } from "react-router";
import { useState } from "react";
import { handle_save_new_projectName } from "../../Functions/projectsFunction";
import { useDispatch, useSelector } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "50%",
  border: "none",
  outline: "none",
};

export default function EditProjectname({
  Editprojectnamemodal,
  setEditprojectnamemodal,
  projectData,
  setProjectoption,
}) {
  const [newProjectName, setNewProjectName] = useState("");
  const oldUserData_id = useSelector((state) => {
    return state.profileData._id;
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myCurrentRouter = useSelector((state) => {
    return state.router;
  });

  const handleChange = (ele) => {
    setNewProjectName(ele.target.value);
  };

  const router = useSelector((state) => {
    return state.router;
  });

  const handleClose = () => setEditprojectnamemodal(false);

  const HandleProjectName=(event) =>{
    if (event.keyCode === 13) {
    if (myCurrentRouter)
    handle_save_new_projectName({
      oldUserData_id,
      projectName: newProjectName,
      projectData,
      setNewProjectName,
      dispatch,
      handleClose,
      setProjectoption,
      navigate,
      router,
      myCurrentRouter,
    });
  }
  }
  return (
    <div>
      <Modal
        open={Editprojectnamemodal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="editprojectname">
            <div className="editprojectnameheader">
              <span>Edit project name</span>
              <div className="editprojectcrosss" onClick={handleClose}>
                <FiX />
              </div>
            </div>

            {/* <div className="editprojectbody">
              <input type="text" placeholder="Enter Project Name" />

              <div className="modalprojectsavebtn">
                <span>save</span>
              </div>
            </div> */}
          </div>

          <div className="editprojectbody">
            <input
              onChange={handleChange}
              onKeyDown={(e) =>HandleProjectName(e)}
              value={newProjectName}
              type="text"
              placeholder="Enter Project Name"
            />

            <div
              onClick={() => {
                if (myCurrentRouter)
                  handle_save_new_projectName({
                    oldUserData_id,
                    projectName: newProjectName,
                    projectData,
                    setNewProjectName,
                    dispatch,
                    handleClose,
                    setProjectoption,
                    navigate,
                    router,
                    myCurrentRouter,
                  });
              }}
              className="modalprojectsavebtn"
            >
              <span>save</span>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
