import React, { useState } from "react";
import "./addnewquery.css";
import "./adduserinquery.css";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import chatinfopic from "../../assest/Image/chatinfopic.png";
import upload from "../../assest/Image/upload.png";
import name from "../../assest/Image/name.png";
import { FiX } from "react-icons/fi";
import {
  handle_add_query,
  handle_add_user_in_query,
} from "../../Functions/projectsFunction";
import { useDispatch } from "react-redux";
const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "50%",
  border: "none",
  outline: "none",
};

function Adduserinquery({
  showadduserinquery,
  setShowadduserinquery,
  selectedProject,
  selectedQuery,
}) {
  const [queryUsers, setQueryUsers] = useState([]);
  const dispatch = useDispatch();
  const handleClose = () => {
    setShowadduserinquery(false);
  };


  const handleChange = ({ data, field }) => {
    let obj = queryUsers.find((e) => {
      return e.user.email == data.user.email;
    });
    let userList = [];
    if (obj) {
      userList = queryUsers.filter((e) => {
        return e.user.email != data.user.email;
      });
    } else {
      userList = [...queryUsers, data];
    }
    setQueryUsers(userList);
  };

  return (
    <Modal
      open={showadduserinquery}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="inviteusermaindiv">
          <div className="addnewqueryheader">
            <span>Add New User</span>
            <div className="addnewquerycrosss" onClick={handleClose}>
              <FiX />
            </div>
          </div>

          <div className="addnewuserinquerybody">
            <div className="adduserdiv">
              <img src={name} />
              <div>Add user in Query</div>
            </div>

            <div className="usersinquery">
              {selectedProject.projectData?.projectUsers !== null
                ? selectedProject.projectData?.projectUsers.map(
                    (value, idx) => {
                      return (
                        <div className="showusernameandcheckbox" key={idx}>
                          <div className="addnewusernameinquery">
                            {" "}
                            {value.user.name}
                          </div>
                          <input
                            type="checkbox"
                            onClick={() => {
                              handleChange({
                                data: value,
                                field: "queryUsers",
                              });
                            }}
                          />
                        </div>
                      );
                    }
                  )
                : null}
            </div>
            <div
              className="addnewuserbtn"
              onClick={() => {
                let finalData = {
                  project: selectedQuery.project,
                  query: selectedQuery._id,
                  users: queryUsers,
                };
                handle_add_user_in_query({
                  finalData,
                  handleClose,
                  dispatch,
                  setQueryUsers
                });
              }}
            >
              Submit
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default Adduserinquery;
