import React from "react";
import Box from "@mui/material/Box";
import "./changepassword.css";
import Modal from "@mui/material/Modal";
// import './Inviteuser.css'
import { FiX } from "react-icons/fi";
import * as Yup from "yup";
import password from "../../assest/Image/password.png";
import { useFormik } from "formik";
import { handle_change_password_for_user } from "../../Functions/profileFunctions";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // height: "51%",
  border: "none",
  outline: "none",
};

export default function Changepassword({
  openchangepasswordmodal,
  setOpenchangepasswordmodal,
}) {
  const navigate = useNavigate();
  const initialValues = {
    currentPassword: "",
    Password: "",
    Conform_Password: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .min(6)
        .max(30)
        .required(" Please Enter Your Password"),
      Password: Yup.string()
        .min(6)
        .max(30)
        .required(" Please Enter Your Password"),
      Conform_Password: Yup.string()
        .required()
        .oneOf([Yup.ref("Password"), null], "Password must match"),
    }),
    onSubmit: (values, action) => {
      action.resetForm();
      handle_change_password_for_user({
        ...formik.values,
        handleClose,
        navigate,
      });
      // formik.resetForm();
    },
  });
  const handleClose = () => setOpenchangepasswordmodal(false);

  return (
    <div>
      <Modal
        open={openchangepasswordmodal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form>
            <div className="inviteusermaindiv">
              <div className="inviteuserheader">
                <span>Change Password</span>
                <div className="inviteusercrosss" onClick={handleClose}>
                  <FiX />
                </div>
              </div>

              <div className="changepasswordbody">
                <div className="input-group mb-2 changepassworddiv shadow-none ">
                  <span
                    className="input-group-text changepasswordemailspan"
                    id="basic-addon1"
                    htmlFor="changePassword"
                  >
                    <img src={password} className="nameimage" />
                  </span>
                  <input
                    type="password"
                    className="form-control changepasswordnameinput shadow-none"
                    onChange={formik.handleChange}
                    placeholder="Current Password"
                    value={formik.values.currentPassword}
                    name="currentPassword"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {formik.errors.currentPassword &&
                formik.touched.currentPassword ? (
                  <div className="changepasswordparagraph">
                    {formik.errors.currentPassword}
                  </div>
                ) : null}
                <div className="input-group mb-2 changepassworddiv shadow-none ">
                  <span
                    className="input-group-text changepasswordemailspan"
                    id="basic-addon1"
                    htmlFor="Password"
                  >
                    <img src={password} className="nameimage" />
                  </span>
                  <input
                    type="password"
                    className="form-control changepasswordnameinput shadow-none"
                    onChange={formik.handleChange}
                    placeholder="New Password"
                    value={formik.values.Password}
                    name="Password"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {formik.errors.Password && formik.touched.Password ? (
                  <div className="changepasswordparagraph">
                    {formik.errors.Password}
                  </div>
                ) : null}
                <div className="input-group mb-2 changepassworddiv2 shadow-none ">
                  <span
                    className="input-group-text changepasswordemailspan"
                    id="basic-addon1"
                    htmlFor="Conform_Password"
                  >
                    <img src={password} className="nameimage" />
                  </span>
                  <input
                    type="password"
                    className="form-control changepasswordnameinput shadow-none"
                    onChange={formik.handleChange}
                    placeholder="Conform Password"
                    value={formik.values.Conform_Password}
                    name="Conform_Password"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {formik.errors.Conform_Password &&
                formik.touched.Conform_Password ? (
                  <div className="changepasswordparagraph">
                    {formik.errors.Conform_Password}
                  </div>
                ) : null}
                <button
                  className="changepasswordsavebtn"
                  type="button"
                  onClick={formik.handleSubmit}
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
