import { combineReducers } from "redux";
import ProjectsReducer from "./projects/Reducer";
import LoaderReducer from "./loader/Reducer";
import ProfileReducer from "./profile/Reducer";
import SelectQueryReducer from "./selectQuery/Reducer";
import SelectProjectReducer from "./projects/SelectProjectReducer";
import RouterReducer from "./routers/Reducer";
import NotificationsReducer from "./notifications/Reducer";
import ProfileDataReducer from "./profile/profileDataReducer";
import ChatDataReducer from "./chatData/Reducer";

const rootReducer = combineReducers({
  projects: ProjectsReducer,
  loader: LoaderReducer,
  profile: ProfileReducer,
  profileData: ProfileDataReducer,
  query: SelectQueryReducer,
  selectedProject: SelectProjectReducer,
  router: RouterReducer,
  notification: NotificationsReducer,
  chatdata: ChatDataReducer,
});

export default rootReducer;
