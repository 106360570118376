import React, { useState, useRef } from "react";
import "./chatarea.css";
import upload from "../../assest/Image/upload.png";
import sendmsgicon from "../../assest/Image/sendmsgicon.png";
import { Stack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import MessageRecieved from "./MessageRecieved";
import MessageSend from "./MessageSend";
import { handleSendMessage } from "../../Functions/messageFunction";
import { handle_read_chat_data } from "../../Functions/projectsFunction";
import { useEffect } from "react";
import CloseChat from "./CloseChat";
import Reply from "./Reply/Reply";
import SelectedtypeImage from "./SelectedImageScreen/SelectedtypeImage";
import ReplyScreenWithImage from "./SimpleImageReplyWithDoc/ReplyScreenWithImage";
import { select_chat_actionCreater } from "../../Redux/chatData/CreateActions";
import { get_all_projects_actionCreater } from "../../Redux/projects/CreateActions";
import { useLocation } from "react-router-dom";
function ChatArea({ isCloseChat, setIsCloseChat }) {
  const [chatreply, setChatreply] = useState(false);
  const [input_message, set_input_message] = useState("");
  const [countScrollTop, setCountScrollTop] = useState(1);
  const [document, setDocument] = useState(null);
  const [isBold, setIsBold] = useState(false);
  const [IsImageSelected, setIsImageSelected] = useState(false);
  const [SelectedDataofImageandPdf, setSelectedDataofImageandPdf] = useState(
    []
  );
  const [ShowImageandPdf, setShowImageandPdf] = useState(false);
  const [Image, setImage] = useState(null);
  const [replymsg, setreplymsg] = useState({
    name: "",
    profession: "",
    color: null,
    message: "",
    message_id: null,
  });
  const location = useLocation();
  let route = location?.pathname.split("/")[2];
  const oldUserData_id = useSelector((state) => {
    return state && state.profileData
      ? state?.profileData?._id
      : state?.profileData;
  });
  const selectedQuery = useSelector((state) => {
    return state.query;
  });
  // send message on Enter function
  const SendMessageFunc = (event) => {
    if (event.keyCode === 13) {
      console.log("hiii am here on Enter Click");
      handleSendMessage({
        oldUserData_id,
        input_message,
        document: document,
        isBold,
        replymsg: replymsg.message_id,
        selectedQuery,
        dispatch,
        setChatreply,
        setIsBold,
        set_input_message,
        setDocument,
        setreplymsg,
        router,
        setShowImageandPdf,
      });
    }
  };

  useEffect(() => {
    if (selectedQuery) {
      select_chat_actionCreater({
        dispatch,
        data: selectedQuery,
      });
      handle_read_chat_data({
        data: selectedQuery,
        dispatch,
      });
      get_all_projects_actionCreater({
        oldUserData_id,
        dispatch,
        router: route,
      });
    }
  }, [selectedQuery]);

  // send message on Enter function end here

  // scroll to top function uses

  const HandleScroll = async (event) => {
    if (event.currentTarget.scrollTop === 0) {
      const token = window.localStorage.getItem("token");
      try {
        const realChatData = await axios.post(
          `${process.env.React_App_prod_url}/message/get_message`,
          {
            query: selectedQuery._id,
            page: countScrollTop,
            limit_of_message: 100,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        console.log(realChatData, "realChatData", countScrollTop);
        console.log(countScrollTop, "countScrollTop");
        setCountScrollTop(countScrollTop + 1);
      } catch (err) {
        console.log(err, "error");
      }
    }
  };
  // end scroll to top function uses

  const replymsgRef = useRef({});

  const forwardtoChatReplymsg = (id) => {
    console.log(replymsgRef.current, "reply message btn ");
    console.log(id, "reply message btn id");
    replymsgRef.current[id].scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  const dispatch = useDispatch();

  let userDetail = useSelector((state) => {
    return state.profileData;
  });

  let chatData = useSelector((state) => {
    return state.chatdata;
  });

  const router = useSelector((state) => {
    return state.router;
  });

  const ScrolltoBottom = useRef(null);

  useEffect(() => {
    ScrolltoBottom.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [chatData]);

  useEffect(() => {
    return () => {
      handle_read_chat_data({
        data: selectedQuery,
        dispatch,
        status: "unselect",
      });
      get_all_projects_actionCreater({
        oldUserData_id,
        dispatch,
        router: route,
      });
    };
  }, [selectedQuery]);

  const [replyscreen, setReplyscreen] = useState(false);
  const handleReply = (name, message, profession, color, message_id) => {
    setChatreply(true);
    setreplymsg({
      name,
      profession,
      color,
      message,
      message_id,
    });
  };

  const HandlemsgReplyscreen = () => {
    // setReplyscreen(true);
    setChatreply(false);
  };

  const openreply = Boolean(chatreply);
  const replyid = openreply ? "simple-popover" : undefined;

  const ref = useRef();

  // this is for handle the input change only in

  const handleInputChange = (event) => {
    event.target.files[0]
      ? setShowImageandPdf(true)
      : setShowImageandPdf(false);

    setSelectedDataofImageandPdf(event.target.files[0]);

    if (
      ["image/png", "image/jpeg", "image/jpg"].includes(
        event.target.files[0].type
      )
    ) {
      setIsImageSelected(true);
      setImage(URL.createObjectURL(event.target.files[0]));
      //   const input = document.getElementById("selectinputfiled")
      // input.value = "";
    } else {
      setIsImageSelected(false);
    }

    // event.target.value = "";
  };

  console.log(Image, "IsImageSelected");
  console.log(chatData, "chatData");
  console.log(userDetail, "userDetail");

  return (
    <div className="chatareamaindivref">
      <div className="chatArea" ref={ref} onScroll={HandleScroll}>
        <div className="HrLine">
          <hr style={{ border: "2px solid #E3E3E3" }} />
          <div className="today">Today</div>
        </div>
        {chatData?.map((ele,index) => {
          return ele.sendFrom._id === userDetail._id ? (
            <MessageSend
              setChatreply={setChatreply}
              isSameUser = {ele?.sendFrom?._id !== chatData[index - 1]?.sendFrom?._id}
              replymsgRef={replymsgRef}
              forwardtoChatReplymsg={forwardtoChatReplymsg}
              message={ele}
              setreplymsg={setreplymsg}
            />
          ) : (
            <MessageRecieved
              setChatreply={setChatreply}
              isSameUser = {ele?.sendFrom?._id !== chatData[index - 1]?.sendFrom?._id}
              replymsgRef={replymsgRef}
              forwardtoChatReplymsg={forwardtoChatReplymsg}
              message={ele}
              setreplymsg={setreplymsg}
            />
          );
        })}

        <Stack ref={ScrolltoBottom} className="okk" />
        {chatreply === true ? <Reply setChatreply={setChatreply} /> : null}

        {ShowImageandPdf === true ? (
          <SelectedtypeImage
            Image={Image}
            setImage={setImage}
            setShowImageandPdf={setShowImageandPdf}
            SelectedDataofImageandPdf={SelectedDataofImageandPdf}
            IsImageSelected={IsImageSelected}
          />
        ) : null}

        {isCloseChat === true ? (
          <CloseChat setIsCloseChat={setIsCloseChat} />
        ) : (
          <div className="inputboxmaindiv">
            <div className="inputbox">
              <input
                onKeyDown={(e) => SendMessageFunc(e)}
                onChange={(e) => {
                  set_input_message(e.target.value);
                }}
                value={input_message}
                placeholder="Type Something..."
                className="userinputclass"
              />
              <div
                className="sendmsgbtn"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleSendMessage({
                    oldUserData_id,
                    input_message,
                    document: document,
                    isBold,
                    replymsg: replymsg.message_id,
                    selectedQuery,
                    dispatch,
                    setChatreply,
                    setIsBold,
                    set_input_message,
                    setDocument,
                    setreplymsg,
                    router,
                    setShowImageandPdf,
                  });
                }}
              >
                <img src={sendmsgicon} />
              </div>
            </div>
            <hr
              style={{
                border: "1px solid rgb(150 139 139)",
                marginTop: "0%",
                marginBottom: "0%",
              }}
            />
            <div className="boldword">
              <span
                onClick={() => {
                  setIsBold(!isBold);
                }}
                style={{
                  cursor: "pointer",
                  background: `${isBold ? "#98939e" : "#ffffff"}`,
                }}
              >
                B
              </span>
              <div className="boldblankdiv"></div>
              {chatreply === true ? null : (
                <>
                  <input
                    onChange={(e) => {
                      handleInputChange(e);
                      setDocument(e.target.files);
                    }}
                    files={document}
                    type="file"
                    id="selectinputfiled"
                    accept="image/png,image/jpeg,application/pdf,image/jpg"
                    className="uploadinchatclass"
                  />
                  <img src={upload} className="blankuploaddataimage" />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatArea;
