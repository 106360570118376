import axios from "axios";
import { handleUpload } from "../Components/GeneralFileUpload/GeneralFileUpload";
import { closeLoaderAction, startLoaderAction } from "../Redux/loader/Actions";
import { show_toaster_actionCreater } from "../Redux/toasers/CreateActions";
import { send_message_actionCreater } from "../Redux/chatData/CreateActions";
import { get_all_projects_actionCreater } from "../Redux/projects/CreateActions";
import { CleaningServices } from "@mui/icons-material";

export const handleSendMessage = async ({
  oldUserData_id,
  dispatch,
  input_message,
  document,
  isBold,
  replymsg,
  selectedQuery,
  setIsBold,
  set_input_message,
  setDocument,
  setreplymsg,
  setChatreply,
  router,
  setShowImageandPdf
}) => {
  try {


    if (input_message == "") {
      if (!document) {
        throw "Please Upload the Document or message";
      }
      //   throw "Enter the Query Description";
    }
    if (document) dispatch(startLoaderAction());
    // upload file
    let myFileUrl = null;
    if (document != null) {
      myFileUrl = await handleUpload(document);
    }


    const token = window.localStorage.getItem("token");

    let send_message = await axios.post(
      `${process.env.React_App_prod_url}/message/send`,
      {
        project: selectedQuery.project,
        sequence: selectedQuery.sequence,
        query: selectedQuery._id,
        replyOf: replymsg || null,
        messageData: {
          description: input_message,
          doc: myFileUrl,
        },
        isBold,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    // console.log({ send_message: send_message.data.post_data });

    send_message_actionCreater({
      dispatch,
      message: send_message.data.post_data,
    });
    get_all_projects_actionCreater({ oldUserData_id,dispatch, router });
    // show_toaster_actionCreater({
    //   message: "Send Message Successfully",
    //   status: "success",
    // });
    setIsBold(false);
    setChatreply(false);
    set_input_message("");
    setDocument(null);
    setShowImageandPdf(false);
    setreplymsg({
      name: "",
      profession: "",
      color: null,
      message: "",
      message_id: null,
    });
    dispatch(closeLoaderAction());
  } catch (err) {
    console.log({ err });
    show_toaster_actionCreater({
      message: err,
      status: "error",
    });
    dispatch(closeLoaderAction());
  }
};
