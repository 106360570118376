import React,{useState} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import './addfile.css'
import { FiX } from "react-icons/fi";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { AiOutlineUpload} from "react-icons/ai";
import sequencelogoblue from '../../assest/Image/sequencelogoblue.png'
const style = {
  position: "absolute",
  top: "44%",
  left: "47%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "37%",
  border: "none",
  outline: "none",
};
export default function Addfile({ openaddfile,setOpenaddfile }) {
  const [image,setImage] = useState(null);
  const [imageprev,setImagePrev] = useState(null);
  const [showsequence,setShowsequence] = useState(false);
    const [selectedsequencename,setSelectedsequencename] = useState('');
  const handleClose = () => setOpenaddfile(false);


  const sequencename=[
    {
      name:"sequence/"
    },
    {
      name:"sequence/"
    },
    {
      name:"sequence/"
    },
    {
      name:"sequence/"
    },

      {
      name:"sequence/"
    },
      {
      name:"sequence/"
    },
      {
      name:"sequence/"
    },
    {
      name:"sequence/"
    },


      {
      name:"sequence/"
    },
      {
      name:"sequence/"
    },

      {
      name:"sequence/"
    },
    {
      name:"sequence/"
    },
  ]
  const HandlefileChnage = (e) =>{
    const file = e.target.files[0]
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
        setImagePrev(reader.result)
        setImage(reader.result)
    }

  }
  const handlefilename = (name) =>{
    setShowsequence(false)
    setSelectedsequencename(name)
  }
  return (
    <div>
      <Modal
        open={openaddfile}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="addfilemaindiv">
            <div className="addfileheader">
              <span>Add More Files</span>
              <div className="addfilecrosss" onClick={handleClose}>
                <FiX />
              </div>
            </div>

            <div className="addfilebody">
             <div className="selectsequencediv" onClick={()=>setShowsequence(true)}>
<div className="sequencelogoandname" >
<img src={sequencelogoblue} />
{
  selectedsequencename === ""? 
  <input type="text" placeholder="Sequence Name"/> 
  :
  <span>{selectedsequencename}</span>
}

</div>
<FaAngleDown/>
</div>

<input type="file" accept="image/*" className="uploadsequencefile" onChange={(e)=>HandlefileChnage(e)}/>
<div className="uploadsequencediv">
<div className="uploadfilelogoandname">
<AiOutlineUpload style={{color:"#1B4D9F"}}/>
{
image !== null ? <div>wjrlqwje</div>:
<div>Upload New File</div> 
}
 
</div>
<span className={image !== null ? 'isuploadfalse': 'isupload'}>Upload</span>
</div>

<div>

</div>
              <div className="addfilesavebtn">
                  save
          
              </div>
            </div>
          </div>
          {
        showsequence === true ? 
       
          <div className="sequencefiletouploaded" >
           {  sequencename.map((element,index)=>(
          <div className="sequencelistforfile"key={index} onClick={()=>handlefilename(element.name)}>
            <img src={sequencelogoblue} style={{height:"15px"}}/>
            <div>{index+1}{element.name}</div>
            </div>
                
        ))}
        </div>
        : null
      }
        </Box>
        
      </Modal> 


     
    </div>
  );
}

