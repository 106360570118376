import React from "react";
import { MdOutlineReplyAll } from "react-icons/md";
import "../sendmessage.css";
import createprojectimage from "../../../../assest/Image/createprojectimage.png";
function SelfImageMessage({ setChatreply, message, setreplymsg }) {
  return (
    <div className="sendmessageimagemaindiv">
      <MdOutlineReplyAll
        style={{ color: "gray", marginRight: "2%" }}
        onClick={() => {
          setChatreply(true);
          setreplymsg({
            name: message.sendFrom.name,
            profession: message.sendFrom.profession,
            color: null,
            message: message.messageData.description,
            message_id: message._id,
          });
        }}
      />
      <div className="sendmessageimage">
        <img src={message?.messageData?.doc} />
      </div>
    </div>
  );
}

export default SelfImageMessage;
