import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Profile from "../../assest/Image/Profile.png";
import Contactus from "../contactus/Contactus";
import Changepassword from "../Changepassword/Changepassword";
import { logoutuser } from "../../Functions/Logout";

function DetectOutsideClick(ref, setShowProfile) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowProfile(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function Showminiprofile({
  showProfile,
  setShowProfile,
  openchangepasswordmodal,
  setOpenchangepasswordmodal,
  userData,
  setShowcontactusmodal,
  showcontactusmadal
}) {
  const profileref = useRef(null);
  DetectOutsideClick(profileref, setShowProfile);

  const navigate = useNavigate();

  // handlelogout function is called here

  const handlelogout = () => {
    const response = logoutuser({ navigate });
    if (response.isDeleted === "successfull") {
      navigate("/");
    }
  };

  // handlelogout function is end here
  // contact us screen here
  // const [showcontactusmadal, setShowcontactusmodal] = useState(false);

  const handlecontactus = () => {
    setShowcontactusmodal(true);
    // setShowProfile(!showProfile);
  
    // setShowProfile(false)
  };
  // end contact us screen
  const handlechangepassword = () => {
    setOpenchangepasswordmodal(true);
    setShowProfile(false);
  };
  const [showprofilesetting, setShowprofilesetting] = useState(false);
  const handlesettingclick = () => {
    setShowprofilesetting(!showprofilesetting);
  };
  return showProfile === true ? (
    <>
      <div className="profilepopup" ref={profileref}>
        <div className="popupimagenmaehaeding" onClick={()=>{
             navigate("/home/editprofile");
             setShowProfile(!showProfile);
        }}>
          <div className="profilepopupimage">
            <img src={Profile} style={{ height: "36px" }} />
          </div>
          <div className="profilepopupheading">
            {" "}
            <div
              className="profilepopupheadingname"
              style={{
                fontWeight : "bold",
              }}
            >
              {userData ? userData.userName : ""}
           
            </div>
            {/* <div className="profilepopupheadingemail">
              {userData ? userData.email : ""}
            </div> */}
          </div>
        </div>
        <div
          className="editProfile"
          onClick={() => {
            navigate("/home/editprofile");
            setShowProfile(!showProfile);
          }}
        >
          Edit Profile
        </div>
        <div className="setting" onClick={handlesettingclick}>
          Settings
        </div>
        {showprofilesetting === true ? (
          <>
            <div className="changepassword" onClick={handlechangepassword}>
              Change Password
            </div>
            {/* <div className="deleteuser">Delete User</div> */}

            <div
              className="settingsubmenu"
              onClick={() => {
                navigate("/home/useraccess");
                setShowProfile(!showProfile);
              }}
            >
              {" "}
              User Access{" "}
            </div>
          </>
        ) : null}
        <div className="contactusdiv" onClick={()=>handlecontactus()}>
          Contact Us
        </div>
        <div className="logout" onClick={handlelogout}>
          Logout
        </div>
      </div>
      {/* <Changepassword openchangepasswordmodal={openchangepasswordmodal} setOpenchangepasswordmodal={setOpenchangepasswordmodal}/> */}
      {showcontactusmadal ? (
        <Contactus
          showcontactusmadal={showcontactusmadal}
          setShowcontactusmodal={setShowcontactusmodal}
          setShowProfile={setShowProfile}
        />
      ) : null}
    </>
  ) : null;
}

export default Showminiprofile;
