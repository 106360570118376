import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./editsequence.css";
import { FiX } from "react-icons/fi";
import { handle_edit_sequence } from "../../Functions/projectsFunction";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "50%",
  border: "none",
  outline: "none",
};

export default function Editsequencename(props) {
  const {
    Opensequencenamemodal,
    setOpensequencenamemodal,
    sequence_data,
    set_sequence_data,
  } = props;
  const [sequenceName, setSequenceName] = React.useState("");
  const dispatch = useDispatch();
  const handleClose = () => setOpensequencenamemodal(false);

  useEffect(() => {
    return () => {
      set_sequence_data({
        project: null,
        sequence_id: null,
      });
    };
  }, []);
  const HandleSequenecName = (event) => {
    if (event.keyCode === 13) {
      let res = handle_edit_sequence({
        sequenceName,
        sequence_data,
        handleClose,
        dispatch,
        set_sequence_data,
      });
      if (res) handleClose();
    }
  };
  return (
    <div>
      <Modal
        open={Opensequencenamemodal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="editprojectname">
            <div className="editprojectnameheader">
              <span>Edit sequence</span>
              <div onClick={handleClose} className="editprojectcrosss">
                <FiX />
              </div>
            </div>

            <div className="editprojectbody">
              <input
                type="text"
                placeholder="Enter Sequence name"
                onKeyDown={(e) => HandleSequenecName(e)}
                onChange={(element) => {
                  setSequenceName(element.target.value);
                }}
              />

              <div className="modalprojectsavebtn">
                <span
                  onClick={() => {
                    let res = handle_edit_sequence({
                      sequenceName,
                      sequence_data,
                      handleClose,
                      dispatch,
                      set_sequence_data,
                    });
                    if (res) handleClose();
                  }}
                >
                  save
                </span>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
