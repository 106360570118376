import React,{ useState } from 'react'
import './Storage.css'
import StorageProject from './StorageProject'
import Groupchat from '../Chatside/Groupchat'
import StorageDescription from './StorageDescription'
function Storage() {
    const [isupload,setIsupload] = useState(false)
  return (

    <>
    <div className="Allproject">
      <div className="Allprojectlist">
      <StorageProject setIsupload = {setIsupload} />
      </div>
    </div>
   
<StorageDescription isupload = {isupload} setIsupload = {setIsupload}/>
  </>
  )
}

export default Storage