import React from 'react'
import './chatsideproject.css'
import slide3pic from '../../assest/Image/slide3pic.png'
import inviteuserarrow from '../../assest/Image/inviteuserarrow.png'
import inviteuserdownarrow from '../../assest/Image/inviteuserdownarrow.png'
import { useNavigate } from 'react-router-dom'
function Chatsideproject() {
  const navigate = useNavigate();
  return (
    <div className='chatsidecreateprojectdiv'>

     <div className='chatsideparagraphmain' >What you can do</div>
    <div className='createprojectuparrow'><img src={inviteuserarrow}/></div>
   
        <div className='chatsideparagraph'>
        Create a New Project and Add Users to proceed further.  
          </div>
    <button  className='chatsidecreateprojectbtn' onClick={ ()=>navigate('/home/add')} ><span>Create New Project</span></button>
<div className='createprojectdownarrow'><img src={inviteuserdownarrow}/></div>
    <div className='chatsidecreateprojectimg'><img src={slide3pic}/></div> 
    </div>
  )
}

export default Chatsideproject