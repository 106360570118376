import React from 'react'
import { RxCross2 } from "react-icons/rx";
import './reply.css'
function Messagereplypop({ setChatreply,replymsg }) {
    const messagetoreply = 'hi am here Testing sdgbakjsgh ksadhgjahgjkasgd skdhgsjkagdsa skajhgdjskagads kjsadhgdsjkahkads ksadhgdksjabgkjhgajk hdkgasg sahgkas hsak sakgh'
    return (
       
            <div className="replymaindivclassmini">
                <div className="replymsgandusername">
                    <div
                        className="replyusername"
                        style={{ color: "green" }}
                    >
                        Replying to {replymsg?.name} ({replymsg?.profession})
                    </div>
                    <div className="msgtobereplyed">
                 {replymsg?.message.substring(0,100)}...
                    </div>
                </div>
                <div className="closereply"
                    onClick={() => setChatreply(false)}
                >
             <RxCross2 />
                </div>
            </div>
    )
}

export default Messagereplypop