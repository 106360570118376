import {
  GET_SINGLE_USER_ACCESS_LIST,
} from "../constants";

export default function ProfileDataReducer(state = null, action) {
  switch (action.type) {
    case GET_SINGLE_USER_ACCESS_LIST:
      return action.payload;
    default:
      return state;
  }
}
