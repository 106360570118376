import {
  GET_USER_ACCESS_LIST,
  GET_SINGLE_USER_ACCESS_LIST,
} from "../constants";

export default function ProfileReducer(state = [], action) {
  switch (action.type) {
    case GET_USER_ACCESS_LIST:
      return action.payload;
    
    default:
      return state;
  }
}
