import { EDIT_SEQUENCE, GET_PROJECTS } from "../constants";

export default function ProjectsReducer(state = {}, action) {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        new: !state.new,
        myProjects: [...action.payload.myProjects],
        allProjects: [...action.payload.my_all_project_list],
        shareWithMe: [...action.payload.sharedWithMe],
      };

    case EDIT_SEQUENCE:
      return {
        ...state,
        sequence: action.payload,
      };

    default:
      return state;
  }
}
