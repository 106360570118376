import axios from "axios";
import { closeLoaderAction, startLoaderAction } from "../loader/Actions";
import {
  get_user_access_list_Action,
  get_single_user_list_Action,
} from "./Actions";
// import {} from "./Action"
// get sequence of project
export const get_user_access_list_actionCreater = async ({ dispatch }) => {
  try {
    const token = window.localStorage.getItem("token");
    dispatch(startLoaderAction());
    let access_list = await axios.get(
      `${process.env.React_App_prod_url}/project/user_access_list`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    dispatch(get_user_access_list_Action({ data: access_list.data.user_list }));
    dispatch(closeLoaderAction());
  } catch (err) {
    dispatch(closeLoaderAction());
    console.log({ err });
  }
};

export const get_single_user_list_actioncreator = async ({ dispatch }) => {
  try {
    const token = window.localStorage.getItem("token");
    dispatch(startLoaderAction());

    let singleUser_access_list = await axios
      .get(`${process.env.React_App_prod_url}/user/get_user_detail`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        dispatch(
          get_single_user_list_Action({
            data: result.data.user,
          })
        );
      });

    dispatch(closeLoaderAction());
  } catch (err) {
    dispatch(closeLoaderAction());
    console.log({ err });
  }
};
