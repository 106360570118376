import React, { useState } from "react";
import "./projectdescription.css";
import users from "../../assest/Image/users.png";
import addProjectIcon from "../../assest/Image/addProjectIcon.png";
import chatinfopic from "../../assest/Image/chatinfopic.png";
import totalsequence from "../../assest/Image/totalsequence.png";
import sequencelogoblue from "../../assest/Image/sequencelogoblue.png";
import lightblueuser from "../../assest/Image/lightblueuser.png";
import addblueicon from "../../assest/Image/addblueicon.png";
import { IoIosArrowDown } from "react-icons/io";
import timer from "../../assest/Image/timer.png";
import { useDispatch, useSelector } from "react-redux";
import closeprojectadduser from "../../assest/Image/closeprojectadduser.png";
import closeprojectred from "../../assest/Image/closeprojectred.png";
import Adduserpopup from "../Adduser/Adduserpopup";
import {
  handle_admin_of_project,
  handle_update_status_project,
} from "../../Functions/projectsFunction";
import moment from "moment/moment";
import { useEffect } from "react";
import { get_all_projects_actionCreater } from "../../Redux/projects/CreateActions";
function Projectdescription() {
  const [showstatus, setShowstatus] = useState(false);
  const [showadduser, setshowadduser] = useState(false);
  const [Showreopentext, setShowreopentext] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const dispatch = useDispatch();

  const selectedProject = useSelector((state) => {
    return state.selectedProject;
  });
  const userProfileEmail = useSelector((state) => {
    return state.profileData;
  });
  const oldUserData_id = useSelector((state) => {
    return state && state.profileData
    ? state?.profileData?._id
    : state?.profileData;;
  });

  const router = useSelector((state) => {
    return state.router;
  });

  useEffect(() => {
    if (selectedProject && userProfileEmail)
      selectedProject?.projectData?.projectUsers.map((ele) => {
        if (userProfileEmail.email.toLowerCase() === ele.email.toLowerCase())
          if (ele.role == "admin") setIsUserAdmin(true);
          else setIsUserAdmin(false);
      });
  }, [selectedProject, userProfileEmail]);

  return (
    <div className="descriptionmaindiv">
      <div
        className={
          selectedProject?.projectData?.projectStatus === false
            ? "closeprojectdescriptionheader"
            : "descriptionheader"
        }
      >
        <div className="descriptionprojectname">
          {/* <img src={addProjectIcon} /> */}
          <div>{selectedProject?.projectData?.projectName}</div>
        </div>
        <div className="descriptionprojectuser">
          <img src={users} />
          <div>
            {selectedProject?.projectData?.projectUsers.length + 1} users
          </div>
        </div>
      </div>
      <div className="projectdescriptioninfo">
        <div className="projectinfo">
          <img src={chatinfopic} />
          <span>Project Info</span>
        </div>
        <div className="projectcreatedbyinfo">
          This project was created by{" "}
          {selectedProject?.projectData?.createrName?.name} (
          {selectedProject?.projectData?.createrName?.profession}) on{" "}
          {moment(selectedProject?.projectData?.createdAt).format("DD/MM/YYYY")}
        </div>
      </div>
      <hr className="projectdescriptionhr" />

      <div className="projectsquares">
        {/* some changes arae here */}
        <div
          className={
            isSelected === true
              ? "projectopensquareblue"
              : "projectopensquarewhite"
          }
          onClick={() => {
            setIsSelected(!isSelected);
            let route = window.location.href.split("/");
            if (!selectedProject?.projectData?.projectStatus)
              handle_update_status_project({
                router,
                dispatch,
                selectedProject: selectedProject?.projectData?._id,
                newStatus: selectedProject?.projectData?.projectStatus
                  ? "Close"
                  : "Open",
              });
            get_all_projects_actionCreater({
              oldUserData_id,
              dispatch,
              router: route[4],
            });
          }}
        >
          {selectedProject?.projectData?.projectStatus ? (
            <div className={isSelected === true ? "imagediv" : "imagedivblue"}>
              <img src={timer} />
            </div>
          ) : (
            <div className={"closeprojectimagediv"}>
              <img src={closeprojectred} />
            </div>
          )}

          <div className="optionspan">
            <div className="isprojectopen">
              <div
                className={
                  selectedProject?.projectData?.projectStatus
                    ? "isprojectopendotgreen"
                    : "isprojectopendotred"
                }
              ></div>
              <div
                className={
                  isSelected === true ? "isopentrue" : "isselectedfalse"
                }
              >
                {selectedProject?.projectData?.projectStatus
                  ? "Open"
                  : "Closed"}
              </div>
            </div>
            <div className="closeproject">
              {selectedProject?.projectData?.projectStatus ? (
                <>
                  <div className={isSelected === true ? "white" : "blue"}>
                    Project Status
                  </div>
                  <div
                    className={
                      isSelected === true ? "arrowstatusblue" : "arrowstatus"
                    }
                  >
                    <IoIosArrowDown
                      onClick={() => setShowstatus(!showstatus)}
                    />
                  </div>
                </>
              ) : (
                <div className={isSelected === true ? "white" : "blue"}>
                  Re-Open-Project
                </div>
              )}
            </div>
          </div>
          {showstatus === true ? (
            <div className="showstatus">
              <div className="greendot"></div>
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  let route = window.location.href.split("/");
                  handle_update_status_project({
                    router,
                    dispatch,
                    selectedProject: selectedProject?.projectData?._id,
                    newStatus: selectedProject?.projectData?.projectStatus
                      ? "Close"
                      : "Open",
                  });
                  get_all_projects_actionCreater({
                    oldUserData_id,
                    dispatch,
                    router: route[4],
                  });
                  setShowstatus(false);
                }}
              >
                {selectedProject?.projectData?.projectStatus
                  ? "Close Project"
                  : "Open Project"}
              </span>
            </div>
          ) : null}
        </div>

        <div className="projectsequencesquare">
          <div className="imagediv">
            <img src={totalsequence} />
          </div>
          <div className="optionspans">
            <div className="total">
              {selectedProject?.externalInfo?.sequence}
            </div>
            <div className="projectsquarespans">Total Sequence</div>
          </div>
        </div>
        <div className="projectqueryopensquare">
          <div className="imagediv">
            <img src={sequencelogoblue} />
          </div>
          <div className="optionspans">
            <div className="total">
              {selectedProject?.externalInfo?.openQuery}
            </div>
            <div className="projectsquarespans">
              Total Query <span>Open</span>
            </div>
          </div>
        </div>
        <div className="projectclosequerysquare">
          <div className="imagediv">
            <img src={sequencelogoblue} />
          </div>
          <div className="optionspans">
            <div className="total">
              {selectedProject?.externalInfo?.closeQuery}
            </div>
            <div className="projectsquarespansclosed">
              Total Query <span className="closequeryspan">Closed</span>
            </div>
          </div>
        </div>
        <div className="projectclosequerysquare">
          <div className="imagediv">
            <img src={lightblueuser} />
          </div>
          <div className="optionspans">
            <div className="total">
              {selectedProject?.projectData?.projectUsers.length + 1}
            </div>
            <div className="projectsquarespans">Total user in project</div>
          </div>
        </div>
        {selectedProject?.projectData?.projectStatus === true ? (
          <div
            className="projectclosequerysquare"
            onClick={() => setshowadduser(true)}
          >
            <div className="imagediv">
              <img src={addblueicon} />
            </div>
            <div
              className="optionspans"
              style={{
                cursor: "pointer",
              }}
            >
              <div className="total">Add User</div>
              <div className="projectsquarespans">Add User in Project</div>
            </div>
          </div>
        ) : (
          <div
            className="projectclosequerysquare"
            onMouseEnter={() => setShowreopentext(true)}
            onMouseLeave={() => setShowreopentext(false)}
          >
            <div className="imagediv">
              <img src={closeprojectadduser} />
            </div>
            <div
              className="optionspans"
              style={{
                cursor: "pointer",
              }}
            >
              <div className="closeprojecttotal">Add User</div>
              <div className="projectsquarespans">Add User in Project</div>
            </div>
            {Showreopentext && (
              <div className="pleasereopenprojecthover">
                Please Re-Open The Project First
              </div>
            )}
          </div>
        )}
      </div>
      <div className="projectusers">
        {/* project creater data */}

        <div className="projectlistuser">
          <div className="listofadminandsimpleuser">
            <div className="logofirstletter">A</div>
            <div className="userlistnameinprojectdescription">
              {selectedProject?.projectData?.createrName?.name || ""}{" "}
              <span> ( {"Super-Admin"})</span>
            </div>
          </div>
          <div
            className={
              selectedProject?.projectData?.projectStatus === true
                ? "chatinfouserbtn"
                : "closeprojectuserbtn"
            }
          >
            Creater
          </div>
        </div>

        {selectedProject?.projectData?.projectUsers?.map((ele) => {
          return (
            <div className="projectlistuser">
              <div className="listofadminandsimpleuser">
                <div className="logofirstletter">A</div>
                <div className="userlistnameinprojectdescription">
                  {ele?.user?.name || ""} <span> ( {ele?.role || ""})</span>
                </div>
              </div>
              {selectedProject?.projectData?.projectStatus &&
              (isUserAdmin ||
                selectedProject?.projectData?.createrName?._id ==
                  userProfileEmail._id) &&
              userProfileEmail.email != ele.email ? (
                <div
                  className="chatinfouserbtn"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handle_admin_of_project({
                      router,
                      dispatch,
                      finalData: ele,
                      selectedProject: selectedProject?.projectData?._id,
                    });
                  }}
                >
                  {ele?.role == "admin" ? "Remove Admin" : "Make Admin"}
                </div>
              ) : (
                <div
                  className="closeprojectuserbtn"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {ele?.role == "admin" ? "Remove Admin" : "Make Admin"}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {showadduser ? (
        <Adduserpopup
          showadduser={showadduser}
          setshowadduser={setshowadduser}
          projectDetails={{
            name: selectedProject?.projectData?.projectName,
            id: selectedProject?.projectData._id,
          }}
        />
      ) : null}
    </div>
  );
}

export default Projectdescription;
