import React from "react";
import "../recivedmessages.css";
import { MdOutlineReplyAll } from "react-icons/md";
import createprojectimage from "../../../../assest/Image/createprojectimage.png";
function ImageMessageRecieved({ setChatreply, message, setreplymsg }) {
  return (
    <div className="messagerecievedimage">
      <div className="imagerecieved">
        <img src={message?.messageData?.doc} />
      </div>
      <MdOutlineReplyAll
        style={{ color: "gray", marginLeft: "2%" }}
        onClick={() => {
          setChatreply(true);
          setreplymsg({
            name: message.sendFrom.name,
            profession: message.sendFrom.profession,
            color: null,
            message: message.messageData.description,
            message_id: message._id,
          });
        }}
      />
    </div>
  );
}

export default ImageMessageRecieved;
