import axios from "axios";
import {
  get_all_projects_actionCreater,
  get_project_data_actionCreater,
  get_sequence_of_project_actionCreater,
} from "../Redux/projects/CreateActions";
import { closeLoaderAction, startLoaderAction } from "../Redux/loader/Actions";
import { handleUpload } from "../Components/GeneralFileUpload/GeneralFileUpload";
import { show_toaster_actionCreater } from "../Redux/toasers/CreateActions";
import { change_base_router_actionCreater } from "../Redux/routers/CreateActions";
import {
  get_query_data_actionCreater,
  select_query_of_sequence_actionCreater,
} from "../Redux/selectQuery/CreateActions";

// get my profile data
export const get_my_data = async ({ token, setUserData }) => {
  try {
    let verifiedData = await axios.get(
      `${process.env.React_App_prod_url}/user/get_user_data_from_token`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    setUserData(verifiedData.data.user);
  } catch (err) {
    console.log({ err });
  }
};

// get my company data
export const get_my_company = async ({ token, setCompanyList }) => {
  try {
    let company_list = await axios.get(
      `${process.env.React_App_prod_url}/company/my_companies`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    setCompanyList(
      company_list.data.companyList.reduce((ans, e) => {
        let inList = ans.find((ele) => ele.data == e.companyName);
        if (inList) return [...ans];
        return [...ans, { ...e, data: e.companyName }];
      }, [])
    );
  } catch (err) {
    console.log({ err });
  }
};

// get my user List from selected company
export const get_users_from_company = async ({
  token,
  setUserList,
  selectedCompanyList,
}) => {
  try {
    let users_list = await axios.post(
      `${process.env.React_App_prod_url}/company/user_list`,
      { selectedCompany: selectedCompanyList.map((e) => e.data) },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    setUserList(
      users_list.data.newUserList.map((e) => {
        return { ...e, data: e.name };
      })
    );
  } catch (err) {
    console.log({ err });
  }
};

// create project from here
export const handle_create_project = async ({
  selectedUserList,
  projectName,
  setProjectName,
  selectedSetCompanyList,
  selectedSetUserList,
  navigate,
  dispatch,
}) => {
  try {
    if (!projectName || projectName == "") {
      show_toaster_actionCreater({
        message: "Please Enter Project name",
        status: "error",
      });
      return;
    }

    if (selectedUserList.length <= 0) {
      show_toaster_actionCreater({
        message: "Please Select User of Project",
        status: "error",
      });
      return;
    }
    dispatch(startLoaderAction());
    const token = window.localStorage.getItem("token");
    let project = await axios.post(
      `${process.env.React_App_prod_url}/project/create`,
      {
        userList: selectedUserList.map((e) => e.email),
        projectName: projectName,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setProjectName("");
    selectedSetCompanyList([...[]]);
    selectedSetUserList([...[]]);

    setTimeout(() => {
      change_base_router_actionCreater({ dispatch, router: "project" });
      dispatch(closeLoaderAction());
      navigate(`/home/project/${project.data.project._id}`);
    }, 2000);
    show_toaster_actionCreater({
      message: "Project Created Successfully!",
      status: "success",
    });
  } catch (err) {
    dispatch(closeLoaderAction());

    show_toaster_actionCreater({
      message: err,
      status: "error",
    });
    console.log({ err });
  }
};

export const handle_Change_Tab = async ({
  tab,
  setSelectedBtn,
  projectList,
  setProjectData,
  navigate,
  route,
}) => {
  try {
    setSelectedBtn(tab);
    if (tab == "my") {
      setProjectData(projectList.myProjects);
    } else if (tab == "all") {
      setProjectData(projectList.allProjects);
    } else {
      setProjectData(projectList.shareWithMe);
    }

    navigate(`/home/${route}`);
  } catch (err) {
    console.log({ err });
  }
};

export const get_query_of_sequence = async ({
  setQuerydata,
  project_id,
  sequence_id,
  dispatch,
}) => {
  try {
    const token = window.localStorage.getItem("token");
    dispatch(startLoaderAction());
    let query_data = await axios.post(
      `${process.env.React_App_prod_url}/project/get_query_of_sequence`,
      {
        sequence: sequence_id,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    dispatch(closeLoaderAction());

    setQuerydata(query_data.data.query);
  } catch (err) {
    dispatch(closeLoaderAction());

    console.log({ err });
  }
};

export const handle_save_new_projectName = async ({
  oldUserData_id,
  projectName,
  projectData,
  setNewProjectName,
  dispatch,
  handleClose,
  setProjectoption,
  navigate,
  router,
  myCurrentRouter,
}) => {
  try {
    const token = window.localStorage.getItem("token");
    const { _id } = projectData;

    let new_project_data = await axios.post(
      `${process.env.React_App_prod_url}/project/edit_project_name`,
      {
        project: _id,
        projectName,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    get_all_projects_actionCreater({ oldUserData_id,dispatch, router });
    setNewProjectName("");
    handleClose();
    setProjectoption(null);
    // change_base_router_actionCreater({ dispatch, router: myCurrentRouter });
    navigate(`/home/${myCurrentRouter}`);
  } catch (err) {
    console.log({ err });
    alert(err);
  }
};

// delete user from Projecy

export const handle_delete_user_from_project = async ({
  oldUserData_id,
  projectData,
  user,
  dispatch,
  handleCloseDeleteUser,
  setProjectoption,
  navigate,
  router,
  myCurrentRouter,
}) => {
  try {
    const token = window.localStorage.getItem("token");
    const { _id } = projectData;

    let new_project_data = await axios.post(
      `${process.env.React_App_prod_url}/project/delete_user_from_project`,
      {
        project: _id,
        user: user.email,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    get_all_projects_actionCreater({ oldUserData_id,dispatch, router });
    handleCloseDeleteUser();
    setProjectoption(null);
    // change_base_router_actionCreater({ dispatch, router: "project" });
    navigate(`/home/${myCurrentRouter}`);
    // navigate("/home/project");
  } catch (err) {
    console.log({ err });
    alert(err);
  }
};

export const handle_close_project = async ({
  oldUserData_id,
  projectData,
  dispatch,
  setProjectoption,
  navigate,
  handleCloseCloseProject,
  router,
  myCurrentRouter,
}) => {
  try {
    const token = window.localStorage.getItem("token");
    const { _id } = projectData;

    let new_project_data = await axios.post(
      `${process.env.React_App_prod_url}/project/update_project_status`,
      {
        project: _id,
        newStatus: "Close",
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    get_all_projects_actionCreater({ oldUserData_id,dispatch, router });
    setProjectoption(null);
    handleCloseCloseProject();
    // change_base_router_actionCreater({ dispatch, router: "project" });
    navigate(`/home/${myCurrentRouter}`);
  } catch (err) {
    console.log({ err });
    alert(err);
  }
};

export const handle_add_sequence = async ({
  dispatch,
  sequenceName,
  id,
  handleClose,
}) => {
  try {
    if (!sequenceName || sequenceName.trim() == "") {
      show_toaster_actionCreater({
        message: "Please enter your Sequence Name",
        status: "error",
      });
      return;
    }
    const token = window.localStorage.getItem("token");

    let new_project_data = await axios.post(
      `${process.env.React_App_prod_url}/project/add_sequence`,
      {
        project: id,
        sequenceName: sequenceName.trim(),
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    handleClose();
    show_toaster_actionCreater({
      message: "Sequence Created Successfully",
      status: "success",
    });
    get_sequence_of_project_actionCreater({ dispatch, project_id: id });
  } catch (err) {
    show_toaster_actionCreater({
      message: "Sequence Created Failed",
      status: "error",
    });
    console.log({ err });
    alert(err);
  }
};

export const handle_edit_sequence = async ({
  dispatch,
  sequenceName,
  sequence_data,
  handleClose,
}) => {
  try {
    if (!sequenceName || sequenceName.trim() == "") {
      show_toaster_actionCreater({
        message: "Please provide edited sequence name",
        status: "error",
      });
      return;
    }
    const token = window.localStorage.getItem("token");
    let new_project_data = await axios.post(
      `${process.env.React_App_prod_url}/project/edit_sequence_name`,
      {
        project: sequence_data.project,
        sequence_id: sequence_data.sequence_id,
        sequenceName,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    get_sequence_of_project_actionCreater({
      dispatch,
      project_id: sequence_data.project,
    });
    show_toaster_actionCreater({
      message: "Sequence Edited Successfully",
      status: "success",
    });
    return true;
  } catch (err) {
    console.log({ err });
    return false;
  }
};

export const handle_add_query = async ({
  dispatch,
  handleClose,
  finalData,
  setAnchorEl,
}) => {
  try {
    if (finalData.queryTitle == "") {
      throw "Please enter your Query Name";
    }
    if (finalData.description == "") {
      throw "Enter the Query Description";
    }
    if (!finalData.document) {
      throw "Please Upload the Document";
    }
    if (finalData.queryUsers.length <= 0) {
      throw "Cannot proceed without at least one user in the query";
    }
    dispatch(startLoaderAction());
    const myFileUrl = await handleUpload(finalData.document);
    const token = window.localStorage.getItem("token");

    let new_project_data = await axios.post(
      `${process.env.React_App_prod_url}/project/add_query_of_sequence`,
      {
        ...finalData,
        document: myFileUrl,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    handleClose();
    show_toaster_actionCreater({
      message: "Query Created Successfully",
      status: "success",
    });
    setAnchorEl(null);
    dispatch(closeLoaderAction());
  } catch (err) {
    console.log({ err });
    show_toaster_actionCreater({
      message: err,
      status: "error",
    });
    dispatch(closeLoaderAction());
  }
};

export const handle_Edit_query = async ({
  dispatch,
  handleClose,
  finalData,
  setAnchorEl,
}) => {
  try {
    if (finalData.queryTitle == "") {
      show_toaster_actionCreater({
        message: "Please Provide the Edited Query Name",
        status: "error",
      });
      return;
    }
    dispatch(startLoaderAction());
    const token = window.localStorage.getItem("token");
    let new_query_data = await axios.post(
      `${process.env.React_App_prod_url}/project/edit_query_name`,
      {
        ...finalData,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    setAnchorEl(null);
    show_toaster_actionCreater({
      message: "Query Edited Successfully",
      status: "success",
    });
    handleClose();
    dispatch(closeLoaderAction());
  } catch (err) {
    console.log({ err });
    dispatch(closeLoaderAction());
  }
};

export const handle_delete_query = async ({
  dispatch,
  handleClose,
  finalData,
}) => {
  try {
    dispatch(startLoaderAction());
    const token = window.localStorage.getItem("token");
    let new_query_data = await axios.post(
      `${process.env.React_App_prod_url}/project/delete_query_from_sequence`,
      {
        ...finalData,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    show_toaster_actionCreater({
      message: "Query Deleted Successfully",
      status: "success",
    });
    handleClose();
    dispatch(closeLoaderAction());
  } catch (err) {
    console.log({ err });
    alert(err);
    dispatch(closeLoaderAction());
  }
};

export const handle_move_query = async ({
  dispatch,
  handleClose,
  finalData,
  oldSequenceName,
}) => {
  try {
    dispatch(startLoaderAction());
    const token = window.localStorage.getItem("token");
    let new_query_data = await axios.post(
      `${process.env.React_App_prod_url}/project/move_query_to_sequence`,
      {
        ...finalData,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let toasterMsg = "Query Moved " + finalData.oldSequenceName.sequenceName;
    show_toaster_actionCreater({
      message: toasterMsg,
      status: "success",
    });
    dispatch(closeLoaderAction());
    return true;
  } catch (err) {
    console.log({ err });
    alert(err);
    dispatch(closeLoaderAction());
    return false;
  }
};

export const handle_admin_of_project = async ({
  dispatch,
  finalData,
  selectedProject,
  router,
}) => {
  try {
    if (finalData.queryTitle == "") {
      throw "Please add a queryTitle for Query";
    }
    dispatch(startLoaderAction());
    let admin = finalData.role == "admin" ? [] : [`${selectedProject}`];
    let user = finalData.role == "admin" ? [`${selectedProject}`] : [];
    let updatedData = {
      projectData: {
        admin,
        user,
        dco: [],
      },
      user: finalData.user.email,
    };

    const token = window.localStorage.getItem("token");
    let new_list = await axios.post(
      `${process.env.React_App_prod_url}/project/update_user_access_for_admin/`,
      {
        projectData: {
          admin,
          dco: [],
          user,
        },
        user: finalData.user.email,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    // console.log(new_list);
    get_project_data_actionCreater({ dispatch, selectedProject, router });

    dispatch(closeLoaderAction());
  } catch (err) {
    // console.log({ err: err.response.data.err });
    show_toaster_actionCreater({
      message: err.response.data.err,
      status: "error",
    });
    // show_toaster_actionCreater({
    //   message: "Successfully Change Role",
    //   status: "success",
    // });
    get_project_data_actionCreater({ dispatch, selectedProject, router });
    dispatch(closeLoaderAction());
  }
};

export const handle_update_status_project = async ({
  dispatch,
  selectedProject,
  newStatus,
  router,
}) => {
  try {
    const token = window.localStorage.getItem("token");
    let new_project_data = await axios.post(
      `${process.env.React_App_prod_url}/project/update_project_status`,
      {
        project: selectedProject,
        newStatus,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    show_toaster_actionCreater({
      message:
        newStatus == "Open"
          ? "Successfully Project Opened"
          : "Your Project has been Closed",
      status: "success",
    });
    get_project_data_actionCreater({ dispatch, selectedProject, router });
  } catch (err) {
    console.log({ err });
    alert(err);
  }
};

export const handle_delete_user_from_query = async ({
  dispatch,
  project,
  query,
  user,
}) => {
  try {
    const token = window.localStorage.getItem("token");
    let new_query_data = await axios.post(
      `${process.env.React_App_prod_url}/project/delete_user_from_query`,
      {
        query,
        user,
        project,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    show_toaster_actionCreater({
      message: "User Deleted Successfully",
      status: "success",
    });
    // get_query_data_actionCreater({
    //   dispatch,
    //   query_id: new_query_data._id,
    // });
  } catch (err) {
    console.log({ err });
    alert(err);
  }
};

export const handle_add_user_in_project = async ({
  oldUserData_id,
  selectedUserList,
  project,
  selectedSetCompanyList,
  selectedSetUserList,
  navigate,
  dispatch,
  handleClose,
  router,
  place,
}) => {
  try {
    if (selectedUserList.length <= 0) {
      show_toaster_actionCreater({
        message: "Please Select User of Project",
        status: "error",
      });
      return;
    }
    dispatch(startLoaderAction());
    const token = window.localStorage.getItem("token");
    let new_project = await axios.post(
      `${process.env.React_App_prod_url}/project/add_user`,
      {
        users: selectedUserList.map((e) => e.email),
        project: project,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    selectedSetCompanyList([...[]]);
    selectedSetUserList([...[]]);
    handleClose();

    if (place == "project") {
      get_all_projects_actionCreater({ oldUserData_id,dispatch, router });
      change_base_router_actionCreater({ dispatch, router: "project" });
      navigate(`/home/project`);
    } else {
      get_project_data_actionCreater({
        dispatch,
        selectedProject: project,
        router,
      });
    }

    // setTimeout(() => {
    // }, 2000);

    dispatch(closeLoaderAction());
    show_toaster_actionCreater({
      message: "User Added Successfully in project!",
      status: "success",
    });
  } catch (err) {
    dispatch(closeLoaderAction());
    show_toaster_actionCreater({
      message: err,
      status: "error",
    });
    console.log({ err });
  }
};

export const handle_add_user_in_query = async ({
  finalData,
  dispatch,
  handleClose,
  setQueryUsers,
}) => {
  try {
    if (finalData.users.length <= 0) {
      show_toaster_actionCreater({
        message: "Please Select User of Project",
        status: "error",
      });
      return;
    }
    dispatch(startLoaderAction());
    const token = window.localStorage.getItem("token");
    let new_project = await axios.post(
      `${process.env.React_App_prod_url}/project/add_user_in_query`,
      {
        ...finalData,
        users: finalData.users.map((e) => e.email),
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setQueryUsers([...[]]);
    handleClose();

    select_query_of_sequence_actionCreater({
      dispatch,
      query: finalData.query,
    });

    dispatch(closeLoaderAction());

    show_toaster_actionCreater({
      message: "User Added Successfully in query!",
      status: "success",
    });
  } catch (err) {
    dispatch(closeLoaderAction());
    show_toaster_actionCreater({
      message: err,
      status: "error",
    });
    console.log({ err });
  }
};

export const handle_read_chat_data = async ({ data, dispatch, status }) => {
  try {
    const token = window.localStorage.getItem("token");

    console.log({ data }, "handle_read_chat_data");

    let new_project = await axios.post(
      `${process.env.React_App_prod_url}/message/read_query`,
      {
        project: data.project,
        sequence: data.sequence,
        query: data._id,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (status != "unselect")
      get_sequence_of_project_actionCreater({
        dispatch,
        project_id: data.project,
      });
    return;
  } catch (err) {
    console.log({ err });
  }
};
